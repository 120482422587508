// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, CardHeader, CardBody } from 'reactstrap';
import { Link, Route, Switch } from 'react-router-dom';
import type { ContextRouter } from 'react-router-dom';
import classNames from 'classnames';

import { VERIFICATION_STATUS } from 'core/Models/Enums';
import type { ClientType } from 'core/Models/Client';
import RoutesDescription from 'core/Models/RoutesDescription';
import { routesArray } from 'core/Utils/routesUtils';

import { selectClient } from '../../../../Selectors';

import BillingInformationForm from './BillingInformationForm';
import InvestmentObjectivesForm from './InvestmentObjectivesForm';
import TradingProfileForm from './TradingProfileForm';

const routes = new RoutesDescription({
  billingInfo: {
    path: `/billingInfo`,
    name: 'Billing Information',
    component: BillingInformationForm,
  },
  investmentsObjectives: {
    path: `/investmentsObjectives`,
    name: 'Investment Objectives',
    component: InvestmentObjectivesForm,
  },
  tradingProfile: {
    path: `/tradingProfile`,
    name: 'Trading Profile',
    component: TradingProfileForm,
  },
});

type StepProps = {
  status?: string,
  path: string,
  title: string,
}

function Step(props: StepProps) {
  const { status, path, title } = props;

  const styles = classNames('step', {
    verified: status === VERIFICATION_STATUS.PENDING_APPROVAL,
  });

  return (
    <Col md={4}>
      <Link className={styles} to={path}>
        

          {status === VERIFICATION_STATUS.CLIENT_AWAITING && (
            <>
              <span className="stepTitle">{title}</span>
              <span>
                <i className="far fa-circle mr-1" /> Not Filled
              </span>
            </>
          )}
          {status === VERIFICATION_STATUS.PENDING_APPROVAL && (
            <>
              <span className="stepTitle text-success">{title}</span>
              <span className="text-success">
                <i className="far fa-check-circle mr-1" /> Filled
              </span>
            </>
          )}
      </Link>
    </Col>
  );
}

type Props = {
  ...ContextRouter,
};

export default function Step3(props: Props) {
  const {
    match: { path: parentPath },
  } = props;

  const client: ClientType = useSelector(selectClient);
  const allSectionsFilled = 
    client.bankDetailsStatus === "PENDING_APPROVAL" && 
    client.investmentObjectivesStatus === "PENDING_APPROVAL" &&
    client.tradingProfileStatus === "PENDING_APPROVAL"
  
  return (
    <>
      <Switch>
        <Route exact path={parentPath}>
          {!allSectionsFilled ? <CardHeader tag="h1">Additional information</CardHeader> : <CardHeader tag="h1">THANK YOU FOR THE APPLICATION, {client.title}. {client.fullName}!</CardHeader>}
          <CardBody>
            <Row>
              <Col md="12">
                {!allSectionsFilled && <>
                  <p>This is the last step of your application.</p>
                  <p>Please submit Billing Information, Investment Objectives and Trading Profile requested below.</p>
                  <h2 className="mt-4 mb-0">Before trade you need to complete:</h2>
                </>}

                {allSectionsFilled && <>
                  <p>We have received all your data needed for a verification.</p>
                  <p>It usually takes up to 3 working days to check your documents.</p>
                  <p className="mb-4">Once your account is verified, you will receive a notification email.</p>
                </>}
                
                <Row className="additionalSteps mt-3">
                  <Step
                    title="1. Billing Information"
                    status={client.bankDetailsStatus}
                    path={`${parentPath}${routes.billingInfo.path}`}
                  />
                  <Step
                    title="2. Investments Objectives"
                    status={client.investmentObjectivesStatus}
                    path={`${parentPath}${routes.investmentsObjectives.path}`}
                  />
                  <Step
                    title="3. Trading Profile"
                    status={client.tradingProfileStatus}
                    path={`${parentPath}${routes.tradingProfile.path}`}
                  />
                </Row>
              </Col>
            </Row>
          </CardBody>
        </Route>
        {routesArray({ routes, parentPath })}
      </Switch>
    </>
  );
}
