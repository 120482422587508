import { Map } from 'immutable'
import { createReducer } from 'core/Utils/reduxUtils'

import * as actions from './actions'

const initialState = Map({
  form: {},
})

export default createReducer(
  {
    [actions.createDueDiligenceForm.success]: (state, form) => state.set('form', form),
    [actions.submitDueDiligenceForm.success]: (state, form) => state.set('form', form),
    [actions.getDueDiligenceForm.success]: (state, form) => state.set('form', form),
    [actions.uploadDocument.success]: (state, form) => {
      let newForm = state.get('form')
      newForm.documents = form.documents
      return state.set('form', newForm)
    },
  },
  initialState
)
