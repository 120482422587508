export function alphanumWithSymbols(v) {
  return v.replace(/[^[a-zA-Z0-9!-_#&(),./\s]/gi, '');
}

export function onlyLatinLetters(v) {
  return v.replace(/[^a-zA-Z0-9-_\s]/gi, '');
}

export function onlyNumbersAndChars(v) {
  return v.replace(/[^0-9-_\s]/gi, '');
}

export function onlyNumbers(v) {
  return v.replace(/[^0-9]/gi, '');
}



