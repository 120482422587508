import PhoneNumber from 'awesome-phonenumber';

export function parsePhone(phoneNumber = '') {
  const number = PhoneNumber(`${phoneNumber}`);
  return {
    countryCode: number.getCountryCode(),
    phone: number.getNumber('significant'),
    fullPhone: number.getNumber(),
  };
}

export function getRegion(phone = '') {
  let region = 'ZZ';
  let countryCodeLength = 1;

  while (region === 'ZZ' && countryCodeLength <= phone.length) {
    region = PhoneNumber.getRegionCodeForCountryCode(phone.substr(0, countryCodeLength));
    countryCodeLength += 1;
  }
  return region;
}
