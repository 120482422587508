const STORAGE_TOKEN_KEY = 'ie_token';

const STORAGE_USER_KEY = 'ie_user';

export function setToken(token) {
  localStorage.setItem(STORAGE_TOKEN_KEY, token);
  return token;
}

export function getToken() {
  return localStorage.getItem(STORAGE_TOKEN_KEY);
}

export function removeToken() {
  localStorage.removeItem(STORAGE_TOKEN_KEY);
  return true;
}

export function setUserData(data) {
  localStorage.setItem(STORAGE_USER_KEY, JSON.stringify(data));
  return data;
}

export function getUserData() {
  return JSON.parse(localStorage.getItem(STORAGE_USER_KEY) || '{}');
}

export function removeUserData() {
  localStorage.removeItem(STORAGE_USER_KEY);
  return true;
}
