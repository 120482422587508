// @flow

import { Formik, FieldArray } from 'formik'
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { format, subYears, addYears, addMonths } from 'date-fns'
import ReactTooltip from 'react-tooltip'

import {
  Table,
  CardBody,
  CardHeader,
  CardFooter,
  UncontrolledCollapse,
  FormGroup,
  Form,
  Button,
  Row,
  Col,
  InputGroup,
  Label,
  InputGroupText,
  InputGroupAddon,
} from 'reactstrap'

import type { ClientType } from 'core/Models/Client'
import mapFullClientToProps from 'core/Transformers/mapFullClientToProps'
import { STANDARD_TIME_DATE } from 'core/Utils/dateUtils'
import {
  CLIENT_TYPE,
  CONTACT_TYPES,
  CORPORATE_POSITIONS,
  PERSON_TITLES,
  VERIFICATION_STATUS,
  SOURCE_OF_FUNDS,
  INVESTMENT_OBJECTIVES,
  TIN_TYPES,
  FREQUENCY,
} from 'core/Models/Enums'
import {
  FieldInput,
  FieldDatePicker,
  FieldCountrySelect,
  FieldSelect,
  FieldCheckbox,
  UploadButton,
} from 'core/Components/Form'
import { corporateValidationSchema, individualValidationSchema } from 'core/Validation/clientValidation'
import { alphanumWithSymbols, onlyLatinLetters, onlyNumbers } from 'core/Components/Form/normalizers'
import { selectUserToken } from '../../../Selectors'
import { selectAmClient } from './selectors'

import * as actions from './actions'

const maxBirthDate = subYears(new Date(), 18)
const minBirthDate = subYears(new Date(), 100)

const defaultBirthDate = subYears(new Date(), 35)
const minPassportExpiryDate = addMonths(new Date(), 3)
const maxPassportExpiryDate = addYears(new Date(), 50)

const maxCompanyIncorporationDate = new Date()
const minCompanyIncorporationDate = subYears(new Date(), 150)

const mapPropsToValues = (user, client, clientType) => ({
  ...mapFullClientToProps(user, client, clientType),
})

type ClientFormProps = {
  clientId: number,
}

export default function ClientForm(props: ClientFormProps) {
  const dispatch = useDispatch()

  const { clientId } = props

  useEffect(() => {
    dispatch(actions.getClient({ id: clientId }))
  }, [clientId, dispatch])

  const client: ClientType = useSelector(selectAmClient)

  if (!client.id) return <CardBody>Loading...</CardBody>
  if (client.user?.status === VERIFICATION_STATUS.VERIFIED) return <CardBody>Account already verified</CardBody>

  const submitForm = (values) => {
    dispatch(actions.updateClient({ id: client.user?.id, values }))
  }

  const onSubmit = (values, { setSubmitting, submit = true }) => {
    const transformedValues = {
      client: {
        title: values.title,
        fullName: values.fullName,
        residence: values.residence,
        citizenship: values.citizenship,
        passportNumber: values.passportNumber,
        passportExpiryDate: values.passportExpiryDate,
        birthday: values.birthday,
        birthdayCountry: values.birthdayCountry,
        birthdayCity: values.birthdayCity,
        address: values.address,
        occupation: values.occupation,
        employerName: values.employerName,
        position: values.position,
        companyName: values.companyName,
        companyIncorporationCountry: values.companyIncorporationCountry,
        companyIncorporationDate: values.companyIncorporationDate,
        companyRegnumber: values.companyRegnumber,
        companyDescription: values.companyDescription,
        companyAddress: values.companyAddress,
        companyRegulatorName: values.companyRegulatorName,
      },
      contacts: values.contacts,
      investmentObjectives: values.investmentObjectives,
      tradingProfile: values.tradingProfile,
    }

    console.log(transformedValues)

    const onSuccess = () => {
      setSubmitting(false)
    }
    const onError = () => {
      setSubmitting(false)
    }
    submitForm({ values: transformedValues, onSuccess, onError })
  }

  const initialValues = mapPropsToValues(client.user, client, client.type)

  const clientType = client.type === 'INDIVIDUAL' ? CLIENT_TYPE.INDIVIDUAL : CLIENT_TYPE.CORPORATE
  const validationSchema =
    clientType === CLIENT_TYPE.INDIVIDUAL ? individualValidationSchema : corporateValidationSchema
  return (
    <Formik
      autoComplete="off"
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={(props) => <ClientDataForm {...props} status={client.user?.status} client={client} />}
      validationSchema={validationSchema}
    />
  )
}

type FormProps = {
  client: ClientType,
  handleSubmit: (values: any) => void,
  isValid: boolean,
  isSubmitting: boolean,
  status?: string,
  text?: string,
  values: any,
  unsubmitForm?: () => void,
  isVerification?: boolean,
}

const ClientDataForm = (props: FormProps) => {
  const { handleSubmit, isValid, isSubmitting, client, values, status } = props
  const fieldsDisabled = Boolean(isSubmitting)

  const dispatch = useDispatch()
  const clientType = values.type === 'INDIVIDUAL' ? CLIENT_TYPE.INDIVIDUAL : CLIENT_TYPE.CORPORATE
  const userToken = useSelector(selectUserToken)

  const uploadDocument = (payload) => dispatch(actions.uploadDocument(payload))
  const downloadApplication = (e) => {
    e.preventDefault()
    dispatch(actions.downloadApplication({ id: client.id }))
  }

  const deleteDocument = ({ id }) => dispatch(actions.deleteDocument({ id }))

  const submitClient = ({ id, values }) => {
    dispatch(actions.submitClient({ id }))
  }
  const unsubmitClient = ({ id }) => dispatch(actions.unsubmitClient({ id }))

  return (
    <Form role="form" onSubmit={handleSubmit} autoComplete="off">
      <ReactTooltip effect="float" />

      <CardHeader tag="h2" className="form-section">
        1. Personal information
      </CardHeader>

      <CardBody>
        <Row>
          <Col xl="4" lg="6">
            <FormGroup>
              <FieldInput label="Account Type" name="type" id="type" disabled />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          {clientType === CLIENT_TYPE.CORPORATE ? (
            <Col xl="4" lg="6">
              <FormGroup>
                <InputGroup>
                  <FieldSelect
                    label="Position in the company *"
                    name="position"
                    id="position"
                    options={CORPORATE_POSITIONS.selectOptions}
                    disabled={fieldsDisabled}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
          ) : null}
          <Col xl="8" lg="12">
            <FormGroup>
              <InputGroup>
                <Label className="muted_label" style={{ display: 'block', width: '100%' }} htmlFor="fullName">
                  Full Name *
                </Label>
                <InputGroupAddon className="title_prepend" addonType="prepend">
                  <FieldSelect
                    type="text"
                    name="title"
                    id="title"
                    options={PERSON_TITLES.selectOptions}
                    menuPosition="fixed"
                    maxMenuWidth={200}
                    disabled={fieldsDisabled}
                  />
                </InputGroupAddon>
                <FieldInput type="text" name="fullName" id="fullName" style={{ paddingLeft: 15 }} disabled />
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
        {clientType === CLIENT_TYPE.INDIVIDUAL || values.position !== CORPORATE_POSITIONS.NONE_OF_ABOVE ? (
          <>
            <Row>
              <Col xl="4" lg="6">
                <FormGroup>
                  <InputGroup>
                    <FieldCountrySelect
                      label="Citizenship *"
                      name="citizenship"
                      id="citizenship"
                      disabled={fieldsDisabled}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col xl="4" lg="6">
                <FormGroup>
                  {/* <i data-tip="You must be over 18 years old to use the service" className="far fa-question-circle" /> */}
                  <FieldDatePicker
                    label="Date of Birth *"
                    name="birthday"
                    id="birthday"
                    maxDate={maxBirthDate}
                    minDate={minBirthDate}
                    openToDate={defaultBirthDate}
                    showYearDropdown
                    scrollableYearDropdown
                    showDisabledMonthNavigation
                    showMonthDropdown
                    yearDropdownItemNumber={60}
                    disabled={fieldsDisabled}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col xl="4" lg="6">
                <FormGroup>
                  <InputGroup>
                    <FieldCountrySelect
                      label="Birthday Country *"
                      name="birthdayCountry"
                      id="birthdayCountry"
                      disabled={fieldsDisabled}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col xl="4" lg="6">
                <FormGroup>
                  <FieldInput
                    label="Birthday City *"
                    name="birthdayCity"
                    id="birthdayCity"
                    disabled={fieldsDisabled}
                    normalize={alphanumWithSymbols}
                    autoComplete="hash-1533"
                    maxLength={100}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col xl="4" lg="6">
                <FormGroup>
                  <FieldInput
                    label="Passport Number *"
                    name="passportNumber"
                    id="passportNumber"
                    disabled={fieldsDisabled}
                    normalize={alphanumWithSymbols}
                    maxLength={30}
                  />
                </FormGroup>
              </Col>
              <Col xl="4" lg="6">
                <FormGroup>
                  <FieldDatePicker
                    label="Passport Expiry Date *"
                    name="passportExpiryDate"
                    id="passportExpiryDate"
                    minDate={minPassportExpiryDate}
                    maxDate={maxPassportExpiryDate}
                    showYearDropdown
                    scrollableYearDropdown
                    showMonthDropdown
                    yearDropdownItemNumber={15}
                    disabled={fieldsDisabled}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col xl="8" lg="12">
                <FormGroup>
                  <FieldInput
                    label="Residential Address (street, building, apt.) *"
                    name="address"
                    id="address"
                    disabled={fieldsDisabled}
                    normalize={alphanumWithSymbols}
                    autoComplete="hash-1532"
                    maxLength={200}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xl="4" lg="6">
                <FormGroup>
                  <FieldInput
                    label="City *"
                    name="addressCity"
                    id="addressCity"
                    disabled={fieldsDisabled}
                    normalize={alphanumWithSymbols}
                    autoComplete="hash-1533"
                    maxLength={100}
                  />
                </FormGroup>
              </Col>
              <Col xl="4" lg="6">
                <FormGroup>
                  <FieldInput
                    label="State"
                    name="addressState"
                    id="addressState"
                    disabled={fieldsDisabled}
                    normalize={alphanumWithSymbols}
                    autoComplete="hash-1533"
                    maxLength={100}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xl="4" lg="6">
                <FormGroup>
                  <InputGroup>
                    <FieldCountrySelect
                      label="Country of Residence *"
                      name="residence"
                      id="residence"
                      disabled={fieldsDisabled}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col xl="4" lg="6">
                <FormGroup>
                  <FieldInput
                    label="Postal Code *"
                    name="addressPostalCode"
                    id="addressPostalCode"
                    disabled={fieldsDisabled}
                    normalize={alphanumWithSymbols}
                    autoComplete="hash-1533"
                    maxLength={30}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Label className="muted_label mb-3">Please provide your personal TIN</Label>
            <FieldArray
              name="tins"
              render={(arrayHelpers) => (
                <>
                  {values.tins.map((contact, idx) => (
                    <Row key={`tins_row_${idx}`}>
                      <Col xl={8} md={12}>
                        <FormGroup>
                          <InputGroup>
                            <InputGroupAddon className="tins_prepend" addonType="prepend">
                              <FieldSelect
                                name={`tins.${idx}.type`}
                                placeholder="Type"
                                options={TIN_TYPES.selectOptions}
                                maxWidth={180}
                                disabled={fieldsDisabled}
                              />
                            </InputGroupAddon>

                            <FieldInput
                              maxLength={30}
                              placeholder="Personal TIN *"
                              name={`tins.${idx}.tin`}
                              autoComplete={`hash-235${idx}`}
                              disabled={fieldsDisabled}
                            />

                            <InputGroupAddon addonType="append" className="tins_append">
                              <FieldCountrySelect
                                placeholder="Country to which TIN applies *"
                                name={`tins.${idx}.country`}
                                id={`tins.${idx}.country`}
                                disabled={fieldsDisabled}
                              />
                            </InputGroupAddon>

                            {!fieldsDisabled && (
                              <InputGroupAddon addonType="append">
                                <Button
                                  type="button"
                                  size="sm"
                                  color="warning"
                                  onClick={() => arrayHelpers.remove(idx)}
                                  disabled={fieldsDisabled || idx === 0}
                                >
                                  <i className="far fa-trash-alt px-1" />
                                </Button>
                              </InputGroupAddon>
                            )}
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                  ))}
                  {values.tins.length <= 4 ? (
                    <Row>
                      <Col lg="2" md="6">
                        <Button
                          type="button"
                          color="success"
                          onClick={() => arrayHelpers.push({ type: TIN_TYPES.TIN, tin: '', country: 'United States' })}
                          disabled={fieldsDisabled}
                        >
                          <i className="fas fa-plus pr-2" />
                          Add TIN
                        </Button>
                      </Col>
                    </Row>
                  ) : null}
                </>
              )}
            />
          </>
        ) : null}
      </CardBody>

      <CardHeader tag="h2" className="form-section">
        2. Contact information
      </CardHeader>

      <CardBody>
        <FieldArray
          name="contacts"
          render={(arrayHelpers) => (
            <>
              {values.contacts.map((contact, idx) => (
                <Row key={`contacts_row_${idx}`}>
                  <Col xl={8} md={12}>
                    <FormGroup>
                      <InputGroup>
                        <InputGroupAddon className="contacts_prepend" addonType="prepend">
                          <FieldSelect
                            name={`contacts.${idx}.type`}
                            options={CONTACT_TYPES.selectOptions}
                            maxWidth={120}
                            disabled={fieldsDisabled || idx === 0}
                          />
                        </InputGroupAddon>

                        <FieldInput
                          maxLength={50}
                          name={`contacts.${idx}.value`}
                          autoComplete={`hash-23${idx}`}
                          disabled={fieldsDisabled || idx === 0}
                        />

                        {idx !== 0 && (
                          <InputGroupAddon addonType="append">
                            <InputGroupText>
                              <Label check className="contact-checkbox" htmlFor={`contacts.${idx}.isPrimary`}>
                                <FieldCheckbox
                                  name={`contacts.${idx}.isPrimary`}
                                  id={`contacts.${idx}.isPrimary`}
                                  disabled={fieldsDisabled}
                                  className="pr-2"
                                />
                                Primary
                              </Label>
                            </InputGroupText>
                          </InputGroupAddon>
                        )}

                        {!fieldsDisabled && (
                          <InputGroupAddon addonType="append">
                            <Button
                              type="button"
                              size="sm"
                              color="warning"
                              onClick={() => arrayHelpers.remove(idx)}
                              disabled={fieldsDisabled || idx === 0}
                            >
                              <i className="far fa-trash-alt pr-1" />
                            </Button>
                          </InputGroupAddon>
                        )}
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              ))}

              {values.contacts.length <= 4 ? (
                <Row>
                  <Col lg="2" md="6">
                    <Button
                      type="button"
                      color="success"
                      onClick={() => arrayHelpers.push({ type: CONTACT_TYPES.PHONE, value: '', isPrimary: false })}
                      disabled={fieldsDisabled}
                    >
                      <i className="fas fa-plus pr-2" />
                      Add contact
                    </Button>
                  </Col>
                </Row>
              ) : null}
            </>
          )}
        />
      </CardBody>

      {clientType === CLIENT_TYPE.INDIVIDUAL ? (
        <>
          <CardHeader tag="h2" className="form-section">
            3. Information about occupation
          </CardHeader>
          <CardBody>
            <Row>
              <Col xl="4" md="6">
                <FormGroup className="mb-0">
                  <FieldInput
                    label="Employer Company Name *"
                    name="employerName"
                    id="employerName"
                    disabled={fieldsDisabled}
                    normalize={alphanumWithSymbols}
                  />
                </FormGroup>
              </Col>
              <Col xl="4" md="6">
                <FormGroup className="mb-0">
                  <FieldInput
                    label="Occupation *"
                    name="occupation"
                    id="occupation"
                    disabled={fieldsDisabled}
                    normalize={alphanumWithSymbols}
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </>
      ) : (
        <>
          <CardHeader tag="h2" className="form-section">
            3. Company Information
          </CardHeader>
          <CardBody>
            <Row>
              <Col xl="4" md="6">
                <FormGroup>
                  <label className="muted_label" htmlFor="companyName">
                    Full Legal Name *
                  </label>
                  <FieldInput
                    name="companyName"
                    id="companyName"
                    disabled={fieldsDisabled}
                    normalize={alphanumWithSymbols}
                  />
                </FormGroup>
              </Col>
              <Col xl="4" md="6">
                <FormGroup>
                  <label className="muted_label" htmlFor="companyIncorporationDate">
                    Date of Incorporation *
                  </label>
                  <FieldDatePicker
                    name="companyIncorporationDate"
                    id="companyIncorporationDate"
                    disabled={fieldsDisabled}
                    maxDate={maxCompanyIncorporationDate}
                    minDate={minCompanyIncorporationDate}
                    showYearDropdown
                    scrollableYearDropdown
                    showMonthDropdown
                    yearDropdownItemNumber={15}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xl="4" md="6">
                <FormGroup>
                  <FieldCountrySelect
                    label="Country of Incorporation *"
                    name="companyIncorporationCountry"
                    id="companyIncorporationCountry"
                    disabled={fieldsDisabled}
                  />
                </FormGroup>
              </Col>
              <Col xl="4" md="6">
                <FormGroup>
                  <FieldInput
                    label="Registration Number *"
                    name="companyRegnumber"
                    id="companyRegnumber"
                    disabled={fieldsDisabled}
                    normalize={alphanumWithSymbols}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xl="4" md="6">
                <FormGroup>
                  <FieldInput
                    label="Description of Business *"
                    name="companyDescription"
                    id="companyDescription"
                    disabled={fieldsDisabled}
                    normalize={onlyLatinLetters}
                  />
                </FormGroup>
              </Col>
              <Col xl="4" md="6" sm="12">
                <FormGroup>
                  <FieldInput
                    label="Regulator Name, if company is regulated"
                    name="companyRegulatorName"
                    id="companyRegulatorName"
                    disabled={fieldsDisabled}
                    normalize={alphanumWithSymbols}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xl="8" md="12">
                <FormGroup>
                  <FieldInput
                    label="Company Registered Address (street, building, office) *"
                    name="companyAddress"
                    id="companyAddress"
                    disabled={fieldsDisabled}
                    normalize={alphanumWithSymbols}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xl="4" md="6">
                <FormGroup>
                  <FieldInput
                    label="City *"
                    name="companyAddressCity"
                    id="companyAddressCity"
                    disabled={fieldsDisabled}
                    normalize={alphanumWithSymbols}
                  />
                </FormGroup>
              </Col>
              <Col xl="4" md="6">
                <FormGroup>
                  <FieldInput
                    label="State"
                    name="companyAddressState"
                    id="companyAddressState"
                    disabled={fieldsDisabled}
                    normalize={alphanumWithSymbols}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xl="4" md="6">
                <FormGroup className="mb-0">
                  <FieldCountrySelect
                    label="Country *"
                    name="companyAddressCountry"
                    id="companyAddressCountry"
                    disabled={fieldsDisabled}
                  />
                </FormGroup>
              </Col>
              <Col xl="4" md="6">
                <FormGroup className="mb-0">
                  <FieldInput
                    label="Postal Code *"
                    name="companyAddressPostalCode"
                    id="companyAddressPostalCode"
                    disabled={fieldsDisabled}
                    normalize={alphanumWithSymbols}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Label className="muted_label mb-3 mt-4">Please provide your company TIN</Label>
            <Row>
              <Col xl={8} md={12}>
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon className="tins_prepend" addonType="prepend">
                      <FieldSelect
                        name="companyTinType"
                        placeholder="Type"
                        options={TIN_TYPES.selectOptions}
                        maxWidth={180}
                        disabled={fieldsDisabled}
                      />
                    </InputGroupAddon>

                    <FieldInput
                      placeholder="Company TIN *"
                      maxLength={50}
                      name="companyTin"
                      autoComplete={`hash-2352`}
                      disabled={fieldsDisabled}
                    />

                    <InputGroupAddon addonType="append" className="tins_append">
                      <FieldCountrySelect
                        placeholder="Country to which TIN applies *"
                        name="companyTinCountry"
                        id="companyTinCountry"
                        disabled={fieldsDisabled}
                      />
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </>
      )}

      <CardHeader tag="h2" className="form-section">
        4. Documents
      </CardHeader>

      <CardBody>
        <p>
          Please see below the list of documents to upload. Please note, that you will have to send us the original
          signed document via DHL/traditional post-service after your account is open.{' '}
        </p>

        <div className="mb-3">
          {client.documents.size > 0 ? (
            <Table striped bordered responsive>
              <thead>
                <tr>
                  <th scope="row">Document</th>
                  <th>Submitted At</th>
                  <th>Verified At</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {client.documents.map((document, idx) => {
                  return (
                    <tr key={`ver_documents_${document.id}_${idx}`}>
                      <th scope="row">{document.name}</th>
                      <td>{format(new Date(document.createdAt), STANDARD_TIME_DATE)}</td>
                      <td>
                        {document.verifiedAt
                          ? format(new Date(document.verifiedAt), STANDARD_TIME_DATE)
                          : 'Not Verified'}
                      </td>
                      <td>
                        {!document.verifiedAt ? (
                          <Button
                            type="button"
                            size="sm"
                            color="warning"
                            onClick={() => deleteDocument({ id: document.id })}
                          >
                            <i className="far fa-trash-alt pr-1" />
                            Delete
                          </Button>
                        ) : null}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          ) : (
            <div className="my-3">No documents uploaded</div>
          )}
        </div>
        <div className="my-3">
          <UploadButton
            onDocumentLoad={uploadDocument}
            userToken={userToken}
            apiPath={`manager/accounts/${values.id}/document`}
          />
          <Button color="primary" id="documents">
            <i className="far fa-question-circle pr-2" />
            List of Documents
          </Button>
        </div>

        <UncontrolledCollapse toggler="#documents">
          <ol
            className="wide-list"
            style={{ backgroundColor: '#f2f4fa', border: '1px solid #5f6bb5', borderRadius: '3px' }}
          >
            <li>
              1. The Account Opening Form. Please <u>download</u>, <u>print</u> and <u>sign</u> the form below
              <br />
              <Button color="simple" size="sm" className="mt-2" onClick={downloadApplication}>
                <i className="fas fa-external-link-alt" /> Account Opening Form
              </Button>
            </li>
            {clientType === CLIENT_TYPE.INDIVIDUAL ? (
              <>
                <li>2. Certified ID (e.g. a passport, a driver’s license or a national identity card)</li>
                <li>
                  3. Certified Utility bill (not older than 3 months) which shows the address that you used during the
                  registration process
                </li>
                <li>
                  4. Authorized Persons Form, if applicable{' '}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://ie-static.ams3.cdn.digitaloceanspaces.com/v2/documents/Authorised_Persons_Form_Individuals.pdf"
                  >
                    Download Example
                  </a>
                </li>
              </>
            ) : (
              <>
                <li>2. Corporate/Board Resolution to open an account with Investors Europe signed by all directors</li>
                <li>3. Certified copy of Register of Directors and Shareholders</li>
                <li>4. Certified copy of Memorandum and Articles of Association</li>
                <li>5. Certified copy of Register of Registered office</li>
                <li>6. Certified copy of Certificate of Incorporation</li>
                <li>7. Certified copy of Share Certificates</li>
                <li>8. Certified copy of Good Standing (for companies older than 1 year) </li>
                <li>
                  9. Certificate of Incumbency if the public record of status is not available online (for companies
                  older than 1 year)
                </li>
                <li>10. A copy of the trust deed/agreement, if applicable</li>
                <li>
                  11. Certified copy of Passport (or national identity card or a driver’s license) of each Beneficial
                  Owner / Director / Authorized Person
                </li>
                <li>
                  12. Certified Utility bill (not older than 3 months) for each Beneficial Owner / Director / Authorized
                  Person
                </li>
              </>
            )}
          </ol>
        </UncontrolledCollapse>
      </CardBody>

      <CardHeader tag="h2" className="form-section">
        5. Investment Objectives
      </CardHeader>
      <CardBody>
        <Row>
          <Col xl="4" md="6">
            <FormGroup>
              <label className="muted_label" htmlFor="investmentObjectives.objectives">
                Investment Objectives
              </label>
              <FieldSelect
                options={INVESTMENT_OBJECTIVES.selectOptions}
                name="investmentObjectives.objectives"
                id="investmentObjectives.objectives"
                disabled={fieldsDisabled}
              />
            </FormGroup>
          </Col>
          <Col xl="4" md="6">
            <FormGroup>
              <label className="muted_label" htmlFor="investmentObjectives.networthAmount">
                Client’s Net Worth
              </label>
              <FieldInput
                name="investmentObjectives.networthAmount"
                id="investmentObjectives.networthAmount"
                disabled={fieldsDisabled}
                normalize={onlyNumbers}
                placeholder="$"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xl="4" md="6">
            <FormGroup>
              <label className="muted_label" htmlFor="investmentObjectives.incomeAmount">
                Client’s Annual Net Income
              </label>
              <FieldInput
                name="investmentObjectives.incomeAmount"
                id="investmentObjectives.incomeAmount"
                disabled={fieldsDisabled}
                normalize={onlyNumbers}
                placeholder="$"
              />
            </FormGroup>
          </Col>
          <Col xl="4" md="6">
            <FormGroup>
              <label className="muted_label" htmlFor="investmentObjectives.investAmount">
                Amount you intend to invest
              </label>
              <FieldInput
                name="investmentObjectives.investAmount"
                id="investmentObjectives.investAmount"
                disabled={fieldsDisabled}
                normalize={onlyNumbers}
                placeholder="$"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xl="4" md="6">
            <FormGroup>
              <label className="muted_label" htmlFor="investmentObjectives.sourceOfFunds">
                The source of funds you are going to invest
              </label>
              <FieldSelect
                options={SOURCE_OF_FUNDS.selectOptions}
                name="investmentObjectives.sourceOfFunds"
                id="investmentObjectives.sourceOfFunds"
                disabled={fieldsDisabled}
              />
            </FormGroup>
          </Col>
          {values.sourceOfFunds === SOURCE_OF_FUNDS.OTHER ||
          values.sourceOfFunds === SOURCE_OF_FUNDS.OWNER_OF_BUSINESS ? (
            <Col xl="4" md="6">
              <FormGroup>
                <label className="muted_label" htmlFor="investmentObjectives.sourceOfFundsDescription">
                  Please provide details
                </label>
                <FieldInput
                  name="investmentObjectives.sourceOfFundsDescription"
                  id="investmentObjectives.sourceOfFundsDescription"
                  disabled={fieldsDisabled}
                  normalize={alphanumWithSymbols}
                />
              </FormGroup>
            </Col>
          ) : null}
        </Row>
      </CardBody>

      <CardHeader tag="h2" className="form-section">
        6. Trading Profile
      </CardHeader>
      <Table striped bordered>
        <thead>
          <tr>
            <th>Asset Class</th>
            <th style={{ width: 50 }}>Experience</th>
            <th style={{ width: 50 }}>Knowledge</th>
            <th>
              Avg Frequency{' '}
              <i
                data-tip="Average frequency of transactions per month for the past year"
                className="far fa-question-circle"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Stocks</td>
            <td>
              <FieldInput
                name="tradingProfile.stocksExperience"
                id="tradingProfile.stocksExperience"
                disabled={fieldsDisabled}
                normalize={onlyNumbers}
                placeholder="1–10"
                maxLength="2"
                bsSize="sm"
              />
            </td>
            <td>
              <FieldInput
                name="tradingProfile.stocksKnowledge"
                id="tradingProfile.stocksKnowledge"
                disabled={fieldsDisabled}
                normalize={onlyNumbers}
                placeholder="1–10"
                maxLength="2"
                bsSize="sm"
              />
            </td>
            <td>
              <FieldSelect
                options={FREQUENCY.selectOptions}
                name="tradingProfile.stocksFrequency"
                id="tradingProfile.stocksFrequency"
                disabled={fieldsDisabled}
              />
            </td>
          </tr>
          <tr>
            <td>
              Bonds <i data-tip="Bonds or other securitised debt" className="far fa-question-circle" />
            </td>
            <td>
              <FieldInput
                name="tradingProfile.bondsExperience"
                id="tradingProfile.bondsExperience"
                disabled={fieldsDisabled}
                normalize={onlyNumbers}
                placeholder="1–10"
                maxLength="2"
                bsSize="sm"
              />
            </td>
            <td>
              <FieldInput
                name="tradingProfile.bondsKnowledge"
                id="tradingProfile.bondsKnowledge"
                disabled={fieldsDisabled}
                normalize={onlyNumbers}
                placeholder="1–10"
                maxLength="2"
                bsSize="sm"
              />
            </td>
            <td>
              <FieldSelect
                options={FREQUENCY.selectOptions}
                name="tradingProfile.bondsFrequency"
                id="tradingProfile.bondsFrequency"
                disabled={fieldsDisabled}
              />
            </td>
          </tr>
          <tr>
            <td>Forex</td>
            <td>
              <FieldInput
                name="tradingProfile.forexExperience"
                id="tradingProfile.forexExperience"
                disabled={fieldsDisabled}
                normalize={onlyNumbers}
                placeholder="1–10"
                maxLength="2"
                bsSize="sm"
              />
            </td>
            <td>
              <FieldInput
                name="tradingProfile.forexKnowledge"
                id="tradingProfile.forexKnowledge"
                disabled={fieldsDisabled}
                normalize={onlyNumbers}
                placeholder="1–10"
                maxLength="2"
                bsSize="sm"
              />
            </td>
            <td>
              <FieldSelect
                options={FREQUENCY.selectOptions}
                name="tradingProfile.forexFrequency"
                id="tradingProfile.forexFrequency"
                disabled={fieldsDisabled}
              />
            </td>
          </tr>
          <tr>
            <td>
              Derivatives{' '}
              <i data-tip="Options, futures, swaps, forwards, other derivatives" className="far fa-question-circle" />
            </td>
            <td>
              <FieldInput
                name="tradingProfile.derivativesExperience"
                id="tradingProfile.derivativesExperience"
                disabled={fieldsDisabled}
                normalize={onlyNumbers}
                placeholder="1–10"
                maxLength="2"
                bsSize="sm"
              />
            </td>
            <td>
              <FieldInput
                name="tradingProfile.derivativesKnowledge"
                id="tradingProfile.derivativesKnowledge"
                disabled={fieldsDisabled}
                normalize={onlyNumbers}
                placeholder="1–10"
                maxLength="2"
                bsSize="sm"
              />
            </td>
            <td>
              <FieldSelect
                options={FREQUENCY.selectOptions}
                name="tradingProfile.derivativesFrequency"
                id="tradingProfile.derivativesFrequency"
                disabled={fieldsDisabled}
              />
            </td>
          </tr>
        </tbody>
      </Table>
      <CardFooter>
        <Row>
          <Col xl="8" md="6">
            <FormGroup>
              <label className="muted_label" htmlFor="description">
                Experience Description
              </label>
              <FieldInput
                name="tradingProfile.description"
                id="tradingProfile.description"
                disabled={fieldsDisabled}
                normalize={onlyLatinLetters}
                type="textarea"
              />
            </FormGroup>
          </Col>
        </Row>
      </CardFooter>

      <CardFooter>
        {status === 'NOT_VERIFIED' ? (
          <Button
            type="submit"
            color="success"
            disabled={!isValid}
            onClick={() => submitClient({ id: client.user?.id, values })}
          >
            <i className="fas fa-check pr-2"></i>Submit Form
          </Button>
        ) : null}
        {status === 'IN_VERIFICATION' ? (
          <Button type="button" color="warning" onClick={() => unsubmitClient({ id: client.user?.id })}>
            <i className="fas fa-times pr-2"></i>Unsubmit Form
          </Button>
        ) : null}

        <Button type="submit" color="primary" disabled={!isValid || fieldsDisabled}>
          <i className="fas fa-save pr-2"></i>Save Changes
        </Button>
      </CardFooter>
    </Form>
  )
}

// const CorporateForm = (props: FormProps) => {
//   const { handleSubmit, isValid, isSubmitting, client } = props;
//   const fieldsDisabled = Boolean(isSubmitting);

//   return (
//     <Form role="form" onSubmit={handleSubmit} autoComplete="off">
//       <FormGroup>
//         <FieldInput label="Type" name="type" id="type" disabled/>
//       </FormGroup>
//     </Form>
//   )
// }
