// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, NavLink } from 'react-router-dom';
import type { ContextRouter } from 'react-router-dom';

import { routesArray } from 'core/Utils/routesUtils';
import type { UserType } from 'core/Models/User';
import type { ClientType } from 'core/Models/Client';
import { VERIFICATION_STATUS } from 'core/Models/Enums';
import RoutesDescription from 'core/Models/RoutesDescription';

import { selectClient, selectUser } from '../../../Selectors';

import { IconStep1, IconStep2, IconStep3 } from './icons';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';

import styles from './styles.module.scss';

const routes = new RoutesDescription({
  step1: {
    path: '/client',
    name: 'Step 1',
    description: 'Fill in the client profile',
    icon: IconStep1,
    component: Step1,
    checkIsDisabled: () => false,
  },
  step2: {
    path: '/documents',
    name: 'Step 2',
    description: 'Print and sign documents',
    icon: IconStep2,
    component: Step2,
    checkIsDisabled: (client: ClientType) => !client.status || client.status === VERIFICATION_STATUS.CLIENT_AWAITING,
  },
  step3: {
    path: '/additional',
    name: 'Step 3',
    description: 'Add additional information',
    icon: IconStep3,
    component: Step3,
    checkIsDisabled: (client: ClientType) =>
      !client.status ||
      client.status === VERIFICATION_STATUS.CLIENT_AWAITING,
  },
});

type Props = ContextRouter & {};

export default function Steps(props: Props) {
  const {
    match: { path: parentPath },
  } = props;

  const user: UserType = useSelector(selectUser);
  const client: ClientType = useSelector(selectClient);

  return (
    <div>
      <div className={styles.steps}>
        {routes.toArray.map(({ path, name, icon: Icon, description, checkIsDisabled }, idx) => (
          <NavLink
            className={styles.step}
            activeClassName={styles.stepActive}
            to={`${parentPath}${path}`}
            key={`step_route_${path}_${idx}`}
            disabled={checkIsDisabled(client)}
          >
            <div className={styles.stepIconWrapper}>
              <Icon className={styles.stepIcon} />
            </div>
            <div className={styles.stepText}>
              <h3 className={styles.stepName}>{name}</h3>
              <div className={styles.stepDescription}>{description}</div>
            </div>
          </NavLink>
        ))}
      </div>
      <div>
        <Switch>
          <Route exact path={parentPath}>
            {user.clientId ? (
              <Redirect to={`${parentPath}${routes.step2.path}`} />
            ) : (
              <Redirect to={`${parentPath}${routes.step1.path}`} />
            )}
          </Route>
          {routesArray({ routes, parentPath })}
        </Switch>
      </div>
    </div>
  );
}
