import React from 'react';
import { components, createFilter } from 'react-select';

import FieldSelect from './FieldSelect';
import countriesList from './countriesList';

import styles from './styles.module.scss';

const options = countriesList.map((option) => {
  return {
    label: option.label,
    value: option.label,
    code: option.value,
  };
});

function CustomOption(props) {
  const {
    data: { code },
  } = props;

  // large list performance workaround: https://github.com/JedWatson/react-select/issues/3128
  const { onMouseMove, onMouseOver, ...newInnerProps } = props.innerProps;
  const newProps = { ...props, innerProps: newInnerProps };

  return (
    <div className={styles.countrySelectOptionWrapper}>
      <components.Option {...newProps} style={{}}>
        <div className={styles.countrySelectOption}>
          <div className={styles.countrySelectOptionImage}>
            <span className={`flag flag-exist flag-${code.toLowerCase()}`} />
          </div>
          <div className={styles.countrySelectOptionText}>{props.children}</div>
        </div>
      </components.Option>
    </div>
  );
}

function CustomSingleValue(props) {
  const {
    data: { code },
  } = props;
  return (
    <components.SingleValue {...props}>
      <div className={styles.countrySelectValue}>
        <div className={styles.countrySelectValueImage}>
          <span className={`flag flag-exist flag-${code.toLowerCase()}`} />
        </div>
        <div className={styles.countrySelectValueText}>{props.children}</div>
      </div>
    </components.SingleValue>
  );
}

export default function(props) {
  return (
    <FieldSelect
      {...props}
      components={{ Option: CustomOption, SingleValue: CustomSingleValue }}
      filterOption={createFilter({ ignoreAccents: false })}
      options={options}
    />
  );
}
