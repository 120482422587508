import { createApiAction } from 'core/Utils/reduxUtils';

export const getAccounts = createApiAction('ACCOUNT_MANAGER_PAGE/GET_LIST_ACCOUNTS');
export const addAccount = createApiAction('ACCOUNT_MANAGER_PAGE/ADD_ACCOUNT');
export const getClient = createApiAction('ACCOUNT_MANAGER_PAGE/GET_CLIENT');
export const updateClient = createApiAction('ACCOUNT_MANAGER_PAGE/UPDATE_CLIENT');

export const downloadApplication = createApiAction('ACCOUNT_MANAGER_PAGE/DOWNLOAD_APPLICATION');
export const uploadDocument = createApiAction('ACCOUNT_MANAGER_PAGE/UPLOAD_DOC');
export const deleteDocument = createApiAction('ACCOUNT_MANAGER_PAGE/DELETE_DOC');

export const submitClient = createApiAction('ACCOUNT_MANAGER_PAGE/SUBMIT_CLIENT');
export const unsubmitClient = createApiAction('ACCOUNT_MANAGER_PAGE/UNSUBMIT_CLIENT');