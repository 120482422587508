// @flow
import { tradingProfileValidationSchema } from 'core/Validation/clientValidation';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import type { ContextRouter } from 'react-router-dom';
import { FormGroup, Row, Col, Form, Button, CardHeader, CardFooter, Table} from 'reactstrap';
import { Link } from "react-router-dom";
import ReactTooltip from 'react-tooltip';

import type { UserType } from 'core/Models/User';
import type { ClientType } from 'core/Models/Client';
import { VERIFICATION_STATUS, FREQUENCY } from 'core/Models/Enums';
import { FieldInput, FieldSelect, FormikPersist } from 'core/Components/Form';
import { onlyLatinLetters, onlyNumbers } from 'core/Components/Form/normalizers';

import { selectClient, selectUser } from '../../../../Selectors';

import * as actions from '../actions.js';

const mapPropsToValues = (props) => props.toJS();

type FormikEnhancerProps = {
  ...ContextRouter,
  parentPath: string,
};

export default function FormikEnhancer(props: FormikEnhancerProps) {
  const { parentPath } = props;
  const [redirectTo, setRedirectTo] = useState(false);

  const dispatch = useDispatch();
  const submitForm = (values) => dispatch(actions.sendTradingProfile(values));
  const unsubmitForm = () => dispatch(actions.unsubmitTradingProfile({}));

  const client: ClientType = useSelector(selectClient);

  const onSubmit = (values, { setSubmitting }) => {
    const { stocksFrequency, bondsFrequency, derivativesFrequency, forexFrequency, description, ...intValues }  = values;
    const parseIntValues = Object.entries(intValues).reduce((n, [k, v]) => {
      n[k] = parseInt(v, 10);
      return n;
    }, {});
    const data = {
      ...parseIntValues,
      stocksFrequency,
      bondsFrequency,
      derivativesFrequency,
      forexFrequency,
      description,
    };
    const cb = () => {
      setSubmitting(false);
      setRedirectTo(true);
    };
    submitForm({ values: data, cb });
  };

  const initialValues = mapPropsToValues(client.tradingProfile);

  if (redirectTo) {
    return <Redirect to={parentPath} />;
  }

  return (
    <Formik
      autoComplete="off"
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={(props) => (
        <TradingProfileForm {...props} status={client.tradingProfileStatus} unsubmitForm={unsubmitForm} />
      )}
      validationSchema={tradingProfileValidationSchema}
      isInitialValid={() => tradingProfileValidationSchema.isValidSync(initialValues)}
      enableReinitialize
    />
  );
}

type Props = {
  handleSubmit: (values: any) => void,
  values: any,
  isValid: boolean,
  isSubmitting: boolean,
  status: string,
  unsubmitForm: () => void,
};

function TradingProfileForm(props: Props) {
  const { handleSubmit, isValid, isSubmitting, status, unsubmitForm } = props;
  const fieldsDisabled = Boolean(isSubmitting || status === VERIFICATION_STATUS.PENDING_APPROVAL);

  const user: UserType = useSelector(selectUser);

  return (
    <>
      <ReactTooltip effect="float" />
      <CardHeader>
        <Link to="/verification/steps/additional/" style={{fontSize: 13}}>← Back to Additional Information</Link>
        <h1 className="mb-0 mt-2">Trading Profile</h1>
      </CardHeader>
      <Form role="form" onSubmit={handleSubmit} autoComplete="off">

          <Table striped bordered>
            <thead>
              <tr>
                <th>Asset Class</th>
                <th style={{width: 50}}>Experience</th>
                <th style={{width: 50}}>Knowledge</th>
                <th>Avg Frequency <i data-tip="Average frequency of transactions per month for the past year" className="far fa-question-circle" /></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Stocks</td>
                <td>
              
                    <FieldInput
                      name="stocksExperience"
                      id="stocksExperience"
                      disabled={fieldsDisabled}
                      normalize={onlyNumbers}
                      placeholder="1–10"
                      maxLength="2"
                      bsSize="sm"
                    />
                    
            
                </td>
                <td>
          
                    <FieldInput
                      name="stocksKnowledge"
                      id="stocksKnowledge"
                      disabled={fieldsDisabled}
                      normalize={onlyNumbers}
                      placeholder="1–10"
                      maxLength="2"
                      bsSize="sm"
                    />
                    
          
                </td>
                <td><FieldSelect
                  options={FREQUENCY.selectOptions}
                  name="stocksFrequency"
                  id="stocksFrequency"
                  disabled={fieldsDisabled}
                /></td>
              </tr>
              <tr>
                <td>Bonds <i data-tip="Bonds or other securitised debt" className="far fa-question-circle" /></td>
                <td>
              
                    <FieldInput
                      name="bondsExperience"
                      id="bondsExperience"
                      disabled={fieldsDisabled}
                      normalize={onlyNumbers}
                      placeholder="1–10"
                      maxLength="2"
                      bsSize="sm"
                    />
                    
      
                </td>
                <td><FieldInput
                  name="bondsKnowledge"
                  id="bondsKnowledge"
                  disabled={fieldsDisabled}
                  normalize={onlyNumbers}
                  placeholder="1–10"
                  maxLength="2"
                  bsSize="sm"
                />               
                 </td>
                <td><FieldSelect
                  options={FREQUENCY.selectOptions}
                  name="bondsFrequency"
                  id="bondsFrequency"
                  disabled={fieldsDisabled}
                /></td>
              </tr>
              <tr>
                <td>Forex</td>
                <td>
                 
                    <FieldInput
                      name="forexExperience"
                      id="forexExperience"
                      disabled={fieldsDisabled}
                      normalize={onlyNumbers}
                      placeholder="1–10"
                      maxLength="2"
                      bsSize="sm"
                    />
                    
       
                </td>
                <td><FieldInput
                  name="forexKnowledge"
                  id="forexKnowledge"
                  disabled={fieldsDisabled}
                  normalize={onlyNumbers}
                  placeholder="1–10"
                  maxLength="2"
                  bsSize="sm"
                />               
                 </td>
                <td><FieldSelect
                  options={FREQUENCY.selectOptions}
                  name="forexFrequency"
                  id="forexFrequency"
                  disabled={fieldsDisabled}
                /></td>
              </tr>
              <tr>
                <td>Derivatives <i data-tip="Options, futures, swaps, forwards, other derivatives" className="far fa-question-circle" /></td>
                <td>
                  
                    <FieldInput
                      name="derivativesExperience"
                      id="derivativesExperience"
                      disabled={fieldsDisabled}
                      normalize={onlyNumbers}
                      placeholder="1–10"
                      maxLength="2"
                      bsSize="sm"
                    />
                    
            
                </td>
                <td><FieldInput
                  name="derivativesKnowledge"
                  id="derivativesKnowledge"
                  disabled={fieldsDisabled}
                  normalize={onlyNumbers}
                  placeholder="1–10"
                  maxLength="2"
                  bsSize="sm"
                />               
                 </td>
                <td><FieldSelect
                  options={FREQUENCY.selectOptions}
                  name="derivativesFrequency"
                  id="derivativesFrequency"
                  disabled={fieldsDisabled}
                /></td>
              </tr>
            </tbody>
          </Table>
          <CardFooter>
          <Row>
            <Col xl="8" md="6">
              <FormGroup>
                <label className="muted_label" htmlFor="description">
                  Experience Description
                </label>
                <FieldInput
                  name="description"
                  id="description"
                  disabled={fieldsDisabled}
                  normalize={onlyLatinLetters}
                  type="textarea"
                />
              </FormGroup>
            </Col>
          </Row>
          </CardFooter>

        <CardFooter>
          {status === VERIFICATION_STATUS.PENDING_APPROVAL ? (
            <Button type="button" color="warning" onClick={unsubmitForm}>
              <i className="fas fa-times pr-2"></i>Unsubmit
            </Button>
          ) : (
            <Button type="submit" color="success" disabled={!isValid || fieldsDisabled}>
              <i className="fas fa-check pr-2"></i>Submit
            </Button>
          )}
          {status === VERIFICATION_STATUS.CLIENT_AWAITING ? (
            <FormikPersist autoComplete="off" name={`trading-profile-form_${user.id ?? 0}`} isSessionStorage />
          ) : null}
          <Button to="/support" tag={Link}>
            <i className="far fa-question-circle pr-2"/>Have Questions?
          </Button>
        </CardFooter>
      </Form>
    </>
  );
}
