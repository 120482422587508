import React from 'react';
import { Row, Col, CardHeader, CardBody, Button, Card, CardText, CardFooter } from 'reactstrap';
import { useSelector } from 'react-redux';

import type { UserType } from 'core/Models/User';

import { selectUser } from '../../../Selectors';

export default function SupportPage() {
  const user: UserType = useSelector(selectUser);

  function handleOpen() {
    if(window.chat) {
      let widget = document.querySelector(".b24-widget-button-wrapper");
      widget.style.display = "block";
      window.chat.open();
    } else {
      (function(w,d,u){
        var s=d.createElement('script');s.async=true;s.src=u+'?'+(Date.now()/60000|0);
        var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h);
      })(window,document,'https://cdn.bitrix24.com/b11783801/crm/site_button/loader_2_btmmez.js');
      window.addEventListener('onBitrixLiveChat', function(event) {
        window.chat = event.detail.widget;
        window.chat.subscribe({
          // eslint-disable-next-line no-undef
          type: BX.LiveChatWidget.SubscriptionType.widgetClose,
          callback: function(data) {
            let widget = document.querySelector(".b24-widget-button-wrapper");
            widget.style.display = "none"
          }
        });
        window.chat.setUserRegisterData({
          'hash': user.chatHash,
          'name': user.firstName,
          'lastName': user.lastName,
          'email': user.email,
        });
        window.chat.open();
      });
    }


  }
  return (
    <>
    <CardHeader tag="h1">Support Center</CardHeader>
    <CardBody>
      <p>We are here to help you with any matters, whenever they arise.</p>
      <p>Our Customer Support Team will provide you with prompt assistance and information.</p>
      <p>The most immediate response can be received around the clock on Monday-Friday.</p>
      <Row>
        <Col lg={6} md={12} className="mt-2">
          <Card>
            <CardHeader tag="h2" className="blue-background"><i className="far fa-envelope pr-2"></i>Email Support</CardHeader>
             <CardBody className="support-block">
              <CardText>We recommend using <b>Email (trading@investorseurope.com)</b> for questions concerning the following:</CardText>
              <ul>
                <li>Personal Data, Documentary changes</li>
                <li>Billing & Taxes</li>
                <li>Security or legal terms</li>
                <li>Minor difficulties when using platforms </li>
                <li>IB conditions</li>
              </ul>
            </CardBody>
            <CardFooter className="blue-background">
              <Button color="brand" style={{border: 0}} href="mailto:support@investorseurope.com">SEND MAIL</Button>
            </CardFooter>
               
          </Card>
        </Col>
        <Col md={6} sm={12} className="mt-2">
          <Card>
            <CardHeader tag="h2" className="blue-background"><i className="far fa-comments pr-2"></i> Live Chat</CardHeader>
            <CardBody className="support-block">
              <CardText>We recommend to solve the following matters via <b>Live Chat</b>:</CardText>
              <ul className="mb-0 pb-0">
                <li>Common questions</li>
                <li>Platforms comparison</li>
                <li>Minor difficulties in using platforms</li>
                <li>Placing trades and trading issues</li>
                <li>Questions about the Client Area</li>
              </ul>
            </CardBody>
            <CardFooter className="blue-background">
              <Button color="brand" style={{border: 0}} onClick={handleOpen}>OPEN CHAT</Button>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </CardBody>
    </>
  );
}
