import React from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Media,
  Nav,
  Navbar,
  UncontrolledDropdown,
  Card, 
  CardHeader
} from 'reactstrap';
import { format } from 'date-fns';
import type { UserType } from 'core/Models/User';

type Props = {
  user: UserType
}

export default function TopNavbar(props: Props) {
  const { user } = props;

  return (
    <>

    <Navbar className="navbar-top" expand="md" id="navbar-main">

      <Container fluid>


          <UncontrolledDropdown nav>

            <DropdownToggle className="pr-0" nav>
              <Media className="align-items-center">
                <Media className="d-none d-md-block">
                  <span className="mb-0 text-sm font-weight-bold">
                    Message Center {user.notes.length > 0 && <span className="messages-flicker ml-1"><i className="far fa-bell pr-2"/>{user.notes.length}</span>}
                  </span>
                </Media>
              </Media>
            </DropdownToggle>

            <DropdownMenu className="dropdown-menu-arrow message-center">
              <Card>
                <CardHeader tag="h3" className="blue-background">                    <i className="fas fa-envelope-open-text pr-2"/>Message Center</CardHeader>
       
                  {user.notes && user.notes.map((note, idx) => {
                    return (
                        <div className="message-center-item" key={idx}>
                          <span className="text-primary">{format(new Date(note.createdAt), 'dd.MM.yyyy HH:mm')}: </span> 
                          <span>{note.content}</span>
                        </div>
                    );
                  })}
                  {!user.notes && <DropdownItem disabled>No messages</DropdownItem>}
             
              </Card>
            </DropdownMenu>

          </UncontrolledDropdown>


        <Nav className="align-items-center d-none d-md-flex" navbar>
        
          <UncontrolledDropdown nav>
            <DropdownToggle className="pr-0" nav>
              <Media className="align-items-center">
                <Media className="ml-2 d-none d-md-block">
                  <span className="mb-0 text-sm font-weight-bold"><i className="fas fa-user-tie pr-2"></i>{user.firstName} {user.lastName}</span>
                </Media>
              </Media>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem>
                <i className="far fa-envelope"/>
                <span>{user.email}</span>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem to="/support" tag={Link}>
                <i className="fas fa-info-circle" />
                <span>Support Center</span>
              </DropdownItem>
              <DropdownItem divider />
              <Link to="/logout" className="dropdown-item">
                <i className="fas fa-arrow-right"></i>
                <span>Sign out</span>
              </Link>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Container>
         
    </Navbar>
    {user.status === 'IN_VERIFICATION' && (
      <div className="global-notification"><i className="far fa-check-circle pr-2"/>Thank you for the information provided. Your information is being verified. It usually takes up to 3 business days.</div>
    )}
    </>
  );
}
