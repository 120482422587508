import { Map, List } from 'immutable';
import { createReducer } from 'core/Utils/reduxUtils';

import * as actions from './actions';

const initialState = Map({
  referrals: List(),
});

export default createReducer({
  [actions.getReferrals.success]: (state, referrals) => state.set('referrals', List(referrals)),
  [actions.addReferral.success]: (state, referrals) => state.set('referrals', List(referrals)),
}, initialState);
