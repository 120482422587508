import React from 'react';
import { Field, ErrorMessage } from 'formik';
import { Label, FormFeedback } from 'reactstrap';
import Select from 'react-select';

import styles from './styles.module.scss';

const selectStyles = {
  control: (styles) => ({
    ...styles,
    borderRadius: '2px',
    borderColor: '#ADADAD',
    '&:hover': { borderColor: '#8a8a8a' },
    '&:focus': { borderColor: '#8a8a8a' },
    boxShadow: 'none',
  }),
};

export default function(props) {
  return (
    <Field
      {...props}
      render={({ field, form: { touched, errors, handleChange, setFieldTouched } }) => {
        const { isMulti, options, maxWidth, disabled } = props;

        const selectValue = isMulti
          ? options.filter((o) => (field.value || []).includes(o.value))
          : options.find((o) => o.value === field.value);

        const onChange = (value) => {
          const v = isMulti ? (value || []).map((val) => val.value) : value.value;
          handleChange(field.name)(v);
        };

        return (
          <>
            {props.label ? <Label className="muted_label" for={field.name}>{props.label}</Label> : null}
            <div style={{ maxWidth, width: '100%' }}>
              <Select
                {...props}
                {...field}
                onBlur={() => setFieldTouched(field.name, true)}
                onChange={onChange}
                value={selectValue}
                invalid={Boolean(touched[field.name] && errors[field.name])}
                type="select"
                styles={selectStyles}
                className={touched[field.name] && errors[field.name] && styles.selectError}
                isDisabled={disabled}
              />
            </div>
            <ErrorMessage name={field.name}>
              {(msg) => <FormFeedback className={styles.errorMessage}>{msg}</FormFeedback>}
            </ErrorMessage>
          </>
        );
      }}
    />
  );
}
