import noop from 'lodash/noop';
import { takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import * as userApi from 'core/Api/user/userApi';

import * as mainActions from '../../../Actions';
import * as actions from './actions'

export function* createUserBankAccountSaga({ payload: { values, onSuccess = noop, onError = noop  } = {} }) {
  try {
    yield call(userApi.createUserBankAccount, { values });
    yield call(toast.success, 'Bank account added');
    yield put(mainActions.getUser());
    yield call(onSuccess);
  } catch (e) {
    yield call(onError);
  }
}

export function* deleteUserBankAccountSaga({ payload: { accountId, onSuccess = noop, onError = noop  } = {} }) {
  try {
    yield call(userApi.deleteUserBankAccount, { accountId });
    yield call(toast.success, 'Bank account deleted');
    yield put(mainActions.getUser());
    yield call(onSuccess);
  } catch (e) {
    yield call(onError);
  }
}

export default [
  takeLatest(actions.createUserBankAccount, createUserBankAccountSaga),
  takeLatest(actions.deleteUserBankAccount, deleteUserBankAccountSaga),
];
