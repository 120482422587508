// @flow
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import type { ContextRouter } from 'react-router-dom';
import { FormGroup, Row, Col, Form, Button, CardHeader, CardBody, CardFooter } from 'reactstrap';
import { Link } from 'react-router-dom';

import { investmentObjectivesValidationSchema } from 'core/Validation/clientValidation';
import type { UserType } from 'core/Models/User';
import type { ClientType } from 'core/Models/Client';
import { SOURCE_OF_FUNDS, VERIFICATION_STATUS, INVESTMENT_OBJECTIVES } from 'core/Models/Enums';
import { FieldInput, FieldSelect, FormikPersist } from 'core/Components/Form';
import { alphanumWithSymbols, onlyNumbers } from 'core/Components/Form/normalizers';

import { selectClient, selectUser } from '../../../../Selectors';
import * as actions from '../actions.js';

const mapPropsToValues = (props) => props.toJS();

type FormikEnhancerProps = {
  ...ContextRouter,
  parentPath: string,
};

export default function FormikEnhancer(props: FormikEnhancerProps) {
  const { parentPath } = props;
  const [redirectTo, setRedirectTo] = useState(false);

  const dispatch = useDispatch();
  const submitForm = (values) => dispatch(actions.sendInvestmentsObjectives(values));
  const unsubmitForm = () => dispatch(actions.unsubmitInvestmentsObjectives({}));

  const client: ClientType = useSelector(selectClient);

  const onSubmit = (values, { setSubmitting }) => {
    const data = {
      ...values,
    };
    const cb = () => {
      setSubmitting(false);
      setRedirectTo(true);
    };
    submitForm({ values: data, cb });
  };

  const initialValues = mapPropsToValues(client.investmentObjectives);

  if (redirectTo) {
    return <Redirect to={parentPath} />;
  }

  return (
    <Formik
      autoComplete="off"
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={(props) => (
        <InvestmentsObjectivesForm {...props} status={client.investmentObjectivesStatus} unsubmitForm={unsubmitForm} />
      )}
      validationSchema={investmentObjectivesValidationSchema}
      isInitialValid={() => investmentObjectivesValidationSchema.isValidSync(initialValues)}
      enableReinitialize
    />
  );
}

type Props = {
  handleSubmit: (values: any) => void,
  values: any,
  isValid: boolean,
  isSubmitting: boolean,
  status: string,
  unsubmitForm: () => void,
};

function InvestmentsObjectivesForm(props: Props) {
  const { handleSubmit, isValid, isSubmitting, status, unsubmitForm, values } = props;
  const fieldsDisabled = Boolean(isSubmitting || status === VERIFICATION_STATUS.PENDING_APPROVAL);

  const user: UserType = useSelector(selectUser);

  return (
    <>
      <CardHeader>
        <Link to="/verification/steps/additional/" style={{ fontSize: 13 }}>
          ← Back to Additional Information
        </Link>
        <h1 className="mt-2">INVESTMENT OBJECTIVES</h1>
      </CardHeader>
      <Form role="form" onSubmit={handleSubmit} autoComplete="off">
        <CardBody>
          <Row>
            <Col xl="4" md="6">
              <FormGroup>
                <label className="muted_label" htmlFor="objectives">
                  Investment Objectives *
                </label>
                <FieldSelect
                  options={INVESTMENT_OBJECTIVES.selectOptions}
                  name="objectives"
                  id="objectives"
                  disabled={fieldsDisabled}
                />
              </FormGroup>
            </Col>
            <Col xl="4" md="6">
              <FormGroup>
                <label className="muted_label" htmlFor="networthAmount">
                  Client’s Net Worth *
                </label>
                <FieldInput
                  name="networthAmount"
                  id="networthAmount"
                  disabled={fieldsDisabled}
                  normalize={onlyNumbers}
                  placeholder="$"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xl="4" md="6">
              <FormGroup>
                <label className="muted_label" htmlFor="incomeAmount">
                  Client’s Annual Net Income *
                </label>
                <FieldInput
                  name="incomeAmount"
                  id="incomeAmount"
                  disabled={fieldsDisabled}
                  normalize={onlyNumbers}
                  placeholder="$"
                />
              </FormGroup>
            </Col>
            <Col xl="4" md="6">
              <FormGroup>
                <label className="muted_label" htmlFor="investAmount">
                  Amount you intend to invest *
                </label>
                <FieldInput
                  name="investAmount"
                  id="investAmount"
                  disabled={fieldsDisabled}
                  normalize={onlyNumbers}
                  placeholder="$"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xl="4" md="6">
              <FormGroup>
                <label className="muted_label" htmlFor="sourceOfFunds">
                  The source of funds you are going to invest *
                </label>
                <FieldSelect
                  options={SOURCE_OF_FUNDS.selectOptions}
                  name="sourceOfFunds"
                  id="sourceOfFunds"
                  disabled={fieldsDisabled}
                />
              </FormGroup>
            </Col>
            {values.sourceOfFunds === SOURCE_OF_FUNDS.OTHER ||
            values.sourceOfFunds === SOURCE_OF_FUNDS.OWNER_OF_BUSINESS ? (
              <Col xl="4" md="6">
                <FormGroup>
                  <label className="muted_label" htmlFor="sourceOfFundsDescription">
                    Please provide details
                  </label>
                  <FieldInput
                    name="sourceOfFundsDescription"
                    id="sourceOfFundsDescription"
                    disabled={fieldsDisabled}
                    normalize={alphanumWithSymbols}
                  />
                </FormGroup>
              </Col>
            ) : null}
          </Row>
          {/* <Row>
            <Col xl="4" md="6">
              <FormGroup>
                <label className="muted_label" htmlFor="tin">
                  Tax Identification Number (TIN) *
                </label>
                <FieldInput name="tin" id="tin" disabled={fieldsDisabled} normalize={alphanumWithSymbols} />
              </FormGroup>
            </Col>
            <Col xl="4" md="6">
              <FormGroup>
                <label className="muted_label" htmlFor="tinCountry">
                  Country to which TIN applies *
                </label>
                <FieldCountrySelect name="tinCountry" id="tinCountry" disabled={fieldsDisabled} />
              </FormGroup>
            </Col>
          </Row> */}
        </CardBody>
        <CardFooter>
          {status === VERIFICATION_STATUS.PENDING_APPROVAL ? (
            <Button type="button" color="warning" onClick={unsubmitForm}>
              <i className="fas fa-times pr-2"></i>Unsubmit
            </Button>
          ) : (
            <Button type="submit" color="success" disabled={!isValid || fieldsDisabled}>
              <i className="fas fa-check pr-2"></i>Submit
            </Button>
          )}
          {status === VERIFICATION_STATUS.CLIENT_AWAITING ? (
            <FormikPersist
              autoComplete="off"
              name={`investments-objectives-form_${user.id ?? 0}`}
              client-formisSessionStorage
            />
          ) : null}
          <Button to="/support" tag={Link}>
            <i className="far fa-question-circle pr-2" />
            Have Questions?
          </Button>
        </CardFooter>
      </Form>
    </>
  );
}
