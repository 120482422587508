import * as Yup from 'yup'
import { CLIENT_TYPE } from '../Models/Enums'

export const individualDdlSchema = Yup.object().shape({
  fullName: Yup.string().required('Please provide an full name'),
  address: Yup.string().required('Please provide an residence address'),
  description: Yup.string().nullable(),
  passportNumber: Yup.string().required('Please provide an passport number'),
  passportCountry: Yup.string().required('Please provide an citizenship'),
  passportExpiryDate: Yup.date().required('Please provide an passport expiry date'),
  tins: Yup.array().of(
    Yup.object().shape({
      type: Yup.string().required(),
      tin: Yup.string().required(),
      country: Yup.string(),
    })
  ),
})

export const corporateDdlSchema = Yup.object().shape({
  fullName: Yup.string().required('Please provide an company name'),
  address: Yup.string().required('Please provide an registered address'),
  description: Yup.string().nullable(),
  operatingAddress: Yup.string().required('Please provide an operating address'),
  tins: Yup.array().of(
    Yup.object().shape({
      type: Yup.string().required(),
      tin: Yup.string().required(),
      country: Yup.string(),
    })
  ),
  persons: Yup.array().of(
    Yup.object().shape({
      type: Yup.string().required(),
      name: Yup.string().required(),
      passportNumber: Yup.string().required(),
      passportExpiryDate: Yup.date().required(),
      tinType: Yup.string().required(),
      tin: Yup.string().required(),
      tinCountry: Yup.string().required(),
    })
  ),
})
