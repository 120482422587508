import { Map, List } from 'immutable';
import { createReducer } from 'core/Utils/reduxUtils';

import * as actions from './actions';

const initialState = Map({
  accounts: List(),
  client: {},
});

export default createReducer({
  [actions.getAccounts.success]: (state, accounts) => state.set('accounts', List(accounts)),
  [actions.addAccount.success]: (state, accounts) => state.set('accounts', List(accounts)),
  [actions.getClient.success]: (state, client) => state.set('client', client),
  [actions.uploadDocument.success]: (state, client) => state.set('client', client),
  [actions.deleteDocument.success]: (state, client) => state.set('client', client),
  [actions.submitClient.success]: (state, client) => state.set('client', client),
  [actions.unsubmitClient.success]: (state, client) => state.set('client', client),
}, initialState);
