import noop from 'lodash/noop'
import { takeLatest, call, put } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import * as userApi from 'core/Api/user/userApi'
import * as actions from './actions'
import * as mainActions from '../../../Actions'

export function* createDueDiligenceFormSaga({ payload: { onSuccess = noop, onError = noop } = {} }) {
  try {
    const result = yield call(userApi.createDueDiligenceForm)
    yield put(actions.createDueDiligenceForm.success(result))
    window.location.replace(`due-diligence/view/${result.id}`)
    yield call(onSuccess)
  } catch (e) {
    yield put(actions.createDueDiligenceForm.error(e))
    yield call(onError)
  }
}

export function* submitDueDiligenceFormSaga({ payload: { id, values, onSuccess = noop, onError = noop } = {} }) {
  try {
    const result = yield call(userApi.submitDueDiligenceForm, { id, values })
    yield put(actions.submitDueDiligenceForm.success(result))
    yield put(mainActions.getClient())
    yield put(mainActions.getUser())
    yield call(onSuccess)
    yield call(toast.success, 'Form Submitted')
  } catch (e) {
    yield call(onError)
  }
}

export function* getDueDiligenceFormSaga({ payload: { id, onSuccess = noop, onError = noop } = {} }) {
  try {
    const result = yield call(userApi.getDueDiligenceForm, { id })
    yield put(actions.getDueDiligenceForm.success(result))

    yield call(onSuccess)
  } catch (e) {
    yield put(actions.getDueDiligenceForm.error(e))
    yield call(onError)
  }
}

export function* uploadDocumentSaga({ payload: { payload, onSuccess = noop, onError = noop } = {} }) {
  try {
    const form = payload?.successful?.[0]?.response?.body
    if (form) {
      yield put(actions.uploadDocument.success(form))
    }
  } catch (e) {
    yield put(actions.uploadDocument.error(e))
  }
}

export default [
  takeLatest(actions.createDueDiligenceForm, createDueDiligenceFormSaga),
  takeLatest(actions.submitDueDiligenceForm, submitDueDiligenceFormSaga),
  takeLatest(actions.getDueDiligenceForm, getDueDiligenceFormSaga),
  takeLatest(actions.uploadDocument, uploadDocumentSaga),
]
