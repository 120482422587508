// @flow
import * as React from 'react';
import { Modal as ReactModal } from 'reactstrap';

// import styles from './styles.module.scss';

type Props = {
  isOpen: boolean,
  children: React.Node,
  width?: number,
  toggle: () => void,
};

export function Modal(props: Props) {
  const { isOpen, children, width = 680, toggle } = props;

  return (
    <ReactModal {...props} isOpen={isOpen} toggle={toggle} style={{ maxWidth: width }}>
      {children}
    </ReactModal>
  );
}
