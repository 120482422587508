import { createApiAction } from 'core/Utils/reduxUtils';

export const sendClientInformation = createApiAction('VERIFICATION/SEND_CLIENT_INFORMATION');
export const unsubmitClientInformation = createApiAction('VERIFICATION/UNSUBMIT_CLIENT_INFORMATION');

export const downloadClientDoc = createApiAction('VERIFICATION/DOWNLOAD_CLIENT_DOC');
export const loadDocument = createApiAction('VERIFICATION/DELETE_DOCUMENT');
export const deleteDocument = createApiAction('VERIFICATION/DELETE_DOCUMENT');

export const sendBillingInformation = createApiAction('VERIFICATION/SEND_BILLING_INFORMATION');
export const unsubmitBillingInformation = createApiAction('VERIFICATION/UNSUBMIT_BILLING_INFORMATION');

export const sendInvestmentsObjectives = createApiAction('VERIFICATION/SEND_INVESTMENTS_OBJECTIVES');
export const unsubmitInvestmentsObjectives = createApiAction('VERIFICATION/UNSUBMIT_INVESTMENTS_OBJECTIVES');

export const sendTradingProfile = createApiAction('VERIFICATION/SEND_TRADING_PROFILE');
export const unsubmitTradingProfile = createApiAction('VERIFICATION/UNSUBMIT_TRADING_PROFILE');
