import React, { useState, useEffect } from 'react';
import { CardHeader, CardBody, CardFooter, Table, Button } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';

import { format } from 'date-fns';
import { STANDARD_TIME_DATE } from 'core/Utils/dateUtils';
import type { UserType } from 'core/Models/User';

import { selectUser } from './../../../Selectors';
import AddReferralModal from './AddReferralModal';

import { selectUserReferrals } from './selectors';
import * as actions from './actions';

export default function PartnerPage() {
  const user: UserType = useSelector(selectUser);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getReferrals({ refId: user.id }));
  }, [user.id, dispatch]);

  const userReferrals = useSelector(selectUserReferrals);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  return (
    <>
      <CardHeader tag="h1">Partner Page</CardHeader>
      {user.isPartner && <CardBody>Your partner ID: <b>#{user.id}</b></CardBody>}
        {(userReferrals.size > 0 && user.isPartner) && <Table hover bordered striped>
          <thead>
            <tr>
              <th>Referral Name</th>
              <th>Email</th>
              <th>Invited At</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {user.isPartner && userReferrals.map((referral, idx) => (
              <tr key={idx}>
                <td>{referral.firstName + ' ' + referral.lastName}</td>
                <td>{referral.email}</td>
                <td>{format(new Date(referral.createdAt), STANDARD_TIME_DATE)}</td>
                <td>
                  {referral.status === "NOT_VERIFIED" && <span className="text-muted"><i className="far fa-clock pr-2"/>Not Verified</span>}
                  {referral.status === "IN_VERIFICATION" && <span className="text-danger"><i className="far fa-clock pr-2"/>In Verification</span>}
                  {referral.status === "VERIFIED" && <span className="text-success"><i className="far fa-check-circle pr-2"/>Ready to Fund</span>}
                  {referral.status === "READY_TO_TRADE" && <span className="text-success"><i className="far fa-check-circle pr-2"/>Ready to Trade</span>}
                  {referral.status === "ACTIVE_CLIENT" && <span className="text-success"><i className="far fa-check-circle pr-2"/>Active Client</span>}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>}
        {(userReferrals.size === 0 && user.isPartner) && <CardBody>You have no referrals</CardBody>}
        {!user.isPartner && <CardBody>If you want to become our partner please send a message to <a href="mailto:support@investorseurope.com">support@investorseurope.com</a></CardBody>}

      <CardFooter>
        <Button color="success" disabled={!user.isPartner} onClick={toggleModal}>
          <i className="fas fa-plus pr-2"></i>Invite Referral
        </Button>
        <AddReferralModal isOpen={isModalOpen} toggleModal={toggleModal} />
      </CardFooter>
    </>
  );
}
