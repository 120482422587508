// @flow

import { Formik } from 'formik';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ModalHeader, ModalBody, ModalFooter, FormGroup, Form, Button, Row, Col } from 'reactstrap';

import { Modal } from 'core/Components/Modal';
import { referralValidationSchema } from 'core/Validation/userValidation';
import { FieldInput, FieldPhoneInput } from 'core/Components/Form';
import { alphanumWithSymbols } from 'core/Components/Form/normalizers';
import type { UserType } from 'core/Models/User';
import { selectUser } from './../../../Selectors';

import * as actions from './actions.js';

type AddReferralModalProps = {
  toggleModal: () => void,
  isOpen: boolean,
};

export default function AddReferralModal(props: AddReferralModalProps) {
  const { toggleModal, isOpen } = props;
  const dispatch = useDispatch();
  const submitForm = (values) => dispatch(actions.addReferral(values));

  const onSubmit = (values, { setSubmitting }) => {
    const { passwordConfirmation, phoneNumber, ...restValues } = values;

    const transformedValues = {
      ...restValues,
      phoneCode: `+${phoneNumber.countryCode}`,
      phoneNumber: `${phoneNumber.phoneWithoutCode}`,
    };

    const onSuccess = () => {
      setSubmitting(false);
      toggleModal();
    };
    const onError = () => {
      setSubmitting(false);
    };
    submitForm({ values: transformedValues, onSuccess, onError });
  };

  const user: UserType = useSelector(selectUser);

  return (
    <Modal isOpen={isOpen} toggle={toggleModal}>
      <ModalHeader className="blue-background" tag="h2" toggle={toggleModal}>
        Invite Referral
      </ModalHeader>
      <Formik
        autoComplete="off"
        onSubmit={onSubmit}
        initialValues={{ partnerText: user.partnerText }}
        render={(props) => <ReferralForm {...props} />}
        validationSchema={referralValidationSchema}
      />
    </Modal>
  );
}

type Props = {
  handleSubmit: (values: any) => void,
  isValid: boolean,
  isSubmitting: boolean,
  status?: string,
  text?: string,
  unsubmitForm?: () => void,
  isVerification?: boolean,
};

function ReferralForm(props: Props) {
  const { handleSubmit, isValid, isSubmitting } = props;
  const fieldsDisabled = Boolean(isSubmitting);

  return (
    <Form role="form" onSubmit={handleSubmit} autoComplete="off">
      <ModalBody>
      <Row>
        <Col>
          <FormGroup>
            <FieldInput label="First Name *" placeholder="e.g. John" name="firstName" id="firstName" disabled={fieldsDisabled} normalize={alphanumWithSymbols} />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <FieldInput label="Last Name *" placeholder="e.g. Doe" name="lastName" id="lastName" disabled={fieldsDisabled} normalize={alphanumWithSymbols} />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <FieldInput label="Email *" placeholder="e.g. john@example.com" name="email" id="email" disabled={fieldsDisabled} />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <FieldPhoneInput label="Phone Number *" placeholder="e.g. +1234567890" name="phoneNumber" id="phoneNumber" disabled={fieldsDisabled} />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <FieldInput label="Message *" rows={5} type="textarea" name="partnerText" id="partnerText" disabled={fieldsDisabled}/>
          </FormGroup>
        </Col>
      </Row>
      </ModalBody>

      <ModalFooter>
        <Button type="submit" color="brand" disabled={!isValid || fieldsDisabled}><i className="far fa-paper-plane pr-2"/>Send Invite</Button>
      </ModalFooter>

    </Form>
  );
}
