import { toast } from 'react-toastify';
import { takeLatest, call, put } from 'redux-saga/effects';
import noop from 'lodash/noop';

import * as authApi from 'core/Api/user/authApi';

import * as actions from './actions';

export function* registerUserSaga({ payload: { values, onSuccess = noop, onError = noop } }) {
  try {
    const user = yield call(authApi.registerUser, values);
    yield call(toast.success, 'Successful Registration');
    yield call(onSuccess);
    yield put(actions.registerUser.success(user));
  } catch (e) {
    yield call(onError);
    yield put(actions.registerUser.error(e));
  }
}

export function* sendSmsCodeSaga({ payload: { values, onError = noop } }) {
  try {
    yield call(authApi.sendSmsCode, values);
    yield call(toast.success, 'Code successfully sent');
  } catch (e) {
    yield call(onError);
    yield put(actions.sendSmsCode.error(e));
  }
}

export function* verifyEmailSaga({ payload: { token, onSuccess = noop } }) {
  try {
    yield call(authApi.verifyEmail, { token });
    yield call(toast.success, 'Email successfully verified');
    yield call(onSuccess);
  } catch (e) {
    yield put(actions.sendSmsCode.error(e));
  }
}

export default [
  takeLatest(actions.registerUser, registerUserSaga),
  takeLatest(actions.sendSmsCode, sendSmsCodeSaga),
  takeLatest(actions.verifyEmail, verifyEmailSaga),
];
