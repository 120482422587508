import axios from 'axios';
import { call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { apiError, authError, serverDown } from '../Actions';
import * as localStorageApi from './localStorageApi';

export const baseURL = process.env.REACT_APP_API_HOST;

export const apiRequest = axios.create({
  baseURL,
  timeout: 10000,
});

export default function* apiSaga(fn, ...params) {
  const method = fn.method || fn;
  const config = fn.config || {};

  const authToken = yield call(localStorageApi.getToken);
  const authHeader = authToken ? { Authorization: `Bearer ${authToken}` } : {};

  const extendedConfig = {
    ...config,
    headers: {
      ...authHeader,
    },
  };

  try {
    const result = yield call(method, ...params, extendedConfig);

    if (result?.status === 200 || result?.status === 204) {
      return yield result.data;
    }

    return yield Promise.reject(result);
  } catch (e) {
    const response = e?.response;
    const status = response?.status;

    const errorMessage = response?.data?.error?.message ?? 'Session expired';

    if (status === 502 || status === 503) {
      yield put(serverDown());
    }
    if (status === 403 || status === 401) {
      yield put(authError());
    }

    if (!fn.skipErrors && status && status !== 502) {
      yield put(apiError({ response, isException: typeof e !== 'string' }));
    }

    if (status !== 406) {
      yield call(toast.error, errorMessage.toString());
    } else {
      yield call(toast.error, 'Not acceptable');
    }

    return yield Promise.reject(response);
  }
}
