import React, { useState } from 'react';
import { CardHeader, CardBody, CardFooter, Button, Table } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';

import type { UserType } from 'core/Models/User';

import CreateBankAccountModal from './CreateBankAccountModal';

import { selectUser } from './../../../Selectors';
import * as actions from './actions';

export default function BankAccountsList() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const user: UserType = useSelector(selectUser);

  const dispatch = useDispatch();
  const deleteUserBankAccount = ({ accountId }) => dispatch(actions.deleteUserBankAccount({ accountId }));

  return (
    <>
      <CardHeader tag="h1">Billing</CardHeader>
      <CardBody>
        <p>
          All the billing procedures are tied directly to your account and take place in the <b>Clients Area</b> of the
          website. Use the forms below to provide us deposit or withdrawal instruction.
        </p>
        <p>
          Please note that when you fund your account for the first time, you are required to submit a bank statement
          where the following details are clearly seen: bank name, account number, account name and the transferred
          amount. All other information may be hidden.{' '}
        </p>
        <div>
          <a
            className="btn btn-simple mb-3 btn-sm"
            href="https://ie-static.ams3.cdn.digitaloceanspaces.com/documents/deposit_instructions.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fas fa-arrow-down pr-1"></i>Deposit Instruction
          </a>

          <a
            className="btn btn-simple mb-3 btn-sm"
            href="https://ie-static.ams3.cdn.digitaloceanspaces.com/documents/withdrawal_form.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fas fa-arrow-down pr-1"></i> Withdrawal Form
          </a>
        </div>
        <p className="mt-3">
          Please fill it in by hand, sign and send us a scanned copy to backoffice@investorseurope.com. Please specify
          your account name in the subject.
        </p>
        <p className="mb-0">
          Please note, that the deposit & withdrawal operations may take up to 5-7 working days (depending on the speed
          of your bank and its correspondent bank). In case of any questions arise , our managers are always ready to
          help you through our Support Center.
        </p>
      </CardBody>

      <CardHeader tag="h2" className="form-section" style={{ border: 0 }}>
        <i className="fas fa-university pr-2"></i>Connected Bank Accounts
      </CardHeader>
      {user.bankAccounts.size > 0 ? (
        <Table responsive hover>
          <thead>
            <tr>
              <th>Bank</th>
              <th>Account</th>
              <th>Account Beneficiary Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {user.bankAccounts.map((item, idx) => {
              return (
                <tr key={`ver_documents_${item.id}_${idx}`}>
                  <th scope="row">
                    {item.bank} ({item.swift})
                  </th>
                  <td>{item.account}</td>
                  <td>{item.accountBeneficiary}</td>
                  <td>
                    {idx > 0 && (
                      <Button color="simple" size="sm" onClick={() => deleteUserBankAccount({ accountId: item.id })}>
                        <i className="fas fa-times pr-2"></i>Delete
                      </Button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : null}

      {user.bankAccounts.size === 0 && <CardFooter>No Bank Accounts connected</CardFooter>}

      <CardFooter>
        <Button color="success" onClick={toggleModal}>
          <i className="fas fa-plus pr-2"></i>Add Bank Account
        </Button>
        <CreateBankAccountModal isOpen={isModalOpen} toggleModal={toggleModal} />
      </CardFooter>
    </>
  );
}
