// @flow
import React from 'react';
import { FormGroup, Row, Col, CardHeader, CardBody } from 'reactstrap';

import { FieldInput } from 'core/Components/Form';
import { alphanumWithSymbols } from 'core/Components/Form/normalizers';

type Props = {
  fieldsDisabled: boolean,
};

export default function IndividualTypeBlock(props: Props) {
  const { fieldsDisabled } = props;

  return (
    <>
      <CardHeader tag="h2" className="form-section">4. Please provide information about your occupation</CardHeader>
      <CardBody>
      
        <Row>
          <Col md="12">
            <p>If you are self-employed, please write "Self-employed" below and provide futher details of your business in Source of Funds on Step 3.</p>
          </Col>
        </Row>

        <Row>
          <Col xl="4" md="6">
            <FormGroup className="mb-0">
              <FieldInput label="Employer Company Name *" name="employerName" id="employerName" disabled={fieldsDisabled} normalize={alphanumWithSymbols} />
            </FormGroup>
          </Col>
          <Col xl="4" md="6">
            <FormGroup className="mb-0">
              <FieldInput label="Occupation *" name="occupation" id="occupation" disabled={fieldsDisabled} normalize={alphanumWithSymbols} />
            </FormGroup>
          </Col>
        </Row>

      </CardBody>
    </>
  );
}
