// @flow
import * as React from 'react'
import { Container, Row } from 'reactstrap'

import logo from 'core/Assets/img/brand/logo-white.svg'

type Props = {
  children: React.Node,
}

export default function AuthLayout(props: Props) {
  const { children } = props
  return (
    <div className="auth_splashscreen main-content d-flex align-items-start flex-column">
      <Container className="mb-auto p-2">
        <Row className="justify-content-center">
          <a href="https://investorseurope.com" target="_blank" rel="noopener noreferrer">
            <img className="my-3" src={logo} width="200" height="143" alt="Investors Europe" />
          </a>
        </Row>

        <Row className="justify-content-center">{children}</Row>
      </Container>

      <Container className="container p-2">
        <Row className="justify-content-center pb-3">
          <small className="text-light text-center">
            © 2024. Investors Europe (Mauritius) Limited is authorised and regulated by the FSC Mauritius, license
            C112011088.
          </small>
        </Row>
      </Container>
    </div>
  )
}
