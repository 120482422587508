// @flow
import React, { useState } from 'react'
import { NavLink as NavLinkRRD, Link } from 'react-router-dom'
import {
  Collapse,
  Modal,
  ModalHeader,
  // ModalBody,
  NavbarBrand,
  Navbar,
  Button,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
} from 'reactstrap'

import { USER_STATUS } from 'core/Models/Enums'

import logo from 'core/Assets/img/brand/logo-white.svg'

type SidebarType = {
  routes: Array<{ path: string, icon: string, name: string, access: Array<string>, additionalConditions: boolean }>,
  userVerificationStatus: string,
  ieId: string,
  linkedUsers: any,
}

export default function Sidebar(props: SidebarType) {
  const { routes, userVerificationStatus, ieId, linkedUsers } = props

  const [collapseOpen, setCollapseOpen] = useState(false)

  const toggleCollapse = () => setCollapseOpen(!collapseOpen)
  const closeCollapse = () => setCollapseOpen(false)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const toggleModal = () => setIsModalOpen(!isModalOpen)

  const routesForStatus = routes.filter((route) => route.access.includes(userVerificationStatus))

  return (
    <Navbar className="navbar-vertical fixed-left navbar-dark " expand="md" id="sidenav-main">
      <Container fluid>
        <NavbarBrand tag={Link} to="/" className="pt-0">
          <img alt="Investors Europe" className="navbar-brand-img" src={logo} />
        </NavbarBrand>
        <button className="navbar-toggler" type="button" onClick={toggleCollapse}>
          <span className="navbar-toggler-icon" />
        </button>
        <Collapse navbar isOpen={collapseOpen}>
          <div className="navbar-collapse-header d-md-none">
            <Row>
              <Col className="collapse-brand" xs="10">
                <Link to="/">
                  <h2>Investors Europe</h2>
                </Link>
              </Col>
              <Col className="collapse-close" xs="2">
                <button className="navbar-toggler" type="button" onClick={toggleCollapse}>
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>
          <div className="sidebarStatus">
            <p>
              <span>Status:</span> {USER_STATUS.getLabel(userVerificationStatus)}
            </p>

            {ieId && (
              <p>
                <span>Client ID:</span> {ieId}
              </p>
            )}

            {linkedUsers.length ? (
              <Button size="sm" className="px-0 text-white underline" onClick={toggleModal}>
                <i className="fas fa-retweet pr-1" />
                Switch Account
              </Button>
            ) : null}
          </div>
          <Nav navbar className="sidebar">
            {routesForStatus.map(({ path, icon, name }, key) => (
              <NavItem key={`sidebar_nav_item_${key}`}>
                <NavLink to={path} tag={NavLinkRRD} onClick={closeCollapse} activeClassName="active">
                  <i className={icon} />
                  {name}
                </NavLink>
              </NavItem>
            ))}

            <NavItem>
              <NavLink to="/logout" tag={NavLinkRRD} onClick={closeCollapse} activeClassName="active">
                <i className="fas fa-arrow-right"></i>
                Sign out
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
      <Modal isOpen={isModalOpen} toggle={toggleModal}>
        <ModalHeader tag="h2" toggle={toggleModal}>
          Switch Account
        </ModalHeader>

        <ListGroup>
          <ListGroupItem tag="button" action onClick={toggleModal} color="light">
            Account #1 (current)
          </ListGroupItem>
          <ListGroupItem tag="button" action onClick={toggleModal}>
            Switch to Account #2
          </ListGroupItem>
        </ListGroup>
      </Modal>
    </Navbar>
  )
}
