import { call } from 'redux-saga/effects';

import apiSaga, { apiRequest } from '../index';

export function* registerUser(credentials) {
  return yield call(apiSaga, apiRequest.post, 'users/', credentials);
}

export function* sendSmsCode({ phoneNumber, type }) {
  return yield call(apiSaga, apiRequest.post, 'users/phone-verification', { phoneNumber, type });
}

export function* verifyEmail({ token }) {
  return yield call(apiSaga, apiRequest.get, `users/email-verification/${token}`);
}

export function* loginUser(credentials) {
  return yield call(apiSaga, apiRequest.post, 'users/login', credentials);
}

export function* sendResetPasswordLink(credentials) {
  return yield call(apiSaga, apiRequest.post, 'auth/password-reset', credentials);
}


export function* checkResetPasswordToken({ token }) {
  return yield call(apiSaga, apiRequest.get, `auth/password-reset/${token}`);
}


export function* changePassword(credentials) {
  return yield call(apiSaga, apiRequest.post, 'auth/password-change', credentials);
}

export function* getUser() {
  return yield call(apiSaga, apiRequest.get, 'users/me');
}
