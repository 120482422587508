import { createSelector } from 'reselect';

const selectAmSpace = (state) => state.get('amPage');

export const selectAmAccounts = createSelector(
  selectAmSpace,
  (amPage) => amPage.get('accounts')
);

export const selectAmClient = createSelector(
  selectAmSpace,
  (amPage) => amPage.get('client')
);