// import type { UserType } from 'core/Models/User';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ToastContainer, cssTransition, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';

import 'core/Assets/scss/react/plugins/react-datepicker.scss';
import 'core/Assets/vendor/nucleo/css/nucleo.css';
import 'core/Assets/vendor/@fortawesome/fontawesome-free/css/all.min.css';
import 'core/Assets/scss/bundle.scss';

import { PrivateRoute } from './Routes';
import configureStore from './configureStore';

import LoginPage from './Pages/LoginPage';
import ResetPasswordEmailPage from './Pages/LoginPage/ResetPasswordEmailPage';
import ResetPasswordPage from './Pages/LoginPage/ResetPasswordPage';
import LogoutPage from './Pages/LoginPage/LogoutPage';
import RegistrationPage from './Pages/RegistrationPage';
import RegistrationSuccessPage from './Pages/RegistrationPage/RegistrationSuccessPage';
import EmailVerificationPage from './Pages/RegistrationPage/EmailVerificationPage';
import InnerPage from './Pages/InnerPage';
// import { selectUser } from './Selectors';

const store = configureStore();

toast.configure({ className: 'black-background' });

const Zoom = cssTransition({
  enter: 'flipInX',
  exit: 'flipOutX',
  duration: [1000, 1000],
  appendPosition: false,
});

export default function() {
  return (
    <Provider store={store}>
      <Router>
        <WrappedApp />
      </Router>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        transition={Zoom}
        hideProgressBar={true}
        className="custom-toast"
        newestOnTop={true}
      />
    </Provider>
  );
}

function WrappedApp(props) {
  // const history = useHistory();
  // const user: UserType = useSelector(selectUser);

  // useLayoutEffect(() => {
  //   const { action, location } = history;

  //   window.dataLayer = window.dataLayer || [];

  //   const gtmData = localStorage.getItem('gtmData');

  //   if (user.id && user.status) {
  //     window.dataLayer.push({
  //       dimension2: user.id,
  //       dimension5: 'Autorized',
  //       dimension8: user.status,
  //     });
  //     // Save User Data to Local Storage
  //     if (!gtmData) {
  //       localStorage.setItem(
  //         'gtmData',
  //         JSON.stringify({
  //           dimension2: user.id,
  //           dimension8: user.status,
  //         })
  //       );
  //     }
  //   } else {
  //     if (gtmData) {
  //       window.dataLayer.push(JSON.parse(gtmData));
  //     }
  //     window.dataLayer.push({
  //       dimension5: 'NonAutorized',
  //     });
  //   }

  //   // Location Change Handler
  //   if (action === 'PUSH') {
  //     window.dataLayer.push({ event: 'Pageviews' });
  //     // console.log('PUSH: DL Pageview', location.pathname);
  //     toast.dismiss();
  //   }

  //   if(action === 'REPLACE' && (location.pathname === '/verification/steps/client/individual' || '/verification/steps/client/corporate')) {
  //     window.dataLayer.push({event: 'Pageviews'});
  //     // console.log('REPLACE: DL Pageview', location.pathname);
  //     toast.dismiss();
  //   }
  // }, [history.location.key, user, history]);

  return (
    <Switch>
      <Route path="/login" component={LoginPage} />
      <Route path="/reset_password_email" component={ResetPasswordEmailPage} />
      <Route path="/change_password/:token" component={ResetPasswordPage} />
      <Route path="/logout" component={LogoutPage} />
      <Route path="/registration" component={RegistrationPage} />
      <Route path="/registration_success" component={RegistrationSuccessPage} />
      <Route path="/email_verification/:token" component={EmailVerificationPage} />
      <PrivateRoute path="/" component={InnerPage} />
    </Switch>
  );
}
