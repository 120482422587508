import React, { useState, useEffect } from 'react';
import { CardHeader, CardBody, CardFooter, Table, Button } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';

import { format } from 'date-fns';
import { STANDARD_TIME_DATE } from 'core/Utils/dateUtils';
import type { UserType } from 'core/Models/User';
// import type { ClientType } from 'core/Models/Client';

import { selectUser } from '../../../Selectors';
import AddAccountModal from './AddAccountModal';
import ClientForm from './ClientForm';

import { selectAmAccounts } from './selectors';
import * as actions from './actions';

import { Route, Switch, Link } from 'react-router-dom';

const AccountInfo = (props) => {
  const clientId = props.match.params.id;

  return (
    <>
      <CardHeader tag="h1">
        <Link to={`/manager/accounts`}>Account Manager</Link> > Account #{clientId}
      </CardHeader>
      <ClientForm clientId={clientId} />
    </>
  );
};

function AccountsList() {
  const user: UserType = useSelector(selectUser);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getAccounts({ refId: user.id }));
  }, [user.id, dispatch]);

  const amAccounts = useSelector(selectAmAccounts);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  if (!user.amAccess) {
    return (
      <>
        <CardHeader tag="h1">Account Manager</CardHeader>
        <CardBody>
          You do not have access to this section. If you want to become Account Manager please send a message to{' '}
          <a href="mailto:support@investorseurope.com">support@investorseurope.com</a>
        </CardBody>
      </>
    );
  }

  return (
    <>
      <CardHeader tag="h1">Account Manager</CardHeader>
      {/* <CardBody>Access: <b>{user.amAccess.toString()}</b></CardBody> */}

      {amAccounts.size > 0 && user.amAccess && (
        <Table hover bordered striped>
          <thead>
            <tr style={{ backgroundColor: '#e1e2ed' }}>
              <th style={{ width: 50 }}>ID</th>
              <th>Account Name</th>
              <th>Email</th>
              <th>Created At</th>
              <th>Status</th>
              <th style={{ width: 100 }}></th>
            </tr>
          </thead>
          <tbody>
            {amAccounts.map((account, idx) => (
              <tr key={idx}>
                <td>#{account.id}</td>
                <td>{`${account.firstName} ${account.lastName}`}</td>
                <td>{account.email}</td>
                <td>{format(new Date(account.createdAt), STANDARD_TIME_DATE)}</td>
                <td>
                  {account.status === 'NOT_VERIFIED' && (
                    <span className="text-danger">
                      <i className="far fa-clock pr-2" />
                      Waiting for Verification Form
                    </span>
                  )}
                  {account.status === 'IN_VERIFICATION' && (
                    <span className="text-danger">
                      <i className="far fa-clock pr-2" />
                      Verification Form Submitted
                    </span>
                  )}
                  {account.status === 'VERIFIED' && (
                    <span className="text-success">
                      <i className="far fa-check-circle pr-2" />
                      Verified
                    </span>
                  )}
                  {account.status === 'READY_TO_TRADE' && (
                    <span className="text-success">
                      <i className="far fa-check-circle pr-2" />
                      Verified
                    </span>
                  )}
                  {account.status === 'ACTIVE_CLIENT' && (
                    <span className="text-success">
                      <i className="far fa-check-circle pr-2" />
                      Verified
                    </span>
                  )}
                </td>
                <td>
                  {' '}
                  <Link to={`/manager/accounts/${account.id}`}>
                    <Button
                      size="sm"
                      color="success"
                      disabled={account.status !== 'NOT_VERIFIED' && account.status !== 'IN_VERIFICATION'}
                    >
                      <i className="fas fa-edit pr-2" />
                      Edit Form
                    </Button>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {amAccounts.size === 0 && <CardBody>You don't have any accounts created yet.</CardBody>}

      <CardFooter>
        <Button color="success" disabled={!user.amAccess} onClick={toggleModal}>
          <i className="fas fa-user-plus pr-2"></i>Add Account
        </Button>
        <AddAccountModal isOpen={isModalOpen} toggleModal={toggleModal} />
      </CardFooter>
    </>
  );
}

export default function AccountManagerPage() {
  return (
    <Switch>
      <Route path="*/accounts/:id" component={AccountInfo} />
      <Route path="*/accounts" component={AccountsList} />
    </Switch>
  );
}
