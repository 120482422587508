import { all } from 'redux-saga/effects'

import RegistrationSagas from 'Pages/RegistrationPage/sagas'
import LoginSagas from 'Pages/LoginPage/sagas'
import VerificationSagas from 'Pages/InnerPage/VerificationPage/sagas'
import BillingPageSagas from 'Pages/InnerPage/BillingPage/sagas'
import ProfilePageSagas from 'Pages/InnerPage/ProfilePage/sagas'
import PartnerPageSagas from 'Pages/InnerPage/PartnerPage/sagas'
import AmPageSagas from 'Pages/InnerPage/AccountManagerPage/sagas'
import DdlPageSagas from 'Pages/InnerPage/DueDiligencePage/sagas'

export default function* root() {
  yield all([
    ...LoginSagas,
    ...RegistrationSagas,
    ...VerificationSagas,
    ...BillingPageSagas,
    ...ProfilePageSagas,
    ...PartnerPageSagas,
    ...AmPageSagas,
    ...DdlPageSagas,
  ])
}
