// @flow
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col } from 'reactstrap';

import { AuthLayout } from '../../Components/Layout';

type Props = {};

export default function RegistrationSuccessPage(props: Props) {
  return (
    <AuthLayout>
      <Col md={8}>
        <Card className="registration-success">
          <CardBody className="text-center">
            <h1>Thank you for registration!</h1>
            <span>To get access to Trader Room please proceed with email verification.</span>

             <Link className="btn btn-brand" to="/login">Back to Login Page</Link>
          </CardBody>
        </Card>
      </Col>
    </AuthLayout>
  );
}
