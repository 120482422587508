import { Map, fromJS } from 'immutable';

import { createReducer } from 'core/Utils/reduxUtils';
import { User } from 'core/Models/User';
import { Client } from 'core/Models/Client';

import * as mainActions from './Actions';

const initialState = Map({
  user: new User(),
  client: new Client(),
  token: undefined,
});

export default createReducer(
  {
    [mainActions.userLoggedIn]: (state, { user, token }) => state.set('token', token).set('user', new User(user)),
    [mainActions.userLoggedOut]: () => initialState,

    [mainActions.getUser.success]: (state, payload) => state.set('user', new User(payload)),
    [mainActions.updateUser]: (state, payload) => state.set('user', state.get('user').merge(fromJS(payload))),

    [mainActions.getClient.success]: (state, payload) => state.set('client', new Client(payload)),
  },
  initialState
);
