// @flow
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import type { ContextRouter } from 'react-router-dom';
import { CardBody, Col, Row } from 'reactstrap';

import type { UserBankAccountType } from 'core/Models/User';
import type { ClientType } from 'core/Models/Client';
import { billingInformationValidationSchema } from 'core/Validation/clientValidation';

import BankAccountForm from '../../../../Components/BankAccountForm';
import { selectClient, selectUserBankAccountFirst } from '../../../../Selectors';

import * as actions from '../actions.js';

type FormikEnhancerProps = {
  ...ContextRouter,
  parentPath: string,
};

export default function FormikEnhancer(props: FormikEnhancerProps) {
  const { parentPath } = props;
  const [redirectTo, setRedirectTo] = useState(false);

  const dispatch = useDispatch();
  const submitForm = (values) => dispatch(actions.sendBillingInformation(values));
  const unsubmitForm = () => dispatch(actions.unsubmitBillingInformation({}));

  const userBankAccountFirst: UserBankAccountType = useSelector(selectUserBankAccountFirst);
  const client: ClientType = useSelector(selectClient);

  const onSubmit = (values, { setSubmitting }) => {
    const { id, ...restValues } = values;
    const data = {
      ...restValues,
    };
    const cb = () => {
      setSubmitting(false);
      setRedirectTo(true);
    };
    submitForm({ values: data, cb });
  };

  const userBankAccountObject = userBankAccountFirst.toJS();
  // omit id prop
  const { id, ...initialValues } = userBankAccountObject;

  if (redirectTo) {
    return <Redirect to={parentPath} />;
  }

  return (
    <CardBody>
      <Link to="/verification/steps/additional/" style={{ fontSize: 13 }}>
        ← Back to Additional Information
      </Link>
      <h1 className="mb-2 mt-2">Billing Information</h1>
      <p className="mb-4">Please provide your bank account information. Please note that you must fund your account from the bank account specified during registration.</p>
      <Row>
        <Col xl="8" md="8">
          <Formik
            autoComplete="off"
            initialValues={initialValues}
            onSubmit={onSubmit}
            render={(props) => (
              <BankAccountForm {...props} status={client.bankDetailsStatus} unsubmitForm={unsubmitForm} isVerification />
            )}
            validationSchema={billingInformationValidationSchema}
            isInitialValid={() => billingInformationValidationSchema.isValidSync(initialValues)}
            enableReinitialize
          />
        </Col>
      </Row>
    </CardBody>
  );
}
