import { createAction, createReducer } from 'redux-act';

function createApiAction(actionName = '') {
  const action = createAction(actionName);
  action.predict = createAction(`${actionName}_PREDICT`);
  action.success = createAction(`${actionName}_SUCCESS`);
  action.error = createAction(`${actionName}_ERROR`);
  action.fulfill = createAction(`${actionName}_FULFILL`);

  return action;
}

export { createAction, createApiAction, createReducer };
