import { call } from 'redux-saga/effects'

import apiSaga, { apiRequest } from '../index'

export function* changeUserPassword({ values }) {
  return yield call(apiSaga, apiRequest.post, `users/change-password`, values)
}

export function* createUserBankAccount({ values }) {
  return yield call(apiSaga, apiRequest.post, `users/bank-accounts`, values)
}

export function* deleteUserBankAccount({ accountId }) {
  return yield call(apiSaga, apiRequest.delete, `users/bank-accounts/${accountId}`)
}

export function* getReferrals() {
  return yield call(apiSaga, apiRequest.get, `/users/partners/referrals`)
}

export function* addReferral({ values }) {
  return yield call(apiSaga, apiRequest.post, `/users/partners/referrals`, values)
}

export function* getAmAccounts() {
  return yield call(apiSaga, apiRequest.get, `/manager/accounts`)
}

export function* addAmAccount({ values }) {
  return yield call(apiSaga, apiRequest.post, `/manager/accounts`, values)
}

export function* getAmClient({ id }) {
  return yield call(apiSaga, apiRequest.get, `/manager/accounts/${id}`)
}

export function* updateAmClient({ id, values }) {
  return yield call(apiSaga, apiRequest.put, `/manager/accounts/${id}`, values)
}

export function* deleteAmDocument({ id }) {
  return yield call(apiSaga, apiRequest.delete, `/manager/accounts/document/${id}`)
}

export function* downloadAmClientDoc({ id }) {
  return yield call(
    apiSaga,
    { method: apiRequest.get, config: { responseType: 'blob' } },
    `/manager/accounts/application/${id}`
  )
}

export function* submitAmClient({ id }) {
  return yield call(apiSaga, apiRequest.get, `/manager/accounts/submit/${id}`)
}

export function* unsubmitAmClient({ id }) {
  return yield call(apiSaga, apiRequest.get, `/manager/accounts/unsubmit/${id}`)
}

/*
 * DDL Page
 */
export function* createDueDiligenceForm() {
  return yield call(apiSaga, apiRequest.get, `/due-diligence/`)
}

export function* submitDueDiligenceForm({ values, id }) {
  return yield call(apiSaga, apiRequest.put, `/due-diligence/${id}`, values)
}

export function* getDueDiligenceForm({ id }) {
  return yield call(apiSaga, apiRequest.get, `/due-diligence/${id}`)
}
