export default class EnumDescription {
  constructor(data) {
    this.values = Object.freeze(data);
    this.selectOptions = Object.values(data)
      .filter(({ availableForSelection = true }) => availableForSelection)
      .map(({ id, label, ...props }) => ({ value: id, label, ...props }));
    this.byId = Object.freeze(Object.values(this.values).reduce((acc, v) => ({ ...acc, [v.id]: v }), {}));
    this.allValues = Object.freeze(Object.values(this.values).map(({ id }) => id));

    // Create getters by key for quick access to enum values
    Object.keys(data).forEach((k) => {
      this[k] = data[k].id;
    });
  }
  getById(id, defaultValue = {}) {
    return this.byId[id] || defaultValue;
  }
  getLabel(id) {
    return this.getById(id, { label: id }).label;
  }
  map(cb) {
    return Object.keys(this.values).map((key) => (cb ? cb(this.values[key], key) : this.values[key]));
  }
}
