import * as Yup from 'yup'
import { CLIENT_TYPE, CORPORATE_POSITIONS } from '../Models/Enums'

export const requiredIfIndividualOrNoneCorporatePosition = (message, YupType = Yup.string) =>
  YupType().when(['type', 'position'], (type, position, schema) => {
    if (type === CLIENT_TYPE.INDIVIDUAL || position !== CORPORATE_POSITIONS.NONE_OF_ABOVE) {
      return schema.required(message).nullable()
    }
    return schema.nullable()
  })

export const commonValidationSchema = {
  type: Yup.string().required('Please select type of account'),
  ieId: Yup.string().nullable(),

  title: Yup.string().required('Please provide a title'),
  fullName: Yup.string().required('Full name is required'),

  citizenship: requiredIfIndividualOrNoneCorporatePosition('Please provide a citizenship'),
  passportNumber: requiredIfIndividualOrNoneCorporatePosition('Please provide a passport number'),
  passportExpiryDate: requiredIfIndividualOrNoneCorporatePosition('Please provide a passport expiry date', Yup.date),
  birthday: requiredIfIndividualOrNoneCorporatePosition('Please provide a date of birth', Yup.date),
  birthdayCountry: Yup.string().required('Please provide a birthday country'),
  birthdayCity: Yup.string().required('Please provide a birthday city'),

  address: requiredIfIndividualOrNoneCorporatePosition('Please provide a residental address'),
  addressCity: requiredIfIndividualOrNoneCorporatePosition('Please provide a residence country'),
  addressState: Yup.string().nullable(),
  residence: requiredIfIndividualOrNoneCorporatePosition('Please provide a residence country'),
  addressPostalCode: requiredIfIndividualOrNoneCorporatePosition('Please provide a residence country'),

  recommendedBy: Yup.string(),
  contacts: Yup.array().of(
    Yup.object().shape({
      type: Yup.string().required('Please specify a contact type'),
      isPrimary: Yup.boolean(),
    })
  ),
  tins: Yup.array().of(
    Yup.object().shape({
      type: Yup.string().required(),
      tin: Yup.string().required(),
      country: Yup.string().required(),
    })
  ),
}

export const individualPartValidationSchema = {
  occupation: Yup.string()
    .required('Please provide an occupation')
    .nullable(),
  employerName: Yup.string()
    .required('Please provide an employer name')
    .nullable(),
}

export const corporatePartValidationSchema = {
  position: Yup.string()
    .required('Please provide a position')
    .nullable(),
  companyName: Yup.string()
    .required('Please provide a full legal name')
    .nullable(),
  companyIncorporationDate: Yup.date('Invalid date')
    .required('Please provide a date of incorporation')
    .nullable(),
  companyIncorporationCountry: Yup.string()
    .required('Please provide a country of incorporation')
    .nullable(),
  companyRegnumber: Yup.string()
    .required('Please provide a registration number')
    .nullable(),
  companyDescription: Yup.string()
    .required('Please provide a description of business')
    .nullable(),
  companyAddress: Yup.string()
    .required('Please provide a company address')
    .nullable(),
  companyAddressCity: Yup.string()
    .required('Please provide a company city')
    .nullable(),
  companyAddressState: Yup.string().nullable(),
  companyAddressCountry: Yup.string()
    .required('Please provide a company country')
    .nullable(),
  companyAddressPostalCode: Yup.string()
    .required('Please provide a company postal code')
    .nullable(),
}

export const individualValidationSchema = Yup.object().shape({
  ...commonValidationSchema,
  ...individualPartValidationSchema,
})

export const corporateValidationSchema = Yup.object().shape({
  ...commonValidationSchema,
  ...corporatePartValidationSchema,
})

export const tradingProfileValidationSchema = Yup.object().shape({
  stocksKnowledge: Yup.number()
    .min(0, 'Range 1-10')
    .max(10, 'Range 1-10')
    .required('Required'),
  stocksExperience: Yup.number()
    .min(0, 'Range 1-10')
    .max(10, 'Range 1-10')
    .required('Required'),
  stocksFrequency: Yup.string().required('Required'),
  bondsKnowledge: Yup.number()
    .min(0, 'Range 1-10')
    .max(10, 'Range 1-10')
    .required('Required'),
  bondsExperience: Yup.number()
    .min(0, 'Range 1-10')
    .max(10, 'Range 1-10')
    .required('Required'),
  bondsFrequency: Yup.string().required('Required'),
  forexKnowledge: Yup.number()
    .min(0, 'Range 1-10')
    .max(10, 'Range 1-10')
    .required('Required'),
  forexExperience: Yup.number()
    .min(0, 'Range 1-10')
    .max(10, 'Range 1-10')
    .required('Required'),
  forexFrequency: Yup.string().required('Required'),
  derivativesKnowledge: Yup.number()
    .min(0, 'Range 1-10')
    .max(10, 'Range 1-10')
    .required('Required'),
  derivativesExperience: Yup.number()
    .min(0, 'Range 1-10')
    .max(10, 'Range 1-10')
    .required('Required'),
  derivativesFrequency: Yup.string().required('Required'),
  description: Yup.string().nullable(),
})

export const investmentObjectivesValidationSchema = Yup.object().shape({
  objectives: Yup.string().required('Please provide an objectives'),
  networthAmount: Yup.string().required('Net worth amount is required'),
  incomeAmount: Yup.string().required('Income amount is required'),
  investAmount: Yup.string().required('Invest amount is required'),
  sourceOfFunds: Yup.string().required('Source of funds is required'),
  sourceOfFundsDescription: Yup.string().nullable(),
  // tin: Yup.string().required('TIN is required'),
  // tinCountry: Yup.string().required('TIN country is required'),
})

export const billingInformationValidationSchema = Yup.object().shape({
  account: Yup.string().required('Please provide a bank account'),
  accountBeneficiary: Yup.string().required('Account beneficiary is required'),
  address: Yup.string().required('Please provide a bank address'),
  bank: Yup.string().required('Please provide a bank name'),
  relationshipTime: Yup.string().required('Please provide a relationship time'),
  swift: Yup.string().required('Please provide a SWIFT'),
  transfer: Yup.string().nullable(),
})

export const platformValidationSchema = Yup.object().shape({
  platform: Yup.string().required('Please provide a platform type'),
  accountNumber: Yup.string().required('Please provide a platform account'),
  openingDate: Yup.date('Enter Opening Date').required('Enter Opening Date'),
  closingDate: Yup.date('Enter correct Closing Date').nullable(),
})
