// @flow
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, CardHeader, CardFooter, CardBody } from 'reactstrap';

import { VERIFICATION_STATUS } from 'core/Models/Enums';
import { UploadButton } from 'core/Components/Form';

import { selectClient, selectUserToken } from '../../../../Selectors';
import Documents from './Documents';

import * as actions from '../actions';

type PropsType = {};

type StatusAlertProps = {
  status?: string,
  message?: string,
};

function StatusAlert(props: StatusAlertProps) {
  const { status, message } = props;

  if (status === VERIFICATION_STATUS.PENDING_APPROVAL) {
    return (
      <span>
        Thank you for the documents provided. Your information is being verified. It usually takes up to 3 business days.
      </span>
    );
  }
  if (status === VERIFICATION_STATUS.DECLINED) {
    return <div className="alert alert-danger">{message}</div>;
  }
  if (status === VERIFICATION_STATUS.VERIFIED) {
    return <div className="alert alert-success">{message}</div>;
  }
  return <></>;
}

export default function Step2(props: PropsType) {
  const dispatch = useDispatch();
  const deleteDocument = ({ id }) => dispatch(actions.deleteDocument({ id }));
  const loadDocument = (payload) => dispatch(actions.loadDocument(payload));

  const userToken = useSelector(selectUserToken);
  const client = useSelector(selectClient);

  const downloadClientDoc = (e) => {
    e.preventDefault();
    dispatch(actions.downloadClientDoc());
  };

  const dueDiligenceFile =
    client.type === 'INDIVIDUAL' ? 'Due_Diligance_Individuals.pdf' : 'Due_Diligance_Companies.pdf';
  const dueDiligenceUrl = `https://ie-static.ams3.cdn.digitaloceanspaces.com/v2/documents/${dueDiligenceFile}`;

  return (
    <>
      <CardHeader tag="h1">Documents</CardHeader>
      
      

      <CardBody>
        <p>
          Please see below the list of documents to upload. Please note, that you will have to send us the original
          signed document via DHL/traditional post-service after your account is open. </p>  
          <p>Before you upload these documents, please see our Due Dilligence requirements.</p>
        <a className="btn btn-simple btn-sm" target="_blank" rel="noopener noreferrer" href={dueDiligenceUrl}>
          <i className="fas fa-external-link-alt"></i> Due Diligence requirements
        </a>
       
      </CardBody>

      <CardHeader className="form-section" tag="h2">List of documents to upload</CardHeader>


      <ol className="wide-list">
        <li>
          1. The Account Opening Form. Please <u>download</u>, <u>print</u> and <u>sign</u> the form below<br/>
          <Button color="simple" size="sm" className="mt-3" onClick={downloadClientDoc}>
            <i className="fas fa-external-link-alt"/> Account Opening Form
          </Button>
        </li>
        {client.type === 'INDIVIDUAL' ? (
          <>
            <li>2. Certified ID (e.g. a passport, a driver’s license or a national identity card)</li>
            <li>3. Certified Utility bill (not older than 3 months) which shows the address that you used during the registration process
            </li>
            <li>4. Authorized Persons Form, if applicable{' '}
              <a target="_blank" rel="noopener noreferrer" href="https://ie-static.ams3.cdn.digitaloceanspaces.com/v2/documents/Authorised_Persons_Form_Individuals.pdf">
                Download Example
              </a>
            </li>
          </>
        ) : (
          <>
            <li>2. Corporate/Board Resolution to open an account with Investors Europe signed by all directors</li>
            <li>3. Certified copy of Register of Directors and Shareholders</li>
            <li>4. Certified copy of Memorandum and Articles of Association</li>
            <li>5. Certified copy of Register of Registered office</li>
            <li>6. Certified copy of Certificate of Incorporation</li>
            <li>7. Certified copy of Share Certificates</li>
            <li>8. Certified copy of Good Standing (for companies older than 1 year) </li>
            <li>
              9. Certificate of Incumbency if the public record of status is not available online (for companies older than
              1 year)
            </li>
            <li>10. A copy of the trust deed/agreement, if applicable</li>
            <li>
              11. Certified copy of Passport (or national identity card or a driver’s license) of each Beneficial Owner /
              Director / Authorized Person
            </li>
            <li>
              12. Certified Utility bill (not older than 3 months) for each Beneficial Owner / Director / Authorized Person
            </li>
          </>
        )}
      </ol>

      <CardHeader className="form-section" tag="h2">List of documents to read</CardHeader>


        <ol className="wide-list">
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://ie-static.ams3.cdn.digitaloceanspaces.com/v2/documents/General_Terms_and_Conditions.pdf"
            >
              1. General Terms and Conditions
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://ie-static.ams3.cdn.digitaloceanspaces.com/v2/documents/Best_Execution_Policy.pdf"
            >
              2. Best Execution Policy
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://ie-static.ams3.cdn.digitaloceanspaces.com/v2/documents/Conflict_of_Interest_Policy.pdf"
            >
              3. Conflict of Interest Policy
            </a>
          </li>
        </ol>

      <CardHeader className="form-section" tag="h2">Uploaded documents</CardHeader>
      
      
        {client.documents.size ? (
        <>
          <CardBody><StatusAlert status={client.documentsStatus} message={client.documentsStatusMessage} /></CardBody>
          <Documents documents={client.documents} onDelete={deleteDocument} /> 
        </>): <CardFooter className="text-muted">No documents uploaded</CardFooter>}
      <CardFooter>
        <UploadButton onDocumentLoad={loadDocument} userToken={userToken} apiPath="clients/documents" />
      </CardFooter>

      <CardFooter>
        
        <Button color="primary" tag={Link} to="/verification/steps/additional">
          <i className="fas fa-arrow-right pr-2"/>Next step
        </Button>
        <Button to="/support" tag={Link}>
          <i className="far fa-question-circle pr-2"/>Have Questions?
        </Button>
      </CardFooter>
    </>
  );
}
