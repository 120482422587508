import React, { useEffect } from 'react'
import { format } from 'date-fns'
import { CardHeader, CardBody, CardFooter, Button, Table, Alert } from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'
import { Route, Switch, Link } from 'react-router-dom'
import { STANDARD_DATE_TIME } from 'core/Utils/dateUtils'
import type { ClientType } from 'core/Models/User'
import { CLIENT_TYPE } from 'core/Models/Enums'

import DueDiligenceForm from './DueDiligenceForm'
import * as actions from './actions'
import { selectClient, selectUserToken } from '../../../Selectors'
import { selectDueDiligenceForm } from './selectors'

function DueDiligenceView(props) {
  const formId = +props.match.params.id
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(actions.getDueDiligenceForm({ id: formId }))
  }, [formId, dispatch])
  const form = useSelector(selectDueDiligenceForm)
  const client: ClientType = useSelector(selectClient)
  const userToken: string = useSelector(selectUserToken)
  const clientType = client.type === 'INDIVIDUAL' ? CLIENT_TYPE.INDIVIDUAL : CLIENT_TYPE.CORPORATE
  return (
    <>
      <CardHeader tag="h1">
        <Link to={`/due-diligence`}>Due Diligence</Link> > {client.type} Form DD{formId}
      </CardHeader>
      <DueDiligenceForm
        formId={formId}
        initialValues={{
          ...form,
          tins: form.tins ?? [],
          documents: form.documents ?? [],
          persons: form.persons ?? [],
        }}
        type={clientType}
        isEditable={Boolean(!form.createdAt)}
        userToken={userToken}
      />
    </>
  )
}

function DueDiligenceList() {
  const client: ClientType = useSelector(selectClient)
  const dispatch = useDispatch()

  const onCreate = () => {
    dispatch(actions.createDueDiligenceForm())
  }
  return (
    <>
      <CardHeader tag="h1">Due Diligence</CardHeader>

      <CardBody>
        <p>
          From our side being regulated by the FSC we have certain obligations in relation to KYC (”Know Your Customer”)
          procedures, thus in order to meet our external requirements we kindly ask you to update your information.
        </p>

        {client.type === 'INDIVIDUAL' ? (
          <Alert color="info">
            <p>
              <b>Please read this carefully before submitting this form</b>
            </p>
            <p>
              Please submit this form if you have received our request to update your information. Please fill in all
              the fields in Client Information and provide your TIN number.
            </p>
            <p>
              Please note that you <b>do not need to submit any documents</b> if your ID document or residency address
              has not changed.
            </p>
            <p>However, if you did change your ID document or residency, please submit:</p>
            <ul style={{ marginBottom: 0, fontSize: 14 }}>
              <li>if your ID document has changed: a certified copy of your passport or ID card;</li>
              <li>
                if your ID residency address has changed: a certified copy of your utility bill or a bank statement
                which is not older than 3 months.
              </li>
            </ul>
          </Alert>
        ) : (
          <Alert color="info">
            <p>
              <b>Please read this carefully before submitting this form</b>
            </p>
            <p>
              Please submit this form if you have received our request to update the information. Please fill in all the
              fields in Client Information and Information about Controlling Persons and provide a TIN number.
            </p>
            <p>
              Please note that you <b>do not need to submit any documents</b> if no information on your file has
              changed.
            </p>
            <p>
              However, if there were any changes in your company’s structure or any of the controlling persons changed
              their ID document or residency address, please submit:
            </p>
            <ul style={{ marginBottom: 0, fontSize: 14 }}>
              <li>if an ID document has changed: a certified copy of a passport or an ID card;</li>
              <li>
                if a residency address has changed: a certified copy of a utility bill or a bank statement which is not
                older than 3 months;
              </li>
              <li>
                if there is a change in Directors or Shareholders, please provide a certified copy of a relevant
                certificate or a certified copy of a Certificate of Incumbency to confirm the change;
              </li>
              <li>
                In case there are new directors or shareholders, please provide certified copiers of their ID and
                residency documents.
              </li>
            </ul>
          </Alert>
        )}

        <p>
          <b>Please see our certification requirements:</b>
        </p>

        <ul style={{ listStyle: 'none', padding: '0 10px' }}>
          {client.type === 'INDIVIDUAL' ? (
            <li className="mb-3">
              <a
                style={{ textDecoration: 'underline', color: '#40488a' }}
                href="https://ie-static.ams3.cdn.digitaloceanspaces.com/v2/documents/Due_Diligance_Individuals.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fas fa-link pr-2" />
                Due Diligence Requirements For Individuals
              </a>
            </li>
          ) : (
            <li>
              <a
                style={{ textDecoration: 'underline', color: '#40488a' }}
                href="https://ie-static.ams3.cdn.digitaloceanspaces.com/v2/documents/Due_Diligance_Companies.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fas fa-link pr-2" />
                Due Diligence Requirements For Corporates
              </a>
            </li>
          )}
        </ul>

        <Button className="mt-2" color="success" onClick={onCreate}>
          <i className="fas fa-plus pr-2" />
          Submit New Form
        </Button>
      </CardBody>

      <CardHeader tag="h2" className="form-section" style={{ border: 0 }}>
        <i className="far fa-file-alt pr-2"></i>
        Due Diligence Forms
      </CardHeader>
      {client.ddl.size > 0 ? (
        <Table responsive hover>
          <thead>
            <tr>
              <th>Form ID</th>
              <th>Submitted At</th>
              <th>Accepted At</th>
              <th className="text-right">Actions</th>
            </tr>
          </thead>
          <tbody>
            {client.ddl.map((item, idx) => {
              return (
                <tr key={`ddl_forms_${item.id}_${idx}`}>
                  <th scope="row">DD{item.id}</th>
                  <td>
                    {item.createdAt ? (
                      format(new Date(item.createdAt), STANDARD_DATE_TIME)
                    ) : (
                      <span className="text-muted">
                        <i className="far fa-clock pr-1"></i>Not Submitted
                      </span>
                    )}
                  </td>
                  <td>
                    {item.acceptedAt ? (
                      format(new Date(item.acceptedAt), STANDARD_DATE_TIME)
                    ) : (
                      <span className="text-muted">N/A</span>
                    )}
                  </td>
                  <td className="text-right">
                    <Link to={`/due-diligence/view/${item.id}`}>
                      <Button color="simple" size="sm" onClick={() => {}}>
                        <i className="fas fa-search pr-1"></i>View
                      </Button>
                    </Link>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      ) : (
        <CardFooter>No Forms Submitted</CardFooter>
      )}
    </>
  )
}

export default function DueDiligenceSection() {
  return (
    <Switch>
      <Route path="*/due-diligence/view/:id" component={DueDiligenceView} />
      <Route path="*/due-diligence" component={DueDiligenceList} />
    </Switch>
  )
}
