// @flow
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Card, CardHeader, CardBody, Col, Form, FormGroup, CardFooter, Alert } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import type { ContextRouter } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { alphanumWithSymbols } from 'core/Components/Form/normalizers';

import { FieldPasswordInput } from 'core/Components/Form';

import { AuthLayout } from '../../Components/Layout';

import * as actions from './actions';

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password too short'),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm password is required'),
});

type FormikEnhancerProps = {
  ...ContextRouter,
};

export default function FormikEnhancer(props: FormikEnhancerProps) {
  const { match } = props;

  const dispatch = useDispatch();
  const changePassword = ({ values, onSuccess, onError }) =>
    dispatch(actions.changePassword({ values, onSuccess, onError }));

  const [redirectToLogin, setRedirectToLogin] = useState(false);

  const token = match?.params?.token;

  useEffect(() => {
    if (token) {
      const onError = () => {
        setRedirectToLogin(true);
      };
      dispatch(actions.checkResetPasswordToken({ token, onError }));
    }
  }, [dispatch, token]);

  const onSubmit = ({ password }, { setSubmitting }) => {
    const data = {
      password,
      token,
    };
    const onSuccess = () => {
      setSubmitting(false);
      setRedirectToLogin(true);
    };

    const onError = () => {
      setSubmitting(false);
      setRedirectToLogin(true);
    };
    changePassword({ values: data, onSuccess, onError });
  };

  if (redirectToLogin) return <Redirect to="/login" />;

  return <Formik autoComplete="off" component={ResetPasswordForm} onSubmit={onSubmit} validationSchema={validationSchema} />;
}

type ResetPasswordFormProps = {
  isSubmitting: boolean,
  isValid: boolean,
  handleSubmit: () => void,
};

function ResetPasswordForm(props: ResetPasswordFormProps) {
  const { isSubmitting, isValid, handleSubmit } = props;

  return (
    <AuthLayout>
      <Col  xl={5} lg={6} md={8}>
        <Card className="card-auth">

          <CardHeader tag="h1">Change password</CardHeader>

          <CardBody>

            <Alert color="primary">Please set a new password for your account</Alert>

            <Form role="form" onSubmit={handleSubmit}>

              <FormGroup>
                <FieldPasswordInput
                  label="Type new password *"
                  type="password"
                  name="password"
                  disabled={isSubmitting}
                  autoComplete="new-password"
                  normalize={alphanumWithSymbols}
                />
              </FormGroup>

              <FormGroup>
                <FieldPasswordInput
                  label="Retype new password *"
                  type="password"
                  name="passwordConfirmation"
                  disabled={isSubmitting}
                  autoComplete="new-password"
                  normalize={alphanumWithSymbols}
                />
              </FormGroup>

              <Button className="auth_button" color="brand" type="submit" disabled={isSubmitting || !isValid}>
                CHANGE PASSWORD
              </Button>

            </Form>
          </CardBody>

          <CardFooter>
            <Link to="/login">Back to Login Page</Link>
          </CardFooter>

        </Card>
      </Col>
    </AuthLayout>
  );
}
