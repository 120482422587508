export const STANDARD_DATE = 'dd.MM.yyyy';
export const STANDARD_DATE_TIME = 'dd.MM.yyyy HH:mm';
export const STANDARD_DATE_TIME_SECONDS = 'dd.MM.yyyy HH:mm:ss';
export const STANDARD_TIME_DATE = 'HH:mm dd.MM.yyyy';
export const STANDARD_TIME = 'HH:mm';
export const FULL_TIME = 'HH:mm:ss';
export const SHORT_DATE_TIME = 'dd:MM HH:mm';
export const DATE_MONTH_YEAR = 'MMM yyyy';


export function transformDateToString(date) {
  if (typeof date === 'string') {
    return date;
  } else if (date instanceof Date) {
    return date.toISOString();
  }
  return date;
}
