import React from 'react'
import { ErrorMessage, Field } from 'formik'
import { FormFeedback, Input, Label } from 'reactstrap'
import DatePicker from 'react-datepicker'
import { format } from 'date-fns'

import styles from './styles.module.scss'

export default function(props) {
  return (
    <Field
      {...props}
      render={({ field, form: { touched, errors, handleChange } }) => {
        const pickerValue = typeof field.value === 'string' ? new Date(field.value) : field.value
        const { value, ...restFieldProps } = field

        const onChange = (value) => {
          if (!value) {
            handleChange(field.name)(undefined)
          }
          const v = format(new Date(value), 'yyyy-MM-dd')
          handleChange(field.name)(v)
        }

        return (
          <>
            {props.label ? (
              <Label className="muted_label" for={field.name}>
                {props.label}
              </Label>
            ) : null}
            <Input
              {...props}
              {...restFieldProps}
              tag={DatePicker}
              dateFormat="dd.MM.yyyy"
              onChange={onChange}
              selected={pickerValue}
              invalid={Boolean(touched[field.name] && errors[field.name])}
            />
            <ErrorMessage name={field.name}>
              {(msg) => <FormFeedback className={styles.errorMessage}>{msg}</FormFeedback>}
            </ErrorMessage>
          </>
        )
      }}
    />
  )
}
