import React from 'react';
import { Route } from 'react-router-dom';

import RoutesDescription from '../Models/RoutesDescription';
import { objectToArray } from './commonUtils';

export function routesArray(props) {
  const { routes = [], parentPath = '', additionalProps = {} } = props;
  let convertedRotes = routes;

  if (routes instanceof RoutesDescription) {
    convertedRotes = routes.toArray;
  } else if (typeof routes === 'object') {
    convertedRotes = objectToArray(routes);
  }

  return convertedRotes.map(({ path, component: Component}, key) => (
    <Route
      path={`${parentPath}${path}`}
      render={(props) => <Component {...props} {...additionalProps} parentPath={parentPath}/>}
      key={`arr_route_${path}_${key}`}
    />
  ));
}
