// @flow
import type { List as ListType, RecordOf } from 'immutable'
import { Record, List } from 'immutable'
import { User } from './User'
import { listOf } from '../Utils/immutableUtils'

export class ClientContact extends Record({
  type: undefined,
  value: undefined,
  isPrimary: undefined,
}) {}

export class ClientTin extends Record({
  type: undefined,
  tin: undefined,
  country: undefined,
}) {}

export class ClientDdl extends Record({
  id: undefined,
  fullName: undefined,
  address: undefined,
  description: undefined,
  operatingAddress: undefined,
  passportNumber: undefined,
  passportExpiryDate: undefined,
  passportCountry: undefined,
  persons: undefined,
  tins: undefined,
  documents: undefined,
  createdAt: undefined,
  acceptedAt: undefined,
}) {}

export class ClientDocument extends Record({
  id: undefined,
  name: undefined,
  filename: undefined,
  verifiedAt: undefined,
  createdAt: undefined,
  deleted: undefined,
  type: undefined,
}) {}

export class ClientPlatform extends Record({
  id: undefined,
  platform: undefined,
  accountNumber: undefined,
  openingDate: undefined,
  closingDate: undefined,
}) {}

export type ClientInvestmentObjectivesProps = {
  objectives?: string,
  networthAmount?: string,
  incomeAmount?: string,
  investAmount?: string,
  sourceOfFunds?: string,
  sourceOfFundsDescription?: string,
  tin?: string,
  tinCountry?: string,
}

export class ClientInvestmentObjectives extends Record<ClientInvestmentObjectivesProps>({
  objectives: undefined,
  networthAmount: undefined,
  incomeAmount: undefined,
  investAmount: undefined,
  sourceOfFunds: undefined,
  sourceOfFundsDescription: undefined,
  tin: undefined,
  tinCountry: undefined,
}) {}

export type ClientTradingProfileProps = {
  stocksKnowledge?: number,
  stocksExperience?: number,
  stocksFrequency?: number,
  bondsKnowledge?: number,
  bondsExperience?: number,
  bondsFrequency?: number,
  forexKnowledge?: number,
  forexExperience?: number,
  forexFrequency?: number,
  derivativesKnowledge?: number,
  derivativesExperience?: number,
  derivativesFrequency?: number,
  description?: string,
}

export class ClientTradingProfile extends Record<ClientTradingProfileProps>({
  stocksKnowledge: undefined,
  stocksExperience: undefined,
  stocksFrequency: undefined,
  bondsKnowledge: undefined,
  bondsExperience: undefined,
  bondsFrequency: undefined,
  forexKnowledge: undefined,
  forexExperience: undefined,
  forexFrequency: undefined,
  derivativesKnowledge: undefined,
  derivativesExperience: undefined,
  derivativesFrequency: undefined,
  description: undefined,
}) {}

export type ClientPropsType = {
  id?: number | null,
  fullName?: string,
  type?: string,
  ieId?: string,
  position?: string,
  title?: string,
  citizenship?: string,

  birthday?: string,
  birthdayCountry?: string,
  birthdayCity?: string,

  passportNumber?: string,
  passportExpiryDate?: string,
  recommendedBy?: string,

  employerName?: string,
  occupation?: string,

  companyName?: string,
  companyDescription?: string,
  companyIncorporationCountry?: string,
  companyIncorporationDate?: string,

  companyRegnumber?: string,
  companyPreviousName?: string,
  companyRegulatorName?: string,

  riskProfile?: string,

  tins: ListType<any>,
  contacts: ListType<any>,

  platforms: ListType<any>,

  status?: string,
  statusMessage?: string,

  customFields: ListType<any>,

  documentsStatus?: string,
  documentsStatusMessage?: string,
  documents: ListType<any>,

  bankDetailsStatus?: string,
  bankDetailsStatusMessage?: string,

  investmentObjectivesStatus?: string,
  investmentObjectivesStatusMessage?: string,
  investmentObjectives: RecordOf<ClientInvestmentObjectivesProps>,

  tradingProfileStatus?: string,
  tradingProfileStatusMessage?: string,
  tradingProfile: RecordOf<ClientTradingProfileProps>,

  // Address Details
  address?: string,
  addressCity?: string,
  addressState?: string,
  residence?: string,
  addressPostalCode?: string,

  // Company Address Details
  companyAddress?: string,
  companyAddressCity?: string,
  companyAddressState?: string,
  companyAddressCountry?: string,
  companyAddressPostalCode?: string,

  // Company TIN Details
  companyTinType?: string,
  companyTin?: string,
  companyTinCountry?: string,

  user?: RecordOf<UserPropsType>,
}

export type ClientType = RecordOf<ClientPropsType>

export class Client extends Record<ClientPropsType>(
  {
    id: undefined,
    fullName: undefined,
    type: undefined,
    ieId: undefined,
    position: undefined,
    title: undefined,
    citizenship: undefined,
    birthday: undefined,
    birthdayCity: undefined,
    birthdayCountry: undefined,
    passportNumber: undefined,
    passportExpiryDate: undefined,

    address: undefined,
    addressCity: undefined,
    addressState: undefined,
    residence: undefined,
    addressPostalCode: undefined,

    recommendedBy: undefined,

    employerName: undefined,
    occupation: undefined,

    companyName: undefined,
    companyDescription: undefined,
    companyIncorporationCountry: undefined,
    companyIncorporationDate: undefined,
    companyRegnumber: undefined,
    companyPreviousName: undefined,
    companyRegulatorName: undefined,

    companyAddress: undefined,
    companyAddressCity: undefined,
    companyAddressState: undefined,
    companyAddressCountry: undefined,
    companyAddressPostalCode: undefined,

    companyTinType: undefined,
    companyTin: undefined,
    companyTinCountry: undefined,

    riskProfile: undefined,

    contacts: List(),
    tins: List(),
    ddl: List(),

    platforms: List(),

    status: undefined,
    statusMessage: undefined,

    customFields: List(),

    documentsStatus: undefined,
    documentsStatusMessage: undefined,
    documents: List(),

    bankDetailsStatus: undefined,
    bankDetailsStatusMessage: undefined,

    investmentObjectivesStatus: undefined,
    investmentObjectivesStatusMessage: undefined,
    investmentObjectives: new ClientInvestmentObjectives({}),

    tradingProfileStatus: undefined,
    tradingProfileStatusMessage: undefined,
    tradingProfile: new ClientTradingProfile({}),

    user: new User({}),
  },
  'Client'
) {
  constructor(data: ClientPropsType = {}) {
    const {
      contacts = [],
      tins = [],
      ddl = [],
      platforms = [],
      documents = [],
      investmentObjectives = {},
      tradingProfile = {},
      user = {},
      ...client
    } = data || {}
    super({
      ...client,
      investmentObjectives: new ClientInvestmentObjectives(investmentObjectives),
      tradingProfile: new ClientTradingProfile(tradingProfile),
      contacts: listOf(ClientContact, contacts),
      tins: listOf(ClientTin, tins),
      ddl: listOf(ClientDdl, ddl),
      platforms: listOf(ClientPlatform, platforms),
      documents: listOf(ClientDocument, documents),
      user: new User(user),
    })
  }
}
