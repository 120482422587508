// @flow

import { Formik } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import { ModalHeader, ModalBody, ModalFooter, FormGroup, Form, Button } from 'reactstrap';

import { Modal } from 'core/Components/Modal';
import { amAccountlValidationSchema } from 'core/Validation/userValidation';
import { FieldInput, FieldPhoneInput, FieldSelect } from 'core/Components/Form';
import { alphanumWithSymbols } from 'core/Components/Form/normalizers';
// import type { UserType } from 'core/Models/User';
// import { selectUser } from '../../../Selectors';

import * as actions from './actions';

type AddReferralModalProps = {
  toggleModal: () => void,
  isOpen: boolean,
};

export default function AddReferralModal(props: AddReferralModalProps) {
  const { toggleModal, isOpen } = props;
  const dispatch = useDispatch();
  const submitForm = (values) => {
    dispatch(actions.addAccount(values));
  }

  const onSubmit = (values, { setSubmitting }) => {
    const { phoneNumber, ...restValues } = values;

    const transformedValues = {
      ...restValues,
      phoneCode: `+${phoneNumber.countryCode}`,
      phoneNumber: `${phoneNumber.phoneWithoutCode}`,
    };

    console.log(transformedValues);

    const onSuccess = () => {
      setSubmitting(false);
      toggleModal();
    };
    const onError = () => {
      setSubmitting(false);
    };
    submitForm({ values: transformedValues, onSuccess, onError });
  };

  // const user: UserType = useSelector(selectUser);

  return (
    <Modal isOpen={isOpen} toggle={toggleModal} width={400}>
      <ModalHeader className="blue-background" tag="h2" toggle={toggleModal}>
        New Account
      </ModalHeader>
      <Formik
        autoComplete="off"
        onSubmit={onSubmit}
        render={(props) => <AmAccountForm {...props} />}
        validationSchema={amAccountlValidationSchema}
      />
    </Modal>
  );
}

type Props = {
  handleSubmit: (values: any) => void,
  isValid: boolean,
  isSubmitting: boolean,
  status?: string,
  text?: string,
  unsubmitForm?: () => void,
  isVerification?: boolean,
};

function AmAccountForm(props: Props) {
  const { handleSubmit, isValid, isSubmitting } = props;
  const fieldsDisabled = Boolean(isSubmitting);

  return (
    <Form role="form" onSubmit={handleSubmit} autoComplete="off">
      <ModalBody>
      <FormGroup>
        <FieldSelect
          label="Account Type *"
          name="type"
          id="type"
          disabled={fieldsDisabled}
          options={[
            {value: 'INDIVIDUAL', label: 'Individual Account'},
            {value: 'CORPORATE', label: 'Corporate Account'},
          ]}
        />
        </FormGroup>
        <FormGroup>
          <FieldInput label="First Name *" placeholder="e.g. John" name="firstName" id="firstName" disabled={fieldsDisabled} normalize={alphanumWithSymbols} />
        </FormGroup>
        <FormGroup>
          <FieldInput label="Last Name *" placeholder="e.g. Doe" name="lastName" id="lastName" disabled={fieldsDisabled} normalize={alphanumWithSymbols} />
        </FormGroup>
        <FormGroup>
          <FieldInput label="Email *" placeholder="e.g. johndoe@gmail.com" name="email" id="email" disabled={fieldsDisabled} />
        </FormGroup>
        <FormGroup>
          <FieldPhoneInput label="Phone Number *" placeholder="e.g. +1234567890" name="phoneNumber" id="phoneNumber" disabled={fieldsDisabled} />
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        <Button type="submit" color="success" disabled={!isValid || fieldsDisabled}><i className="fas fa-plus pr-2"/>Create Account</Button>
      </ModalFooter>

    </Form>
  );
}
