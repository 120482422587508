import { CONTACT_TYPES, TIN_TYPES, PERSON_TITLES } from '../Models/Enums';
import { ClientInvestmentObjectives } from '../Models/Client';

export default function mapClientToProps(user, client, clientType) {
  return {
    id: client.id,
    type: clientType,

    title: client.title ?? PERSON_TITLES.MR,
    fullName: `${user.firstName} ${user.lastName}`,

    citizenship: client.citizenship,
    passportNumber: client.passportNumber,
    passportExpiryDate: client.passportExpiryDate ? new Date(client.passportExpiryDate) : undefined,
    birthday: client.birthday ? new Date(client.birthday) : undefined,
    birthdayCity: client.birthdayCity,
    birthdayCountry: client.birthdayCountry,

    address: client.address,
    addressCity: client.addressCity,
    addressState: client.addressState,
    residence: client.residence,
    addressPostalCode: client.addressPostalCode,

    recommendedBy: client.recommendedBy || undefined,

    occupation: client.occupation,
    employerName: client.employerName,

    position: client.position,
    companyName: client.companyName,
    companyIncorporationDate: client.companyIncorporationDate ? new Date(client.companyIncorporationDate) : undefined,
    companyIncorporationCountry: client.companyIncorporationCountry,
    companyRegnumber: client.companyRegnumber,
    companyDescription: client.companyDescription,
    companyRegulatorName: client.companyRegulatorName,

    companyAddress: client.companyAddress,
    companyAddressCity: client.companyAddressCity,
    companyAddressState: client.companyAddressState,
    companyAddressCountry: client.companyAddressCountry,
    companyAddressPostalCode: client.companyAddressPostalCode,

    companyTinType: client.companyTinType ? client.companyTinType : TIN_TYPES.TIN,
    companyTin: client.companyTin,
    companyTinCountry: client.companyTinCountry,

    tins: client.tins?.length > 0 ? client.tins : [],
    ddl: client.ddl?.length > 0 ? client.ddl : [],

    contacts:
      client.contacts?.length > 0
        ? client.contacts
        : [
            {
              type: CONTACT_TYPES.PHONE,
              value: `${user.phoneCode}${user.phoneNumber}`,
              isPrimary: true,
            },
          ],

    investmentObjectives: {
      objectives: client.investmentObjectives?.objectives,
      networthAmount: client.investmentObjectives?.networthAmount,
      incomeAmount: client.investmentObjectives?.incomeAmount,
      investAmount: client.investmentObjectives?.investAmount,
      sourceOfFunds: client.investmentObjectives?.sourceOfFunds,
      sourceOfFundsDescription: client.investmentObjectives?.sourceOfFundsDescription,
      tin: client.investmentObjectives?.tin,
      tinCountry: client.investmentObjectives?.tinCountry,
    },
    tradingProfile: {
      stocksKnowledge: client.tradingProfile?.stocksKnowledge,
      stocksExperience: client.tradingProfile?.stocksExperience,
      stocksFrequency: client.tradingProfile?.stocksFrequency,
      bondsKnowledge: client.tradingProfile?.bondsKnowledge,
      bondsExperience: client.tradingProfile?.bondsExperience,
      bondsFrequency: client.tradingProfile?.bondsFrequency,
      forexKnowledge: client.tradingProfile?.forexKnowledge,
      forexExperience: client.tradingProfile?.forexExperience,
      forexFrequency: client.tradingProfile?.forexFrequency,
      derivativesKnowledge: client.tradingProfile?.derivativesKnowledge,
      derivativesExperience: client.tradingProfile?.derivativesExperience,
      derivativesFrequency: client.tradingProfile?.derivativesFrequency,
      description: client.tradingProfile?.description,
    },
  };
}
