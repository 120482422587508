// @flow
import * as React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Card, Col, Container, Row } from 'reactstrap'

import { routesArray } from 'core/Utils/routesUtils'
import { USER_STATUS } from 'core/Models/Enums'
import RoutesDescription from 'core/Models/RoutesDescription'
import type { UserType } from 'core/Models/User'

import { selectUser, selectClient } from '../../Selectors'

import TopNavbar from '../../Components/Navbar'
import Sidebar from '../../Components/Sidebar'

import AccountManagerPage from './AccountManagerPage'
import BillingPage from './BillingPage'
import DueDiligencePage from './DueDiligencePage'
import ProfilePage from './ProfilePage'
import PartnerPage from './PartnerPage'
import SupportPage from './SupportPage'
import VerificationPage from './VerificationPage'
// import TradingAccountsPage from './TradingAccountsPage';

export const routes = new RoutesDescription({
  verification: {
    path: '/verification',
    name: 'Verification',
    icon: 'far fa-check-circle',
    component: VerificationPage,
    access: [USER_STATUS.NOT_VERIFIED, USER_STATUS.IN_VERIFICATION],
  },
  profile: {
    path: '/profile',
    name: 'Account',
    icon: 'fas fa-user-cog',
    component: ProfilePage,
    access: [USER_STATUS.VERIFIED, USER_STATUS.READY_TO_TRADE, USER_STATUS.ACTIVE_CLIENT],
  },

  // tradingAccounts: {
  //   path: '/accounts',
  //   name: 'Trading Accounts',
  //   icon: 'far fa-chart-bar',
  //   component: TradingAccountsPage,
  //   access: [USER_STATUS.VERIFIED],
  // },
  billing: {
    path: '/billing',
    name: 'Billing',
    icon: 'fas fa-money-check-alt',
    component: BillingPage,
    access: [USER_STATUS.VERIFIED, USER_STATUS.READY_TO_TRADE, USER_STATUS.ACTIVE_CLIENT],
  },
  dueDiligence: {
    path: '/due-diligence',
    name: 'Due Diligence',
    icon: 'fas fa-user-shield',
    component: DueDiligencePage,
    access: [USER_STATUS.VERIFIED, USER_STATUS.READY_TO_TRADE, USER_STATUS.ACTIVE_CLIENT],
  },
  accountManager: {
    path: '/manager/accounts',
    name: 'Account Manager',
    icon: 'fas fa-users-cog',
    component: AccountManagerPage,
    access: [
      USER_STATUS.NOT_VERIFIED,
      USER_STATUS.IN_VERIFICATION,
      USER_STATUS.VERIFIED,
      USER_STATUS.READY_TO_TRADE,
      USER_STATUS.ACTIVE_CLIENT,
    ],
  },
  partner: {
    path: '/partners',
    name: 'Partner Page',
    icon: 'fas fa-users',
    component: PartnerPage,
    access: [USER_STATUS.VERIFIED, USER_STATUS.READY_TO_TRADE, USER_STATUS.ACTIVE_CLIENT],
  },

  support: {
    path: '/support',
    name: 'Support Center',
    icon: 'fas fa-info-circle',
    component: SupportPage,
    access: [
      USER_STATUS.NOT_VERIFIED,
      USER_STATUS.IN_VERIFICATION,
      USER_STATUS.VERIFIED,
      USER_STATUS.READY_TO_TRADE,
      USER_STATUS.ACTIVE_CLIENT,
    ],
  },
})

export default function InnerPage() {
  const user: UserType = useSelector(selectUser)
  const client = useSelector(selectClient)

  return (
    <>
      <Sidebar
        routes={routes.toArray}
        userVerificationStatus={user.status}
        linkedUsers={user.linkedUsers}
        ieId={client.ieId}
      />
      <div className="main-content">
        <TopNavbar user={user} />
        <Container fluid>
          <Row>
            <Col>
              <Card className="shadow">
                <Switch>
                  {user.status === USER_STATUS.VERIFIED ||
                  user.status === USER_STATUS.READY_TO_TRADE ||
                  user.status === USER_STATUS.ACTIVE_CLIENT ? (
                    <Redirect from="/" exact to={routes.profile.path} />
                  ) : null}
                  {routesArray({ routes })}
                  <Route path="*">
                    <Redirect to={routes.profile.path} />
                  </Route>
                </Switch>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <footer className="footer">
                <Row className="align-items-center justify-content-center">
                  <Col xl="12" className="justify-content-center text-center mb-2">
                    <span className="copyright text-muted">
                      © 2024. Investors Europe (Mauritius) Limited is authorised and regulated by the FSC Mauritius,
                      license C112011088.
                    </span>
                  </Col>
                  <Col xl="12">
                    <ul className="nav-footer justify-content-center nav">
                      <li className="nav-item">
                        <a
                          href="https://ie-static.ams3.cdn.digitaloceanspaces.com/v2/documents/General_Terms_and_Conditions.pdf"
                          rel="noopener noreferrer"
                          target="_blank"
                          className="nav-link"
                        >
                          General Terms
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href="https://datagrid.b-cdn.net/IEML_CFD.pdf"
                          rel="noopener noreferrer"
                          target="_blank"
                          className="nav-link"
                        >
                          CFD trading terms & conditions
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href="https://ie-static.ams3.cdn.digitaloceanspaces.com/v2/documents/Best_Execution_Policy.pdf"
                          rel="noopener noreferrer"
                          target="_blank"
                          className="nav-link"
                        >
                          Best Execution
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href="https://ie-static.ams3.cdn.digitaloceanspaces.com/v2/documents/Conflict_of_Interest_Policy.pdf"
                          rel="noopener noreferrer"
                          target="_blank"
                          className="nav-link"
                        >
                          Conflicts of Interest
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href="https://ie-static.ams3.cdn.digitaloceanspaces.com/v2/documents/Due_Diligance_Individuals.pdf"
                          rel="noopener noreferrer"
                          target="_blank"
                          className="nav-link"
                        >
                          Due Diligence: Individual
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href="https://ie-static.ams3.cdn.digitaloceanspaces.com/v2/documents/Due_Diligance_Companies.pdf"
                          rel="noopener noreferrer"
                          target="_blank"
                          className="nav-link"
                        >
                          Due Diligence: Corporate
                        </a>
                      </li>

                  


                      
                    </ul>
                  </Col>
                </Row>
              </footer>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}
