import { takeLatest, call, put } from 'redux-saga/effects';
import noop from 'lodash/noop';

import * as verificationApi from 'core/Api/user/verificationApi';

import * as mainActions from '../../../Actions';
import * as actions from './actions';

export function* sendClientInformationSaga({ payload: { values, cb = noop, isUpdate = false } }) {
  try {
    const client = yield call(isUpdate ? verificationApi.updateClientInformation : verificationApi.sendClientInformation, values);
    yield put(actions.sendClientInformation.success(client));
    yield put(mainActions.getClient.success(client));
    yield put(mainActions.getUser());
    yield put(mainActions.updateUser({ clientId: client.id }));
    yield call(cb);
  } catch (e) {
    yield put(actions.sendClientInformation.error(e));
  }
}

export function* unsubmitClientInformationSaga({ payload: { cb = noop } = {} }) {
  try {
    yield call(verificationApi.unsubmitClientInformation);
    yield put(mainActions.getClient());
    yield put(mainActions.getUser());
    yield call(cb);
  } catch (e) {
    yield put(actions.unsubmitClientInformation.error(e));
  }
}

export function* loadDocumentSaga({ payload }) {
  try {
    const client = payload?.successful?.[0]?.response?.body;
    if (client) {
      yield put(mainActions.getClient.success(client));
    } else {
      yield put(mainActions.getClient());
    }
  } catch (e) {
    yield put(actions.loadDocument.error(e));
  }
}


export function* deleteDocumentSaga({ payload: { id } }) {
  try {
    yield call(verificationApi.deleteDocument, { id });
    yield put(mainActions.getClient());
  } catch (e) {
    yield put(actions.deleteDocument.error(e));
  }
}

export function* downloadClientDocSaga() {
  try {
    const response = yield call(verificationApi.downloadClientDoc);
    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Account_Opening_Form.pdf');
    document.body.appendChild(link);
    link.click();
  } catch (e) {
    yield put(actions.downloadClientDoc.error(e));
  }
}

export function* sendBillingInformationSaga({ payload: { values, cb = noop } }) {
  try {
    const user = yield call(verificationApi.sendBillingInformation, values);
    yield put(mainActions.getUser.success(user));
    yield put(mainActions.getClient());
    yield call(cb);
  } catch (e) {
    yield put(actions.sendBillingInformation.error(e));
  }
}

export function* unsubmitBillingInformationSaga({ payload: { cb = noop } = {} }) {
  try {
    yield call(verificationApi.unsumbitBillingInformation);
    yield put(mainActions.getClient());
    yield call(cb);
  } catch (e) {
    yield put(actions.unsubmitBillingInformation.error(e));
  }
}


export function* sendInvestmentsObjectivesSaga({ payload: { values, cb = noop } }) {
  try {
    yield call(verificationApi.sendInvestmentsObjectives, values);
    yield put(mainActions.getClient());
    yield call(cb);
  } catch (e) {
    yield put(actions.sendInvestmentsObjectives.error(e));
  }
}

export function* unsubmitInvestmentsObjectivesSaga({ payload: { cb = noop } = {} }) {
  try {
    yield call(verificationApi.unsumbitInvestmentsObjectives);
    yield put(mainActions.getClient());
    yield call(cb);
  } catch (e) {
    yield put(actions.unsubmitInvestmentsObjectives.error(e));
  }
}

export function* sendTradingProfileSaga({ payload: { values, cb = noop } }) {
  try {
    yield call(verificationApi.sendTradingProfile, values);
    yield put(mainActions.getClient());
    yield call(cb);
  } catch (e) {
    yield put(actions.sendInvestmentsObjectives.error(e));
  }
}

export function* unsubmitTradingProfileSaga({ payload: { cb = noop } = {} }) {
  try {
    yield call(verificationApi.unsumbitTradingProfile);
    yield put(mainActions.getClient());
    yield call(cb);
  } catch (e) {
    yield put(actions.unsubmitInvestmentsObjectives.error(e));
  }
}

export function* getClientSaga() {
  try {
    const client = yield call(verificationApi.getClient);
    yield put(mainActions.getClient.success(client));
  } catch (e) {
    yield put(mainActions.getClient.error(e));
  }
}

export default [
  takeLatest(actions.sendClientInformation, sendClientInformationSaga),
  takeLatest(actions.unsubmitClientInformation, unsubmitClientInformationSaga),
  takeLatest(actions.downloadClientDoc, downloadClientDocSaga),
  takeLatest(actions.deleteDocument, deleteDocumentSaga),
  takeLatest(actions.loadDocument, loadDocumentSaga),
  takeLatest(actions.sendBillingInformation, sendBillingInformationSaga),
  takeLatest(actions.unsubmitBillingInformation, unsubmitBillingInformationSaga),
  takeLatest(actions.sendInvestmentsObjectives, sendInvestmentsObjectivesSaga),
  takeLatest(actions.unsubmitInvestmentsObjectives, unsubmitInvestmentsObjectivesSaga),
  takeLatest(actions.sendTradingProfile, sendTradingProfileSaga),
  takeLatest(actions.unsubmitTradingProfile, unsubmitTradingProfileSaga),
  takeLatest(mainActions.getClient, getClientSaga),
];
