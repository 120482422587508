import noop from 'lodash/noop';
import { takeLatest, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import * as userApi from 'core/Api/user/userApi';

import * as actions from './actions';

export function* changeUserPasswordSaga({ payload: { values, onSuccess = noop, onError = noop  } = {} }) {
  try {
    yield call(userApi.changeUserPassword, { values });
    yield call(onSuccess);
    yield call(toast.success, 'Password Successfully Changed');
  } catch (e) {
    yield call(onError);
  }
}

export default [
  takeLatest(actions.changeUserPassword, changeUserPasswordSaga),
];
