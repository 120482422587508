import { toast } from 'react-toastify';
import { takeLatest, call, put, delay, take, race } from 'redux-saga/effects';
import noop from 'lodash/noop';

import * as authApi from 'core/Api/user/authApi';
import * as localStorageApi from 'core/Api/localStorageApi';
import * as verificationApi from 'core/Api/user/verificationApi';

import * as actions from './actions';
import * as mainActions from '../../Actions';

function* authorizationSaga() {
  try {
    const token = yield call(localStorageApi.getToken);
    if (token) {
      const user = yield call(authApi.getUser);
      yield call(localStorageApi.setUserData, user);
      if (user.clientId) {
        const client = yield call(verificationApi.getClient);
        yield put(mainActions.getClient.success(client));
      }
      yield put(mainActions.userLoggedIn({ user, token }));
    }
  } catch (e) {
    yield put(mainActions.userLoggedOut());
  }
}

function* pingUserSaga() {
  try {
    while (true) {
      const { logout, update } = yield race({
        logout: take(mainActions.userLoggedOut),
        update: delay(2 * 15000),
      });
      if (update) {
        yield call(authApi.getUser);
      }
      if (logout) {
        break;
      }
    }
  } catch (e) {
    yield put(mainActions.userLoggedOut());
  }
}

function* logoutUserSaga() {
  yield call(localStorageApi.removeUserData);
  yield call(localStorageApi.removeToken);
}

export function* loginUserSaga({ payload: { values, onSuccess = noop, onError = noop } }) {
  try {
    const user = yield call(authApi.loginUser, values);
    const token = user?.token;
    if (token) {
      yield call(localStorageApi.setUserData, user);
      yield call(localStorageApi.setToken, token);
      if (user.clientId) {
        const client = yield call(verificationApi.getClient);
        yield put(mainActions.getClient.success(client));
      }
      yield put(mainActions.userLoggedIn({ user, token }));
      yield call(onSuccess);
    } else {
      throw new Error('No token received');
    }
  } catch (e) {
    if (e.status === 406) {
      const token = e?.data?.error?.message;
      window.location.href = `/change_password/${token}`;
    }
    yield put(mainActions.userLoggedOut());
    yield call(onError);
  }
}


export function* sendResetPasswordLinkSaga({ payload: { values, onSuccess = noop, onError = noop } }) {
  try {
    yield call(authApi.sendResetPasswordLink, values);
    yield call(toast.success, 'Successful! Please check your email');
    yield call(onSuccess);
  } catch (e) {
    yield call(onError);
  }
}

export function* checkResetPasswordTokenSaga({ payload: { token, onSuccess = noop, onError = noop } }) {
  try {
    yield call(authApi.checkResetPasswordToken, { token });
    yield call(onSuccess);
  } catch (e) {
    yield call(onError);
  }
}

export function* changePasswordSaga({ payload: { values, onSuccess = noop, onError = noop } }) {
  try {
    yield call(authApi.changePassword, values);
    yield call(toast.success, 'Successful! Now you can login');
    yield call(onSuccess);
  } catch (e) {
    yield call(onError);
  }
}

export function* getUserSaga() {
  try {
    const user = yield call(authApi.getUser);
    yield put(mainActions.getUser.success(user))
  } catch (e) {
    yield put(mainActions.userLoggedOut());
  }
}

export default [
  authorizationSaga(),
  takeLatest(actions.loginUser, loginUserSaga),
  takeLatest(actions.sendResetPasswordLink, sendResetPasswordLinkSaga),
  takeLatest(actions.checkResetPasswordToken, checkResetPasswordTokenSaga),
  takeLatest(actions.changePassword, changePasswordSaga),
  takeLatest(mainActions.userLoggedIn, pingUserSaga),
  takeLatest(mainActions.userLoggedOut, logoutUserSaga),
  takeLatest(mainActions.getUser, getUserSaga),
];
