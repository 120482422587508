// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import type { ContextRouter } from 'react-router-dom';

import { routesArray } from 'core/Utils/routesUtils';
import type { UserType } from 'core/Models/User';
import RoutesDescription from 'core/Models/RoutesDescription';
import { USER_STATUS } from 'core/Models/Enums';

import { selectUser } from '../../../Selectors';

import Steps from './Steps';
import Greeting from './Greeting';

const routes = new RoutesDescription({
  greeting: {
    path: '/greeting',
    name: 'Greeting',
    component: Greeting,
  },
  steps: {
    path: '/steps',
    name: 'Steps',
    component: Steps,
  },
});

type VerificationPageProps = ContextRouter & {};

export default function VerificationPage(props: VerificationPageProps) {
  const {
    match: { path },
  } = props;

  const user: UserType = useSelector(selectUser);

  return (
    <Switch>
      {user.status === USER_STATUS.VERIFIED ? <Redirect to="/" /> : null}
      <Route exact path={path}>
        {!user.clientId ? (
          <Redirect to={`${path}${routes.greeting.path}`} />
        ) : (
          <Redirect to={`${path}${routes.steps.path}`} />
        )}
      </Route>
      {routesArray({ routes, parentPath: path })}
    </Switch>
  );
}
