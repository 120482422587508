import { createAction, createApiAction } from 'core/Utils/reduxUtils';

export const changeLocation = createAction('MAIN/CHANGE_LOCATION');

export const userLoggedIn = createAction('MAIN/USER_LOGGED_IN');
export const userLoggedOut = createAction('MAIN/USER_LOGGED_OUT');

export const getUser = createApiAction('MAIN/GET_USER');
export const updateUser = createAction('MAIN/UPDATE_USER');

export const getClient = createApiAction('MAIN/GET_CLIENT');
