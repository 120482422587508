import React from 'react';
import { Field, ErrorMessage } from 'formik';
import { FormFeedback, Input, Label } from 'reactstrap';

import styles from './styles.module.scss';

export default function(props) {
  return (
    <Field
      {...props}
      render={({ field, form: { touched, errors } }) => {
        return (
          <>
            {props.label ? <Label for={field.name}>{props.label}</Label> : null}
            <Input
              {...props}
              {...field}
              type="checkbox"
              checked={field.value ?? false}
              invalid={Boolean(touched[field.name] && errors[field.name])}
            />
            <ErrorMessage name={field.name}>
              {(msg) => <FormFeedback className={styles.errorMessage}>{msg}</FormFeedback>}
            </ErrorMessage>
          </>
        );
      }}
    />
  );
}
