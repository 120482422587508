// @flow
import { Formik } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import { ModalHeader, ModalBody } from 'reactstrap';

import { Modal } from 'core/Components/Modal';
import { billingInformationValidationSchema } from 'core/Validation/clientValidation';

import BankAccountForm from '../../../Components/BankAccountForm';

import * as actions from './actions.js';

type CreateBankAccountModalProps = {
  toggleModal: () => void,
  isOpen: boolean,
};

export default function CreateBankAccountModal(props: CreateBankAccountModalProps) {
  const { toggleModal, isOpen } = props;
  const dispatch = useDispatch();
  const submitForm = (values) => dispatch(actions.createUserBankAccount(values));

  const onSubmit = (values, { setSubmitting }) => {
    const onSuccess = () => {
      setSubmitting(false);
      toggleModal();
    };
    const onError = () => {
      setSubmitting(false);
    };
    submitForm({ values, onSuccess, onError });
  };

  return (
    <Modal isOpen={isOpen} toggle={toggleModal}>
      <ModalHeader className="blue-background" tag="h2" toggle={toggleModal}>
        New bank account
      </ModalHeader>
      <ModalBody>
        <Formik
          autoComplete="off"
          onSubmit={onSubmit}
          render={(props) => <BankAccountForm {...props} />}
          validationSchema={billingInformationValidationSchema}
        />
      </ModalBody>
    </Modal>
  );
}
