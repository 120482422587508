// @flow
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import type { ContextRouter } from 'react-router-dom';
import { CardBody, Col, Row, Card } from 'reactstrap';

import { AuthLayout } from '../../Components/Layout';

import * as actions from './actions';

type Props = ContextRouter & {};

export default function EmailVerificationPage(props: Props) {
  const dispatch = useDispatch();
  const [redirectToLogin, setRedirectToLogin] = useState(false);

  const { match } = props;
  const token = match?.params?.token;

  useEffect(() => {
    if (token) {
      const onSuccess = () => {
        setRedirectToLogin(true);
      };
      dispatch(actions.verifyEmail({ token, onSuccess }));
    }
  }, [dispatch, token]);

  if (redirectToLogin) return <Redirect to="/login" />;

  return (
    <AuthLayout>
      <Col lg="8" md="6">
        <Card className="shadow">
          <CardBody>
            <h1 className="mb-3">A BIG WELCOME TO INVESTORS EUROPE!</h1>
            <p>To get access to Trader Room please proceed with email verification.</p>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            <Link to="/login" className="text-light">
              <small>Login</small>
            </Link>
          </Col>
        </Row>
      </Col>
    </AuthLayout>
  );
}
