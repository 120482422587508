import React from 'react';
import { Field, ErrorMessage } from 'formik';
import { FormFeedback, Input, Label, InputGroupAddon, InputGroup } from 'reactstrap';
import PhoneNumber from 'awesome-phonenumber';
import * as Yup from 'yup';

import { getRegion } from '../../Utils/phoneUtils';

import styles from './styles.module.scss';

Yup.addMethod(Yup.string, 'mobilePhone', function(message) {
  return this.test('test-name', message, function(value) {
    if (value && !PhoneNumber(value).isPossible()) {
      return false;
    }
    return true;
  });
});

const normalizePhone = (handleChange) => (event) => {
  const value = event.target.value;
  const cleanValue = value.replace(/[\D\s+-]/g, '');
  const regionCode = getRegion(cleanValue);

  if (regionCode === 'ZZ') {
    return handleChange(event.target.name)({
      formattedPhone: cleanValue,
      phone: '',
      phoneWithoutCode: '',
      countryCode: '',
      regionCode,
    });
  }
  const ayt = PhoneNumber.getAsYouType(regionCode);
  const countryCode = PhoneNumber.getCountryCodeForRegionCode(regionCode);
  const phoneWithoutCode = cleanValue.replace(countryCode, '');

  const prefix = `+${countryCode}`;

  const result = Array.from(phoneWithoutCode).reduce((acc, ch) => ayt.addChar(ch), '');

  if (!result) {
    return handleChange(event.target.name)({
      formattedPhone: prefix,
      phone: '',
      phoneWithoutCode: '',
      countryCode,
      regionCode,
    });
  }

  return handleChange(event.target.name)({
    formattedPhone: `${prefix} ${result}`,
    phone: ayt.getPhoneNumber().getNumber(),
    phoneWithoutCode: ayt.getPhoneNumber().getNumber('significant'),
    countryCode,
    regionCode,
  });
};

function renderPrefix(regionCode = 'ZZ') {
  if (regionCode === 'ZZ') {
    return null;
  }
  return (
    <InputGroupAddon addonType="prepend">
      <span className="input-group-text">
        <span className={`flag flag-exist flag-${regionCode.toLowerCase()}`} />
      </span>
    </InputGroupAddon>
  );
}

export default function(props) {
  return (
    <Field
      {...props}
      render={({ field, form: { touched, errors, handleChange } }) => {
        return (
          <>
            {props.label ? <Label className="muted_label" for={field.name}>{props.label}</Label> : null}
            <InputGroup>
              {renderPrefix(field.value?.regionCode)}
              <Input
                {...props}
                {...field}
                onChange={normalizePhone(handleChange)}
                value={field.value?.formattedPhone ?? '+'}
                invalid={Boolean(touched[field.name] && errors[field.name])}
              />
            </InputGroup>
            <ErrorMessage name={field.name}>
              {(msg) => (
                <FormFeedback className={styles.errorMessage}>{msg?.phone ?? 'Error'}</FormFeedback>
              )}
            </ErrorMessage>
          </>
        );
      }}
    />
  );
}
