
import * as Yup from 'yup';

import PhoneNumber from 'awesome-phonenumber';
import { getRegion } from '../Utils/phoneUtils';

Yup.addMethod(Yup.string, 'mobilePhone', function(message) {
  return this.test('test-name', message, function(value) {
    if (value && !PhoneNumber(value).isPossible()) {
      return false;
    }
    return true;
  });
});

export const userCommonValidationSchema = {
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Email is required'),
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password too short'),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm password is required'),
};

export const managerValidationSchema = Yup.object().shape({
  ...userCommonValidationSchema,
});

export const changePasswordValidationSchema = Yup.object().shape({
  currentPassword: Yup.string()
    .required('Password is required')
    .min(8, 'Password must be 8 characters or longer'),
  newPassword: Yup.string()
    .required('Password is required')
    .min(8, 'Password must be 8 characters or longer'),
  retypeNewPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    .required('Confirm new password'),
});

export const changeAdminPasswordValidationSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required('Password is required')
    .min(8, 'Password must be 8 characters or longer'),
  retypeNewPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    .required('Confirm new password'),
});

export const addCustomFieldValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Field name is required')
    .min(2, 'Field name must be 2 characters or longer'),
  value: Yup.string()
    .required('Field value is required')
    .min(2, 'Field value must be 2 characters or longer'),
});


export const userAdminValidationSchema = Yup.object().shape({
  ...userCommonValidationSchema,
  phoneNumber: Yup.object().shape({
    phone: Yup.string()
      .mobilePhone('Invalid phone number')
      .required('Phone number is required'),
  }),
});

export const userClientValidationSchema = Yup.object().shape({
  ...userCommonValidationSchema,
  phoneNumber: Yup.object().shape({
    phone: Yup.string()
      .mobilePhone('Invalid Phone Number')
      .required('Phone number is required'),
  }),
  code: Yup.string()
    .required('SMS code is required')
    .min(6),
});

export const referralValidationSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Email is required'),
  phoneNumber: Yup.object().shape({
    phone: Yup.string()
      .mobilePhone('Invalid phone number')
      .required('Phone number is required'),
  }),
});

export const amAccountlValidationSchema = Yup.object().shape({
  type: Yup.string().required('Type is required'),
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  email: Yup.string()
    .email('Invalid Email')
    .required('Email is required'),
  phoneNumber: Yup.object().shape({
    phone: Yup.string()
      .mobilePhone('Invalid Phone Number')
      .required('Phone Number is required'),
  }),
});