import { call } from 'redux-saga/effects';

import apiSaga, { apiRequest } from '../index';

const UNSUBMIT_STEPS_MAP = {
  clientInfoStatus: 1,
  documentsStatus: 2,
  bankDetailsStatus: 3,
  investmentObjectivesStatus: 4,
  tradingProfileStatus: 5,
};

export function* sendClientInformation(data) {
  return yield call(apiSaga, apiRequest.post, 'clients', data);
}

export function* updateClientInformation(data) {
  return yield call(apiSaga, apiRequest.patch, 'clients', data);
}

export function* unsubmitClientInformation() {
  return yield call(apiSaga, apiRequest.get, `clients/unsubmit/${UNSUBMIT_STEPS_MAP.clientInfoStatus}`);
}

export function* deleteDocument({ id }) {
  return yield call(apiSaga, apiRequest.delete, `clients/documents/${id}`);
}

export function* downloadClientDoc() {
  return yield call(apiSaga, { method: apiRequest.get, config: { responseType: 'blob' } }, 'clients/download');
}

export function* sendBillingInformation(data) {
  return yield call(apiSaga, apiRequest.put, 'clients/billing-info', data);
}

export function* unsumbitBillingInformation() {
  return yield call(apiSaga, apiRequest.get, `clients/unsubmit/${UNSUBMIT_STEPS_MAP.bankDetailsStatus}`);
}

export function* sendInvestmentsObjectives(data) {
  return yield call(apiSaga, apiRequest.put, 'clients/investment-objectives', data);
}

export function* unsumbitInvestmentsObjectives() {
  return yield call(apiSaga, apiRequest.get, `clients/unsubmit/${UNSUBMIT_STEPS_MAP.investmentObjectivesStatus}`);
}


export function* sendTradingProfile(data) {
  return yield call(apiSaga, apiRequest.put, 'clients/trading-profile', data);
}

export function* unsumbitTradingProfile() {
  return yield call(apiSaga, apiRequest.get, `clients/unsubmit/${UNSUBMIT_STEPS_MAP.tradingProfileStatus}`);
}


export function* getClient() {
  return yield call(apiSaga, apiRequest.get, 'clients/me');
}
