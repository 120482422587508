import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { USER_STATUS } from 'core/Models/Enums';

import { selectUser, selectUserToken } from '../Selectors';

import { routes as privateRoutes } from '../Pages/InnerPage';

const ALLOWED_NOT_VERIFIED_PRIVATE_ROUTES = [privateRoutes.verification.path, privateRoutes.support.path];

export function PrivateRoute(props) {
  const { component: Component, ...rest } = props;
  const user = useSelector(selectUser);
  const userToken = useSelector(selectUserToken);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (userToken) {
          if (
            (user.status === USER_STATUS.NOT_VERIFIED || user.status === USER_STATUS.IN_VERIFICATION) &&
            !ALLOWED_NOT_VERIFIED_PRIVATE_ROUTES.some((route) => props.location.pathname.startsWith(route))
          ) {
            return <Redirect to={privateRoutes.verification.path} />;
          }
          return <Component {...props} />;
        }
        return (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
}
