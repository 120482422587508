// @flow
import * as React from 'react';
import { useSelector } from 'react-redux';
import { NavLink, Redirect, Route, Switch } from 'react-router-dom';
import { ButtonGroup, InputGroup, CardHeader, CardBody, Alert } from 'reactstrap';
import { Button } from 'reactstrap';
import type { ContextRouter } from 'react-router-dom';

import RoutesDescription from 'core/Models/RoutesDescription';
import { CLIENT_TYPE, VERIFICATION_STATUS } from 'core/Models/Enums';
import type { ClientType } from 'core/Models/Client';

import { selectClient } from '../../../../Selectors';

import ClientForm from './ClientForm';

const routes = new RoutesDescription({
  individual: {
    path: `/${CLIENT_TYPE.getById(CLIENT_TYPE.INDIVIDUAL).path}`,
    name: CLIENT_TYPE.getById(CLIENT_TYPE.INDIVIDUAL).label,
  },
  corporate: {
    path: `/${CLIENT_TYPE.getById(CLIENT_TYPE.CORPORATE).path}`,
    name: CLIENT_TYPE.getById(CLIENT_TYPE.CORPORATE).label,
  },
});

type StatusAlertProps = {
  status?: string,
  message?: string,
};

function StatusAlert(props: StatusAlertProps) {
  const { status, message } = props;
  if (status === VERIFICATION_STATUS.DECLINED) {
    return <CardHeader><Alert color="warning" className="mb-0">{message}</Alert></CardHeader>;
  }
  if (status === VERIFICATION_STATUS.VERIFIED) {
    return <CardHeader><Alert color="success" className="mb-0">{message}</Alert></CardHeader>;
  }
  return <></>;
}

type Props = {
  ...ContextRouter,
};

export default function Step1(props: Props) {
  const {
    match: { path: parentPath },
  } = props;

  const client: ClientType = useSelector(selectClient);

  return (
    <>
      
      <StatusAlert status={client.status} message={client.statusMessage} />
   
      <CardHeader tag="h1">Client Profile</CardHeader>

      <CardHeader tag="h2" className="form-section">1. Choose your account type</CardHeader>

      <CardBody>
        <InputGroup className="pb-2">
          <ButtonGroup className="type-selector">
            {routes.toArray.map(({ path, name }, idx) => (
              <Button
                tag={NavLink}
                color="info"
                activeClassName="type-selector-active"
                to={`${parentPath}${path}`}
                key={`step1_route_${path}_${idx}`}
                disabled={Boolean(client.status && client.status !== VERIFICATION_STATUS.CLIENT_AWAITING)}
              >
                {name}
              </Button>
            ))}
          </ButtonGroup>
        </InputGroup>
      </CardBody>

      <Switch>
        <Route exact path={parentPath}>
          {!client.type || client.type === CLIENT_TYPE.INDIVIDUAL ? (
            <Redirect to={`${parentPath}${routes.individual.path}`} />
          ) : (
            <Redirect to={`${parentPath}${routes.corporate.path}`} />
          )}
        </Route>
        <Route
          path={`${parentPath}/:clientType`}
          render={(props) => <ClientForm {...props} parentPath={parentPath} />}
        />
      </Switch>
    </>
  );
}
