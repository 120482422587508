import noop from 'lodash/noop'
import { takeLatest, call, put } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import * as mainActions from '../../../Actions'
import * as userApi from 'core/Api/user/userApi'

import * as actions from './actions'

export function* getAccountsSaga({ payload: { onSuccess = noop, onError = noop } = {} }) {
  try {
    const result = yield call(userApi.getAmAccounts)
    yield put(actions.getAccounts.success(result))
    yield call(onSuccess)
  } catch (e) {
    yield put(actions.getAccounts.error(e))
    yield call(onError)
  }
}

export function* addAccountSaga({ payload: { values, onSuccess = noop, onError = noop } = {} }) {
  try {
    const result = yield call(userApi.addAmAccount, { values })
    yield put(actions.getAccounts.success(result))
    yield call(onSuccess)
    yield call(toast.success, 'Account Сreated')
    yield put(mainActions.updateUser({ partnerText: values.partnerText }))
  } catch (e) {
    yield call(onError)
  }
}

export function* getClientSaga({ payload: { id, onSuccess = noop, onError = noop } = {} }) {
  try {
    const result = yield call(userApi.getAmClient, { id })
    yield put(actions.getClient.success(result))
    yield call(onSuccess)
  } catch (e) {
    yield put(actions.getClient.error(e))
    yield call(onError)
  }
}

export function* updateClientSaga({ payload: { id, values, onSuccess = noop, onError = noop } = {} }) {
  try {
    const result = yield call(userApi.updateAmClient, { id, values: values.values })
    console.log(result)
    yield put(actions.updateClient.success(result))
    yield call(values.onSuccess)
    yield call(toast.success, 'Changes Saved')
  } catch (e) {
    yield put(actions.updateClient.error(e))
    yield call(toast.success, 'Validation Error')
    yield call(values.onError)
  }
}

export function* uploadDocumentSaga({ payload }) {
  try {
    const client = payload?.successful?.[0]?.response?.body
    if (client) {
      yield put(actions.getClient.success(client))
    }
  } catch (e) {
    yield put(actions.uploadDocument.error(e))
  }
}

export function* deleteDocumentSaga({ payload: { id } }) {
  try {
    const result = yield call(userApi.deleteAmDocument, { id })
    yield put(actions.getClient.success(result))
  } catch (e) {
    yield put(actions.deleteDocument.error(e))
  }
}

export function* downloadApplicationSaga({ payload: { id } }) {
  try {
    const response = yield call(userApi.downloadAmClientDoc, { id })
    const url = window.URL.createObjectURL(new Blob([response]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'Account_Opening_Form.pdf')
    document.body.appendChild(link)
    link.click()
  } catch (e) {
    yield put(actions.downloadApplication.error(e))
  }
}

export function* submitClientSaga({ payload: { id } }) {
  try {
    const result = yield call(userApi.submitAmClient, { id })
    yield put(actions.getClient.success(result))
    yield call(toast.success, 'Verification Form has been submitted')
  } catch (e) {
    yield put(actions.submitClient.error(e))
  }
}

export function* unsubmitClientSaga({ payload: { id } }) {
  try {
    const result = yield call(userApi.unsubmitAmClient, { id })
    yield put(actions.getClient.success(result))
    yield call(toast.success, 'Verification Form has been unsubmitted')
  } catch (e) {
    yield put(actions.unsubmitClient.error(e))
  }
}

export default [
  takeLatest(actions.getAccounts, getAccountsSaga),
  takeLatest(actions.addAccount, addAccountSaga),
  takeLatest(actions.getClient, getClientSaga),
  takeLatest(actions.updateClient, updateClientSaga),
  takeLatest(actions.uploadDocument, uploadDocumentSaga),
  takeLatest(actions.deleteDocument, deleteDocumentSaga),
  takeLatest(actions.downloadApplication, downloadApplicationSaga),
  takeLatest(actions.submitClient, submitClientSaga),
  takeLatest(actions.unsubmitClient, unsubmitClientSaga),
]
