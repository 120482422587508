// @flow
import React from 'react';
import { FormGroup, CardHeader, CardBody, Row, Col, InputGroup, InputGroupAddon, Label } from 'reactstrap';
import { TIN_TYPES } from 'core/Models/Enums';
import { FieldInput, FieldSelect, FieldDatePicker, FieldCountrySelect } from 'core/Components/Form';
import { onlyLatinLetters, alphanumWithSymbols } from 'core/Components/Form/normalizers';

import { subYears } from 'date-fns';

const maxCompanyIncorporationDate = new Date();
const minCompanyIncorporationDate = subYears(new Date(), 150);

type Props = {
  fieldsDisabled: boolean,
};

export default function CorporateTypeBlock(props: Props) {
  const { fieldsDisabled } = props;

  return (
    <>
      <CardHeader tag="h2" className="form-section">
        4. Please provide your company information
      </CardHeader>
      <CardBody>
        <Row>
          <Col xl="4" md="6">
            <FormGroup>
              <label className="muted_label" htmlFor="companyName">
                Full Legal Name *
              </label>
              <FieldInput
                name="companyName"
                id="companyName"
                disabled={fieldsDisabled}
                normalize={alphanumWithSymbols}
              />
            </FormGroup>
          </Col>
          <Col xl="4" md="6">
            <FormGroup>
              <label className="muted_label" htmlFor="companyIncorporationDate">
                Date of Incorporation *
              </label>
              <FieldDatePicker
                name="companyIncorporationDate"
                id="companyIncorporationDate"
                disabled={fieldsDisabled}
                maxDate={maxCompanyIncorporationDate}
                minDate={minCompanyIncorporationDate}
                showYearDropdown
                scrollableYearDropdown
                showMonthDropdown
                yearDropdownItemNumber={15}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xl="4" md="6">
            <FormGroup>
              <FieldCountrySelect
                label="Country of Incorporation *"
                name="companyIncorporationCountry"
                id="companyIncorporationCountry"
                disabled={fieldsDisabled}
              />
            </FormGroup>
          </Col>
          <Col xl="4" md="6">
            <FormGroup>
              <FieldInput
                label="Registration Number *"
                name="companyRegnumber"
                id="companyRegnumber"
                disabled={fieldsDisabled}
                normalize={alphanumWithSymbols}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xl="4" md="6">
            <FormGroup>
              <FieldInput
                label="Description of Business *"
                name="companyDescription"
                id="companyDescription"
                disabled={fieldsDisabled}
                normalize={onlyLatinLetters}
              />
            </FormGroup>
          </Col>
          <Col xl="4" md="6" sm="12">
            <FormGroup>
              <FieldInput
                label="Regulator Name, if company is regulated"
                name="companyRegulatorName"
                id="companyRegulatorName"
                disabled={fieldsDisabled}
                normalize={alphanumWithSymbols}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xl="8" md="12">
            <FormGroup>
              <FieldInput
                label="Company Registered Address (street, building, office) *"
                name="companyAddress"
                id="companyAddress"
                disabled={fieldsDisabled}
                normalize={alphanumWithSymbols}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xl="4" md="6">
            <FormGroup>
              <FieldInput
                label="City *"
                name="companyAddressCity"
                id="companyAddressCity"
                disabled={fieldsDisabled}
                normalize={alphanumWithSymbols}
              />
            </FormGroup>
          </Col>
          <Col xl="4" md="6">
            <FormGroup>
              <FieldInput
                label="State"
                name="companyAddressState"
                id="companyAddressState"
                disabled={fieldsDisabled}
                normalize={alphanumWithSymbols}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xl="4" md="6">
            <FormGroup className="mb-0">
              <FieldCountrySelect
                label="Country *"
                name="companyAddressCountry"
                id="companyAddressCountry"
                disabled={fieldsDisabled}
              />
            </FormGroup>
          </Col>
          <Col xl="4" md="6">
            <FormGroup className="mb-0">
              <FieldInput
                label="Postal Code *"
                name="companyAddressPostalCode"
                id="companyAddressPostalCode"
                disabled={fieldsDisabled}
                normalize={alphanumWithSymbols}
              />
            </FormGroup>
          </Col>
        </Row>
        <Label className="muted_label mb-3 mt-4">Please provide your company TIN</Label>
        <Row>
          <Col xl={8} md={12}>
            <FormGroup>
              <InputGroup>
                <InputGroupAddon className="tins_prepend" addonType="prepend">
                  <FieldSelect
                    name="companyTinType"
                    placeholder="Type"
                    options={TIN_TYPES.selectOptions}
                    maxWidth={180}
                    disabled={fieldsDisabled}
                  />
                </InputGroupAddon>

                <FieldInput
                  placeholder="Company TIN *"
                  maxLength={50}
                  name="companyTin"
                  autoComplete={`hash-2352`}
                  disabled={fieldsDisabled}
                />

                <InputGroupAddon addonType="append" className="tins_append">
                  <FieldCountrySelect
                    placeholder="Country to which TIN applies *"
                    name="companyTinCountry"
                    id="companyTinCountry"
                    disabled={fieldsDisabled}
                  />
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
    </>
  );
}
