// @flow

import { Formik } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import { ModalHeader, ModalBody, ModalFooter, FormGroup, Form, Button, Row, Col } from 'reactstrap';

import { Modal } from 'core/Components/Modal';
import { changePasswordValidationSchema } from 'core/Validation/userValidation';
import { FieldPasswordInput } from 'core/Components/Form';
import { alphanumWithSymbols } from 'core/Components/Form/normalizers';

import * as actions from './actions.js';

type ChangePasswordModalProps = {
  toggleModal: () => void,
  isOpen: boolean,
};

export default function ChangePasswordModal(props: ChangePasswordModalProps) {
  const { toggleModal, isOpen } = props;
  const dispatch = useDispatch();
  const submitForm = (values) => dispatch(actions.changeUserPassword({...values}));

  const onSubmit = (values, { setSubmitting }) => {
    const { currentPassword, newPassword } = values;

    const transformedValues = {
      currentPassword: `${currentPassword}`,
      newPassword: `${newPassword}`,
    };

    const onSuccess = () => {
      setSubmitting(false);
      toggleModal();
    };
    const onError = () => {
      setSubmitting(false);
    };
    submitForm({ values: transformedValues, onSuccess, onError });
  };

  return (
    <Modal isOpen={isOpen} toggle={toggleModal}>
      <ModalHeader className="blue-background" tag="h2" toggle={toggleModal}>
        Change Password
      </ModalHeader>
      <Formik
        autoComplete="off"
        onSubmit={onSubmit}
        render={(props) => <ChangePasswordForm toggleModal={toggleModal} {...props} />}
        validationSchema={changePasswordValidationSchema}
      />
    </Modal>
  );
}

type Props = {
  handleSubmit: (values: any) => void,
  isValid: boolean,
  isSubmitting: boolean,
  toggleModal: () => void,
  status?: string,
  text?: string,
  unsubmitForm?: () => void,
  isVerification?: boolean,
};

function ChangePasswordForm(props: Props) {
  const { handleSubmit, isValid, isSubmitting, toggleModal } = props;
  const fieldsDisabled = Boolean(isSubmitting);

  return (
    <Form role="form" onSubmit={handleSubmit} autoComplete="off">
      <ModalBody>

        <Row>
          <Col>
            <FormGroup>
              <FieldPasswordInput tabIndex={1} label="Current Password" autoComplete="current-password" name="currentPassword" disabled={fieldsDisabled} normalize={alphanumWithSymbols} />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <FormGroup>
              <FieldPasswordInput tabIndex={2} label="New Password" autoComplete="new-password" name="newPassword" disabled={fieldsDisabled} normalize={alphanumWithSymbols} />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <FormGroup>
              <FieldPasswordInput tabIndex={3} label="Retype New Password" autoComplete="new-password" name="retypeNewPassword" disabled={fieldsDisabled} normalize={alphanumWithSymbols}/>
            </FormGroup>
          </Col>
        </Row>

      </ModalBody>

      <ModalFooter>
        <Button color="simple" onClick={toggleModal}>Cancel</Button>
        <Button type="submit" color="success" disabled={!isValid ||fieldsDisabled}>Change Password</Button>
      </ModalFooter>
      

    </Form>
  );
}
