// @flow
import { Record, List } from 'immutable'
import type { RecordOf, List as ListType } from 'immutable'

import { listOf } from '../Utils/immutableUtils'
import { USER_ROLES } from './Enums'

type UserBankAccountProps = {
  id?: number,
  account?: string,
  accountBeneficiary?: string,
  address?: string,
  bank?: string,
  relationshipTime?: string,
  swift?: string,
  transfer?: string,
  createdAt?: string,
}

type UserNoteProps = {
  content: string,
  createdAt: string,
}

export type UserBankAccountType = RecordOf<UserBankAccountProps>

export type UserBankAccountsListType = ListType<UserBankAccountType>

export type UserNoteType = RecordOf<UserNoteProps>

export type UserNotesListType = ListType<UserNoteType>

export class UserBankAccount extends Record<UserBankAccountProps>({
  id: undefined,
  account: undefined,
  accountBeneficiary: undefined,
  address: undefined,
  bank: undefined,
  relationshipTime: undefined,
  swift: undefined,
  transfer: undefined,
  createdAt: undefined,
}) {}

type UserPropsType = {
  id: number | null,
  clientId: number | null,
  isPartner?: boolean,
  amAccess?: boolean,
  amOwner?: number,
  partnerText?: string,
  refId?: number,
  crmId?: number,
  companyMember?: boolean,
  chatHash?: string,
  phoneCode: string,
  phoneNumber: string,
  status: string,
  token?: string,
  email: string,
  firstName: string,
  lastName: string,
  roles: ListType<string>,
  notes: UserNotesListType,
  bankAccounts: UserBankAccountsListType,
  createdAt?: string,
  verifiedAt?: string,
  closingDate?: string,
  suspended?: boolean,
  hidden?: boolean,
  refferals?: any,
  temporaryPwd?: boolean,
  confirmedEmail?: boolean,
  linkedUsers: ListType<any>,
}

export type UserType = RecordOf<UserPropsType>

export class User extends Record<UserPropsType>(
  {
    id: null,
    clientId: null,
    isPartner: undefined,
    amAccess: undefined,
    amOwner: undefined,
    refferals: undefined,
    partnerText: undefined,
    refId: undefined,
    crmId: undefined,
    companyMember: undefined,
    chatHash: undefined,
    phoneCode: '',
    phoneNumber: '',
    status: '',
    token: undefined,
    email: '',
    firstName: '',
    lastName: '',
    roles: List(),
    notes: List(),
    bankAccounts: List(),
    createdAt: undefined,
    verifiedAt: undefined,
    closingDate: undefined,
    suspended: undefined,
    hidden: undefined,
    temporaryPwd: undefined,
    confirmedEmail: undefined,
    linkedUsers: undefined,
  },
  'User'
) {
  constructor(data: UserPropsType = {}) {
    const { roles = [], bankAccounts = [], ...user } = data
    super({
      ...user,
      roles: List(roles),
      bankAccounts: listOf(UserBankAccount, bankAccounts),
    })
  }

  hasRole(roles: Array<string> = []): boolean {
    const currentRoles = this.get('roles')
    if (currentRoles.includes(USER_ROLES.SUPERADMIN)) {
      return true
    }
    return roles.every((role) => currentRoles.includes(role))
  }
}
