// @flow
import * as React from 'react';
import type { List as ListOf } from 'immutable';
import { Table, Button } from 'reactstrap';
import { format } from 'date-fns';

import { STANDARD_TIME_DATE } from 'core/Utils/dateUtils';
type PropsType = {
  documents: ListOf<any>,
  onDelete: (payload: { id: string }) => void,
}; 

export default function Documents(props: PropsType) {
  const { documents, onDelete } = props;
  return (
    <>
      <Table striped bordered responsive>
        <thead>
          <tr>
            <th scope="row">Document</th>
            <th>Submitted At</th>
            <th>Verified At</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {documents.map((document, idx) => {
            return (
              <tr key={`ver_documents_${document.id}_${idx}`}>
                <th scope="row">{document.name}</th>
                <td>{format(new Date(document.createdAt), STANDARD_TIME_DATE)}</td>
                <td>{document.verifiedAt ? format(new Date(document.verifiedAt), STANDARD_TIME_DATE) : 'Not Verified'}</td>
                <td>
                  {!document.verifiedAt ? (
                    <Button
                      type="button"
                      size="sm"
                      color="warning"
                      onClick={() => onDelete({ id: document.id })}
                    >
                      <i className="far fa-trash-alt pr-1"/>Delete
                    </Button>
                  ) : null}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
}
