import EnumDescription from './EnumDescription'

export const USER_STATUS = new EnumDescription({
  NOT_VERIFIED: {
    id: 'NOT_VERIFIED',
    label: 'Not Approved',
  },
  IN_VERIFICATION: {
    id: 'IN_VERIFICATION',
    label: 'Application Submitted',
  },
  VERIFIED: {
    id: 'VERIFIED',
    label: 'Ready to Fund',
  },
  READY_TO_TRADE: {
    id: 'READY_TO_TRADE',
    label: 'Ready to Trade',
  },
  ACTIVE_CLIENT: {
    id: 'ACTIVE_CLIENT',
    label: 'Active Client',
  },
  CLOSED: {
    id: 'CLOSED',
    label: 'Closed Account',
  },
})

export const USER_ROLES = new EnumDescription({
  SUPERADMIN: {
    id: 'SUPERADMIN',
    label: 'Super Admin',
  },
  EDIT_MANAGERS: {
    id: 'EDIT_MANAGERS',
    label: 'Edit Managers',
  },
  EDIT_USERS: {
    id: 'EDIT_USERS',
    label: 'Edit Users',
  },
  EDIT_VERIFICATIONS: {
    id: 'EDIT_VERIFICATIONS',
    label: 'Edit Verifications',
  },
  VIEW_MANAGERS: {
    id: 'VIEW_MANAGERS',
    label: 'View Managers',
  },
  VIEW_USERS: {
    id: 'VIEW_USERS',
    label: 'View Users',
  },
  VIEW_VERIFICATIONS: {
    id: 'VIEW_VERIFICATIONS',
    label: 'View Verifications',
  },
})

export const DOCUMENT_STATUS = new EnumDescription({
  NOT_VERIFIED: {
    id: 'NOT_VERIFIED',
    label: 'Not verified',
  },
  VERIFIED: {
    id: 'VERIFIED',
    label: 'Verified',
  },
})

export const VERIFICATION_STATUS = new EnumDescription({
  CLIENT_AWAITING: {
    id: 'CLIENT_AWAITING',
    label: 'Not Filled',
  },
  PENDING_APPROVAL: {
    id: 'PENDING_APPROVAL',
    label: 'Filled',
  },
  DECLINED: {
    id: 'DECLINED',
    label: 'Declined',
  },
  VERIFIED: {
    id: 'VERIFIED',
    label: 'Verified',
  },
})

export const CLIENT_TYPE = new EnumDescription({
  CORPORATE: {
    id: 'CORPORATE',
    label: 'Corporate',
    path: 'corporate',
  },
  INDIVIDUAL: {
    id: 'INDIVIDUAL',
    label: 'Individual',
    path: 'individual',
  },
})

export const CONTACT_TYPES = new EnumDescription({
  PHONE: { id: 'PHONE', label: 'Phone' },
  EMAIL: { id: 'EMAIL', label: 'Email' },
})

export const TIN_TYPES = new EnumDescription({
  TIN: { id: 'TIN', label: 'TIN' },
  EIN: { id: 'EIN', label: 'EIN' },
  SSN: { id: 'SSN', label: 'SSN' },
  GIIN: { id: 'GIIN', label: 'GIIN' },
})

export const CP_TYPES = new EnumDescription({
  BENEFICIAL_OWNER: { id: 'Beneficial owner', label: 'Beneficial owner' },
  SETTLOR: { id: 'Settlor', label: 'Settlor' },
  TRUSTEE: { id: 'Trustee', label: 'Trustee' },
  PROTECTOR: { id: 'Protector', label: 'Protector' },
  FOUNDER: { id: 'Founder', label: 'Founder' },
  COUNCIL: { id: 'Member of the foundation council', label: 'Member of the foundation council' },
  DIRECTOR: { id: 'Director', label: 'Director' },
})

export const PLATFORM_TYPES = new EnumDescription({
  ROCK: { id: 'ROCK', label: 'Rock Trader' },
  ATLAS: { id: 'ATLAS', label: 'Atlas Trader' },
  RJO: { id: 'RJO', label: 'RJO' },
  CQG: { id: 'CQG', label: 'CQG' },
  MAYBANK: { id: 'MAYBANK', label: 'Maybank' },
  OTHER: { id: 'OTHER', label: 'Other' },
})

export const PERSON_TITLES = new EnumDescription({
  MR: { id: 'Mr.', label: 'Mr.' },
  MS: { id: 'Ms.', label: 'Ms.' },
  MRS: { id: 'Mrs.', label: 'Mrs' },
  LR: { id: 'Lord', label: 'Lord' },
  LD: { id: 'Lady', label: 'Lady' },
  SIR: { id: 'Sir', label: 'Sir' },
  MD: { id: 'Madam', label: 'Madam' },
  DR: { id: 'Dr.', label: 'Doctor' },
  PR: { id: 'Professor', label: 'Professor' },
})

export const CORPORATE_POSITIONS = new EnumDescription({
  DIRECTOR: { id: 'AUTHORIZED_PERSON', label: 'Beneficiary Owner / Director / Authorized Person' },
  NONE_OF_ABOVE: { id: 'NONE_OF_ABOVE', label: 'None of the above' },
})

export const INVESTMENT_OBJECTIVES = new EnumDescription({
  SAFETY_OF_PRINCIPAL: { id: 'SAFETY_OF_PRINCIPAL', label: 'Safety of Principal' },
  INCOME: { id: 'INCOME', label: 'Income' },
  GROWTH: { id: 'GROWTH', label: 'Growth' },
  TRADING_PROFITS: { id: 'TRADING_PROFITS', label: 'Trading Profits' },
  SPECULATION: { id: 'SPECULATION', label: 'Speculation' },
})

export const SOURCE_OF_FUNDS = new EnumDescription({
  OWNER_OF_BUSINESS: { id: 'OWNER_OF_BUSINESS', label: 'Owner of Business' },
  SALARY: { id: 'SALARY', label: 'Salary' },
  INVESTMENTS: { id: 'INVESTMENTS', label: 'Investments' },
  INHERITANCE: { id: 'INHERITANCE', label: 'Inheritance' },
  OTHER: { id: 'OTHER', label: 'Other' },
})

export const FREQUENCY = new EnumDescription({
  LOW_FREQUENCY: { id: 'LOW_FREQUENCY', label: '< 10 per month' },
  MEDIUM_FREQUENCY: { id: 'MEDIUM_FREQUENCY', label: '11 - 20 per month' },
  HIGH_FREQUENCY: { id: 'HIGH_FREQUENCY', label: '21+ per month' },
})

export const RELATIONSHIP_TIMES = new EnumDescription({
  LESS_THEN_ONE_YEAR: { id: 'LESS_THEN_ONE_YEAR', label: 'Less than one year' },
  FROM_ONE_TO_THREE_YEARS: { id: 'FROM_ONE_TO_THREE_YEARS', label: 'From one to three years' },
  MORE_THEN_THREE_YEARS: { id: 'MORE_THEN_THREE_YEARS', label: 'More then three years' },
})

export const USER_NOTE_TYPES = new EnumDescription({
  INTERNAL: { id: 'INTERNAL', label: 'Internal' },
  PUBLIC: { id: 'PUBLIC', label: 'Public' },
})

export const CLIENT_DOCUMENT_TYPE = new EnumDescription({
  CLIENT: {
    id: 'CLIENT',
    label: 'Client',
  },
  GOOGLE: {
    id: 'GOOGLE',
    label: 'Google',
  },
  FACEBOOK: {
    id: 'FACEBOOK',
    label: 'Facebook',
  },
  WORLD: {
    id: 'WORLD',
    label: 'World',
  },
  MANAGER: {
    id: 'MANAGER',
    label: 'Manager',
  },
})

export const RISK_PROFILE = new EnumDescription({
  LOW_RISK: {
    id: 'LOW_RISK',
    label: 'Low Risk',
  },
  MEDIUM_RISK: {
    id: 'MEDIUM_RISK',
    label: 'Medium Risk',
  },
  HIGH_RISK: {
    id: 'HIGH_RISK',
    label: 'High Risk',
  },
})
