import { List } from 'immutable';
import { createSelector } from 'reselect';

import { UserBankAccount } from 'core/Models/User';

const selectAppSpace = (state) => state.get('app');

export const selectUser = createSelector(
  selectAppSpace,
  (app) => app.get('user')
);

export const selectUserToken = createSelector(
  selectAppSpace,
  (app) => app.get('token')
);

export const selectClient = createSelector(
  selectAppSpace,
  (app) => app.get('client')
);

export const selectIsUserAuthenticated = createSelector(
  selectUser,
  (user) => Boolean(user.get('token', false))
);

export const selectUserStatus = createSelector(
  selectUser,
  (user) => user.get('status')
);

export const selectUserBankAccountFirst = createSelector(
  selectUser,
  (user) => user.getIn(['bankAccounts', 0], new UserBankAccount({}))
);

export const selectUserBankAccounts = createSelector(
  selectUser,
  (user) => user.get('bankAccounts', List())
);
