// @flow
import React from 'react';
import { FormGroup, Row, Col, Form, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { VERIFICATION_STATUS, RELATIONSHIP_TIMES } from 'core/Models/Enums';
import { FieldInput, FieldSelect, FormikPersist } from 'core/Components/Form';
import { onlyLatinLetters, alphanumWithSymbols } from 'core/Components/Form/normalizers';
import type { UserType } from 'core/Models/User';

import { selectUser } from '../../Selectors';

type Props = {
  handleSubmit: (values: any) => void,
  isValid: boolean,
  isSubmitting: boolean,
  status?: string,
  unsubmitForm?: () => void,
  isVerification?: boolean,
};

export default function BancAccountForm(props: Props) {
  const { handleSubmit, isValid, isSubmitting, status, unsubmitForm, isVerification } = props;
  const fieldsDisabled = Boolean(isSubmitting || (isVerification && status === VERIFICATION_STATUS.PENDING_APPROVAL));

  const user: UserType = useSelector(selectUser);

  return (
    <Form role="form" onSubmit={handleSubmit} autoComplete="off">
      <Row>
        <Col xl="6" md="6">
          <FormGroup>
            <label className="muted_label" htmlFor="bank">
              Bank *
            </label>
            <FieldInput name="bank" id="bank" disabled={fieldsDisabled} normalize={alphanumWithSymbols} />
          </FormGroup>
        </Col>
        <Col xl="6" md="6">
          <FormGroup>
            <label className="muted_label" htmlFor="address">
              Bank Address *
            </label>
            <FieldInput name="address" id="address" disabled={fieldsDisabled} normalize={alphanumWithSymbols} />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xl="6" md="6">
          <FormGroup>
            <label className="muted_label" htmlFor="accountBeneficiary">
              Beneficiary Account Name *
            </label>
            <FieldInput
              name="accountBeneficiary"
              id="accountBeneficiary"
              disabled={fieldsDisabled}
              normalize={onlyLatinLetters}
            />
          </FormGroup>
        </Col>
        <Col xl="6" md="6">
          <FormGroup>
            <label className="muted_label" htmlFor="account">
              Account Number / IBAN *
            </label>
            <FieldInput name="account" id="account" disabled={fieldsDisabled} normalize={alphanumWithSymbols} />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xl="12" md="12">
          <FormGroup>
            <label className="muted_label" htmlFor="swift">
              SWIFT / BIC / ABA *
            </label>
            <FieldInput name="swift" id="swift" disabled={fieldsDisabled} normalize={alphanumWithSymbols} />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xl="12" md="12">
          <FormGroup>
            <label className="muted_label" htmlFor="relationshipTime">
              How long have you been a client of this bank (years)? *
            </label>
            <FieldSelect
              name="relationshipTime"
              id="relationshipTime"
              disabled={fieldsDisabled}
              options={RELATIONSHIP_TIMES.selectOptions}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xl="12" md="12">
          <FormGroup>
            <FieldInput 
              label="If your initial funding is done with securities transfer, specify broker/custody name"
              name="transfer" 
              id="transfer" 
              disabled={fieldsDisabled} 
              normalize={alphanumWithSymbols} />
          </FormGroup>
        </Col>
      </Row>
      <>

        {isVerification ? (
          <>
            {status === VERIFICATION_STATUS.PENDING_APPROVAL ? (
              <Button type="button" color="warning" onClick={unsubmitForm}>
                <i className="fas fa-times pr-2"></i>Unsubmit
              </Button>
            ) : (
              <Button type="submit" color="success" disabled={!isValid || fieldsDisabled}>
                <i className="fas fa-check pr-2"></i>Submit
              </Button>
            )}
            <Button to="/support" tag={Link}>
              <i className="far fa-question-circle pr-2"/>Have Questions?
            </Button>
            {status === VERIFICATION_STATUS.CLIENT_AWAITING ? (
              <FormikPersist autoComplete="off" name={`billing-information-form_${user.id ?? 0}`} isSessionStorage />
            ) : null}
          </>
        ) : null}

        {!isVerification && 
          <div className="d-flex justify-content-end mt-2"> <Button type="submit" color="success" disabled={!isValid || fieldsDisabled}><i className="fas fa-plus pr-2"></i>Add Bank Account</Button></div>
        }
      </>
    </Form>
  );
}
