// @flow
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Row,
  Col,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  Button,
  Form,
  Label,
  CardFooter,
  InputGroupText,
  CardHeader,
} from 'reactstrap';
import { Formik, FieldArray } from 'formik';
import { subYears, addYears, addMonths } from 'date-fns';
import { Link, Redirect } from 'react-router-dom';
import type { ContextRouter } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import { transformDateToString } from 'core/Utils/dateUtils';
import type { UserType } from 'core/Models/User';
import type { ClientType } from 'core/Models/Client';
import {
  CLIENT_TYPE,
  CONTACT_TYPES,
  TIN_TYPES,
  CORPORATE_POSITIONS,
  PERSON_TITLES,
  VERIFICATION_STATUS,
} from 'core/Models/Enums';
import mapClientToProps from 'core/Transformers/mapClientToProps';
import { corporateValidationSchema, individualValidationSchema } from 'core/Validation/clientValidation';
import {
  FieldInput,
  FieldDatePicker,
  FieldCountrySelect,
  FieldSelect,
  FieldCheckbox,
  FormikPersist,
} from 'core/Components/Form';
import { alphanumWithSymbols } from 'core/Components/Form/normalizers';

import { selectUser, selectClient } from '../../../../Selectors';

import IndividualTypeBlock from './IndividualTypeBlock';
import CorporateTypeBlock from './CorporateTypeBlock';

import * as actions from '../actions.js';

const maxBirthDate = subYears(new Date(), 18);
const minBirthDate = subYears(new Date(), 100);

const defaultBirthDate = subYears(new Date(), 35);
const minPassportExpiryDate = addMonths(new Date(), 3);
const maxPassportExpiryDate = addYears(new Date(), 50);

const mapPropsToValues = (user, client, clientType) => ({
  ...mapClientToProps(user, client, clientType),
});

const nextStepPath = '/verification/steps/documents';

type FormikEnhancerProps = {
  ...ContextRouter,
  parentPath: string,
};

export default function FormikEnhancer(props: FormikEnhancerProps) {
  const dispatch = useDispatch();
  const submitForm = (values) => dispatch(actions.sendClientInformation(values));
  const unsubmitForm = () => dispatch(actions.unsubmitClientInformation());

  const [redirectToNextStep, setRedirectToNextStep] = useState(false);

  const user: UserType = useSelector(selectUser);
  const client: ClientType = useSelector(selectClient);

  const clientTypeFromUrl = props.match.params.clientType ?? '';
  const clientTypeFromModel = client.type;
  const clientType =
    !client.status || client.status === VERIFICATION_STATUS.CLIENT_AWAITING
      ? clientTypeFromUrl.toUpperCase()
      : clientTypeFromModel;

  const onSubmit = (values, { setSubmitting }) => {
    const {
      contacts,
      tins,

      citizenship,
      passportNumber,
      passportExpiryDate,
      birthday,

      address,
      addressCity,
      addressState,
      residence,
      addressPostalCode,

      occupation,
      employerName,

      position,
      companyName,
      companyIncorporationDate,
      companyIncorporationCountry,
      companyRegnumber,
      companyDescription,

      companyAddress,
      companyAddressCity,
      companyAddressState,
      companyAddressCountry,
      companyAddressPostalCode,

      ...restValues
    } = values;

    const personValues = {
      citizenship,
      passportNumber,
      ...(passportExpiryDate && { passportExpiryDate: transformDateToString(passportExpiryDate) }),
      ...(birthday && { birthday: transformDateToString(birthday) }),
      address,
      addressCity,
      addressState,
      residence,
      addressPostalCode,
    };

    const individualValues = {
      occupation,
      employerName,
    };

    const corporateValues = {
      position,
      companyName,
      ...(companyIncorporationDate && { companyIncorporationDate: transformDateToString(companyIncorporationDate) }),
      companyIncorporationCountry,
      companyRegnumber,
      companyDescription,

      companyAddress,
      companyAddressCity,
      companyAddressState,
      companyAddressCountry,
      companyAddressPostalCode,
    };

    const resolveResultValues = () => {
      if (clientType === CLIENT_TYPE.CORPORATE) {
        if (position !== CORPORATE_POSITIONS.NONE_OF_ABOVE) {
          return { ...corporateValues, ...personValues };
        }
        return { ...corporateValues };
      }
      return { ...individualValues, ...personValues };
    };

    const data = {
      client: {
        ...restValues,
        ...resolveResultValues(),
        type: clientType,
      },
      contacts,
      tins,
    };

    const cb = () => {
      window.scrollTo(0, 0);
      setSubmitting(false);
      setRedirectToNextStep(true);
    };
    submitForm({ values: data, cb, isUpdate: Boolean(user.clientId) });
  };

  const initialValues = mapPropsToValues(user, client, clientType);
  console.log('init', initialValues);
  const validationSchema =
    clientType === CLIENT_TYPE.INDIVIDUAL ? individualValidationSchema : corporateValidationSchema;

  if (
    clientTypeFromModel &&
    clientTypeFromModel !== clientTypeFromUrl.toUpperCase() &&
    client.status !== VERIFICATION_STATUS.CLIENT_AWAITING
  ) {
    return <Redirect to={`${props.parentPath}/${clientTypeFromModel.toLowerCase()}`} />;
  }

  if (redirectToNextStep) {
    return <Redirect to={nextStepPath} />;
  }

  return (
    <Formik
      autoComplete="off"
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={(props) => (
        <ClientForm
          {...props}
          user={user}
          clientType={clientType}
          clientVerificationStatus={client.status}
          unsubmitForm={unsubmitForm}
        />
      )}
      validationSchema={validationSchema}
      isInitialValid={() => validationSchema.isValidSync(initialValues)}
      // enableReinitialize
    />
  );
}

type Props = {
  handleSubmit: (values: any) => void,
  setFieldValue: (field: string, value: any) => void,
  unsubmitForm: () => void,
  values: any,
  isValid: boolean,
  isSubmitting: boolean,
  user: UserType,
  clientType: string,
  clientVerificationStatus: string,
};

function ClientForm(props: Props) {
  const {
    handleSubmit,
    values,
    isValid,
    isSubmitting,
    user,
    clientType,
    clientVerificationStatus,
    unsubmitForm,
    setFieldValue,
  } = props;

  console.log('values from props', values);

  if (!values.tins) {
    setFieldValue('tins', [{ tin: '', country: 'United States', type: 'TIN' }]);
  }

  const fieldsDisabled = Boolean(
    isSubmitting ||
      clientVerificationStatus === VERIFICATION_STATUS.PENDING_APPROVAL ||
      clientVerificationStatus === VERIFICATION_STATUS.VERIFIED
  );

  const client: ClientType = useSelector(selectClient);

  useEffect(() => {
    setFieldValue('type', clientType);
  }, [clientType, setFieldValue]);

  return (
    <Form role="form" onSubmit={handleSubmit} autoComplete="off">
      <CardHeader tag="h2" className="form-section">
        2. Please provide your personal information
      </CardHeader>
      <CardFooter>
        <ReactTooltip effect="float" />
        <Row>
          {clientType === CLIENT_TYPE.CORPORATE ? (
            <Col xl="4" lg="6">
              <FormGroup>
                <InputGroup>
                  <FieldSelect
                    label="Position in the company *"
                    name="position"
                    id="position"
                    options={CORPORATE_POSITIONS.selectOptions}
                    disabled={fieldsDisabled}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
          ) : null}
          <Col xl="4" lg="6">
            <FormGroup>
              <InputGroup>
                <Label className="muted_label" style={{ display: 'block', width: '100%' }} htmlFor="fullName">
                  Full Name *
                </Label>
                <InputGroupAddon className="title_prepend" addonType="prepend">
                  <FieldSelect
                    type="text"
                    name="title"
                    id="title"
                    options={PERSON_TITLES.selectOptions}
                    menuPosition="fixed"
                    maxMenuWidth={200}
                    disabled={fieldsDisabled}
                  />
                </InputGroupAddon>
                <FieldInput type="text" name="fullName" id="fullName" style={{ paddingLeft: 15 }} disabled />
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
        {clientType === CLIENT_TYPE.INDIVIDUAL || values.position !== CORPORATE_POSITIONS.NONE_OF_ABOVE ? (
          <>
            <Row>
              <Col xl="4" lg="6">
                <FormGroup>
                  <FieldInput
                    label="Passport Number *"
                    name="passportNumber"
                    id="passportNumber"
                    disabled={fieldsDisabled}
                    normalize={alphanumWithSymbols}
                    maxLength={30}
                  />
                </FormGroup>
              </Col>
              <Col xl="4" lg="6">
                <FormGroup>
                  <FieldDatePicker
                    label="Passport Expiry Date *"
                    name="passportExpiryDate"
                    id="passportExpiryDate"
                    minDate={minPassportExpiryDate}
                    maxDate={maxPassportExpiryDate}
                    showYearDropdown
                    scrollableYearDropdown
                    showMonthDropdown
                    yearDropdownItemNumber={15}
                    disabled={fieldsDisabled}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col xl="4" lg="6">
                <FormGroup>
                  <InputGroup>
                    <FieldCountrySelect
                      label="Citizenship *"
                      name="citizenship"
                      id="citizenship"
                      disabled={fieldsDisabled}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col xl="4" lg="6">
                <FormGroup>
                  <FieldDatePicker
                    label="Date of Birth *"
                    name="birthday"
                    id="birthday"
                    maxDate={maxBirthDate}
                    minDate={minBirthDate}
                    openToDate={defaultBirthDate}
                    showYearDropdown
                    scrollableYearDropdown
                    showDisabledMonthNavigation
                    showMonthDropdown
                    yearDropdownItemNumber={60}
                    disabled={fieldsDisabled}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col xl="4" lg="6">
                <FormGroup>
                  <InputGroup>
                    <FieldCountrySelect
                      label="Birthday Country *"
                      name="birthdayCountry"
                      id="birthdayCountry"
                      disabled={fieldsDisabled}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col xl="4" lg="6">
                <FormGroup>
                  <FieldInput
                    label="Birthday City *"
                    name="birthdayCity"
                    id="birthdayCity"
                    disabled={fieldsDisabled}
                    normalize={alphanumWithSymbols}
                    autoComplete="hash-1533"
                    maxLength={100}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col xl="8" lg="12">
                <FormGroup>
                  <FieldInput
                    label="Residential Address (street, building, apt.) *"
                    name="address"
                    id="address"
                    disabled={fieldsDisabled}
                    normalize={alphanumWithSymbols}
                    autoComplete="hash-1532"
                    maxLength={200}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xl="4" lg="6">
                <FormGroup>
                  <FieldInput
                    label="City *"
                    name="addressCity"
                    id="addressCity"
                    disabled={fieldsDisabled}
                    normalize={alphanumWithSymbols}
                    autoComplete="hash-1533"
                    maxLength={100}
                  />
                </FormGroup>
              </Col>
              <Col xl="4" lg="6">
                <FormGroup>
                  <FieldInput
                    label="State"
                    name="addressState"
                    id="addressState"
                    disabled={fieldsDisabled}
                    normalize={alphanumWithSymbols}
                    autoComplete="hash-1533"
                    maxLength={100}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xl="4" lg="6">
                <FormGroup>
                  <InputGroup>
                    <FieldCountrySelect
                      label="Country of Residence *"
                      name="residence"
                      id="residence"
                      disabled={fieldsDisabled}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col xl="4" lg="6">
                <FormGroup>
                  <FieldInput
                    label="Postal Code *"
                    name="addressPostalCode"
                    id="addressPostalCode"
                    disabled={fieldsDisabled}
                    normalize={alphanumWithSymbols}
                    autoComplete="hash-1533"
                    maxLength={30}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Label className="muted_label mb-3">Please provide your personal TIN</Label>
            <FieldArray
              name="tins"
              render={(arrayHelpers) => (
                <>
                  {values.tins.map((contact, idx) => (
                    <Row key={`tins_row_${idx}`}>
                      <Col xl={8} md={12}>
                        <FormGroup>
                          <InputGroup>
                            <InputGroupAddon className="tins_prepend" addonType="prepend">
                              <FieldSelect
                                name={`tins.${idx}.type`}
                                placeholder="Type"
                                options={TIN_TYPES.selectOptions}
                                maxWidth={180}
                                disabled={fieldsDisabled}
                              />
                            </InputGroupAddon>

                            <FieldInput
                              maxLength={30}
                              placeholder="Personal TIN *"
                              name={`tins.${idx}.tin`}
                              autoComplete={`hash-235${idx}`}
                              disabled={fieldsDisabled}
                            />

                            <InputGroupAddon addonType="append" className="tins_append">
                              <FieldCountrySelect
                                placeholder="Country to which TIN applies *"
                                name={`tins.${idx}.country`}
                                id={`tins.${idx}.country`}
                                disabled={fieldsDisabled}
                              />
                            </InputGroupAddon>

                            {!fieldsDisabled && (
                              <InputGroupAddon addonType="append">
                                <Button
                                  type="button"
                                  size="sm"
                                  color="warning"
                                  onClick={() => arrayHelpers.remove(idx)}
                                  disabled={fieldsDisabled || idx === 0}
                                >
                                  <i className="far fa-trash-alt px-1" />
                                </Button>
                              </InputGroupAddon>
                            )}
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                  ))}
                  {values.tins.length <= 4 ? (
                    <Row>
                      <Col lg="2" md="6">
                        <Button
                          type="button"
                          color="success"
                          onClick={() => arrayHelpers.push({ type: TIN_TYPES.TIN, tin: '', country: 'United States' })}
                          disabled={fieldsDisabled}
                        >
                          <i className="fas fa-plus pr-2" />
                          Add TIN
                        </Button>
                      </Col>
                    </Row>
                  ) : null}
                </>
              )}
            />
          </>
        ) : null}

        <Row>
          <Col xl={8} md={12}>
            <FormGroup className="mt-4">
              <label className="muted_label" htmlFor="recommendedBy">
                Where did you first hear about our services?{' '}
                <i data-tip="This is important for US persons" className="far fa-question-circle" />
              </label>
              <FieldInput
                name="recommendedBy"
                id="recommendedBy"
                placeholder="Details, Name, Date (if possible)"
                disabled={fieldsDisabled}
                normalize={alphanumWithSymbols}
                maxLength={150}
              />
            </FormGroup>
          </Col>
        </Row>
      </CardFooter>

      <CardHeader tag="h2" className="form-section">
        3. Please provide your contact information
      </CardHeader>

      <CardFooter>
        <FieldArray
          name="contacts"
          render={(arrayHelpers) => (
            <>
              {values.contacts.map((contact, idx) => (
                <Row key={`contacts_row_${idx}`}>
                  <Col xl={8} md={12}>
                    <FormGroup>
                      <InputGroup>
                        <InputGroupAddon className="contacts_prepend" addonType="prepend">
                          <FieldSelect
                            name={`contacts.${idx}.type`}
                            options={CONTACT_TYPES.selectOptions}
                            maxWidth={120}
                            disabled={fieldsDisabled || idx === 0}
                          />
                        </InputGroupAddon>

                        <FieldInput
                          maxLength={50}
                          name={`contacts.${idx}.value`}
                          autoComplete={`hash-23${idx}`}
                          disabled={fieldsDisabled || idx === 0}
                        />

                        {idx !== 0 && (
                          <InputGroupAddon addonType="append">
                            <InputGroupText>
                              <Label check className="contact-checkbox" htmlFor={`contacts.${idx}.isPrimary`}>
                                <FieldCheckbox
                                  name={`contacts.${idx}.isPrimary`}
                                  id={`contacts.${idx}.isPrimary`}
                                  disabled={fieldsDisabled}
                                  className="pr-2"
                                />
                                Primary
                              </Label>
                            </InputGroupText>
                          </InputGroupAddon>
                        )}

                        {!fieldsDisabled && (
                          <InputGroupAddon addonType="append">
                            <Button
                              type="button"
                              size="sm"
                              color="warning"
                              onClick={() => arrayHelpers.remove(idx)}
                              disabled={fieldsDisabled || idx === 0}
                            >
                              <i className="far fa-trash-alt px-1" />
                            </Button>
                          </InputGroupAddon>
                        )}
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              ))}
              {values.contacts.length <= 4 ? (
                <Row>
                  <Col lg="2" md="6">
                    <Button
                      type="button"
                      color="success"
                      onClick={() => arrayHelpers.push({ type: CONTACT_TYPES.PHONE, value: '', isPrimary: false })}
                      disabled={fieldsDisabled}
                    >
                      <i className="fas fa-plus pr-2" />
                      Add contact
                    </Button>
                  </Col>
                </Row>
              ) : null}
            </>
          )}
        />
      </CardFooter>

      {clientType === CLIENT_TYPE.INDIVIDUAL ? (
        <IndividualTypeBlock fieldsDisabled={fieldsDisabled} />
      ) : (
        <CorporateTypeBlock fieldsDisabled={fieldsDisabled} />
      )}

      {clientVerificationStatus === VERIFICATION_STATUS.PENDING_APPROVAL && (
        <CardFooter>
          <span>
            Thank you for the information provided. Your information is being verified. It usually takes{' '}
            <b>up to 3 business days.</b> Please note that we may contact you in case we need any additional
            information.
          </span>
        </CardFooter>
      )}

      <CardFooter>
        {clientVerificationStatus !== VERIFICATION_STATUS.PENDING_APPROVAL && (
          <Button
            type="submit"
            color="success"
            disabled={
              !isValid ||
              clientVerificationStatus === VERIFICATION_STATUS.PENDING_APPROVAL ||
              clientVerificationStatus === VERIFICATION_STATUS.VERIFIED
            }
          >
            <i className="fas fa-check pr-2" />
            Submit
          </Button>
        )}

        {clientVerificationStatus === VERIFICATION_STATUS.PENDING_APPROVAL && (
          <Button type="button" color="warning" onClick={unsubmitForm}>
            <i className="fas fa-times pr-2" />
            Unsubmit
          </Button>
        )}

        <Button
          color="primary"
          tag={Link}
          to={nextStepPath}
          disabled={!client.id || clientVerificationStatus === VERIFICATION_STATUS.CLIENT_AWAITING}
        >
          <i className="fas fa-arrow-right pr-2" />
          Next step
        </Button>

        <Button to="/support" tag={Link}>
          <i className="far fa-question-circle pr-2" />
          Have Questions?
        </Button>
      </CardFooter>

      {!user.clientId && <FormikPersist name={`client-form_${user.id ?? 0}`} isSessionStorage />}
    </Form>
  );
}
