// @flow
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardBody, Col, Form, CardHeader, FormGroup, CardFooter } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import type { ContextRouter } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { alphanumWithSymbols } from 'core/Components/Form/normalizers';
import queryString from 'query-string';
import { FieldInput, FieldPasswordInput } from 'core/Components/Form';

import { selectUserToken } from '../../Selectors';
import { AuthLayout } from '../../Components/Layout';

import * as actions from './actions';
import cookie from 'react-cookies';


const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Incorrect email')
    .required('Please enter your email'),
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password too short'),
});

type FormikEnhancerProps = ContextRouter & {};

export default function FormikEnhancer(props: FormikEnhancerProps) {
  const dispatch = useDispatch();
  const loginUser = ({ values, onSuccess, onError }) => dispatch(actions.loginUser({ values, onSuccess, onError }));

  const expires = new Date();
  expires.setDate(new Date().getDate() + 90);
  const utmParams = queryString.parse(props.location.search);
  if(utmParams.utm_source) cookie.save('utm_source', utmParams.utm_source || '', {expires});
  if(utmParams.utm_medium) cookie.save('utm_medium', utmParams.utm_medium || '', {expires});
  if(utmParams.utm_campaign) cookie.save('utm_campaign', utmParams.utm_campaign || '', {expires});

  const userToken = useSelector(selectUserToken);

  const { from } = props.location.state || { from: { pathname: '/' } };

  if (userToken) {
    return <Redirect to={from} />;
  }

  const onSubmit = (values, { setSubmitting }) => {
    const onSuccess = () => {
      setSubmitting(false);
    };
    const onError = () => {
      setSubmitting(false);
    };
    loginUser({ values, onSuccess, onError });
  };

  return <Formik autoComplete="off" component={LoginForm} onSubmit={onSubmit} validationSchema={validationSchema} />;
}

type LoginFormProps = {
  isSubmitting: boolean,
  isValid: boolean,
  handleSubmit: () => void,
};

function LoginForm(props: LoginFormProps) {
  const { isSubmitting, isValid, handleSubmit } = props;

  return (
    <AuthLayout>
      <Col xl={5} lg={6} md={8}>
        <Card className="card-auth">

          <CardHeader tag="h1">Client Area</CardHeader>

          <CardBody>
            <Form role="form" onSubmit={handleSubmit} className="hide-autocomplete" autoComplete="off">

              <FormGroup>
                <FieldInput
                  label="Email"
                  tabIndex={1}
                  type="email"
                  name="email"
                  id="email"
                  disabled={isSubmitting}
                  autoComplete="username"
                  normalize={alphanumWithSymbols}
                />
              </FormGroup>

              <FormGroup>
                <FieldPasswordInput
                  label="Password"
                  tabIndex={2}
                  name="password"
                  id="password"
                  disabled={isSubmitting}
                  autoComplete="current-password"
                  normalize={alphanumWithSymbols}
                />
              </FormGroup>

              <Button className="my-2 auth_button" color="brand" type="submit" disabled={isSubmitting || !isValid}>
                LOG IN
              </Button>
            </Form>
          </CardBody>
          <CardFooter>
            <Link to="/reset_password_email">
              Forgot password?
            </Link>
            <Link to="/registration">
              Not a Client yet?
            </Link>
          </CardFooter>
        </Card>
        
      </Col>
    </AuthLayout>
  );
}
