import { CONTACT_TYPES, TIN_TYPES, PERSON_TITLES } from '../Models/Enums'

export default function mapClientToProps(user, client, clientType) {
  return {
    type: clientType,
    ieId: client.ieId,

    title: client.title ?? PERSON_TITLES.MR,
    fullName: `${user.firstName} ${user.lastName}`,

    citizenship: client.citizenship,
    passportNumber: client.passportNumber,
    passportExpiryDate: client.passportExpiryDate ? new Date(client.passportExpiryDate) : undefined,
    birthday: client.birthday ? new Date(client.birthday) : undefined,
    birthdayCity: client.birthdayCity,
    birthdayCountry: client.birthdayCountry,

    address: client.address,
    addressCity: client.addressCity,
    addressState: client.addressState,
    residence: client.residence,
    addressPostalCode: client.addressPostalCode,

    companyTinType: client.companyTinType ? client.companyTinType : TIN_TYPES.TIN,
    companyTin: client.companyTin,
    companyTinCountry: client.companyTinCountry ? client.companyTinCountry : 'United States',

    recommendedBy: client.recommendedBy || undefined,
    contacts: (client.contacts?.size && client.contacts.toJS()) || [
      {
        type: CONTACT_TYPES.PHONE,
        value: `${user.phoneCode}${user.phoneNumber}`,
        isPrimary: true,
      },
    ],
    tins: (client.tins?.size && client.tins.toJS()) || [],

    occupation: client.occupation,
    employerName: client.employerName,

    position: client.position,
    companyName: client.companyName,
    companyIncorporationDate: client.companyIncorporationDate ? new Date(client.companyIncorporationDate) : undefined,
    companyIncorporationCountry: client.companyIncorporationCountry,
    companyRegnumber: client.companyRegnumber,
    companyDescription: client.companyDescription,
    companyRegulatorName: client.companyRegulatorName,

    companyAddress: client.companyAddress,
    companyAddressCity: client.companyAddressCity,
    companyAddressState: client.companyAddressState,
    companyAddressCountry: client.companyAddressCountry,
    companyAddressPostalCode: client.companyAddressPostalCode,
  }
}
