// @flow
import * as React from 'react';
import { Link } from 'react-router-dom';
import { CardHeader, CardBody, CardFooter, Button, Col, Row } from 'reactstrap';

import styles from './styles.module.scss';

type Props = {};

export default function Greeting(props: Props) {
  return (
    <>
    <CardHeader tag="h1">A BIG WELCOME TO INVESTORS EUROPE!</CardHeader>
    <CardBody>
      
      <p>Thank you for your interest in Investors Europe. There are 3 steps which need to be completed before your account is open and ready to fund. </p>
      <p>Please follow the steps below to complete your account opening.</p>

      <Row className="my-4 blue-background">
        <Col md="12">
          <h2 className="my-3">Next steps:</h2>
          <ol className={styles.greetingList}>
            <li>
              <span>1</span>
              <div>Fill in client information in the digital form</div>
            </li>
            <li>
              <span>2</span>
              <div>
                Upload documents. Please note that you will need a scanner to provide us with scanned copies of your documents.<br/>
                <small><u>Download</u>, <u>print</u> and <u>sign</u> your application and General Terms and Conditions. Upload them along with certified documents, certified Proof of ID (PoI) and Proof of Address (PoA). Please see our due diligence and certification requirements here: <a target="_blank" rel="noopener noreferrer" href="https://ie-static.ams3.cdn.digitaloceanspaces.com/v2/documents/Due_Diligance_Individuals.pdf">For Individuals</a>, <a target="_blank" rel="noopener noreferrer" href="https://ie-static.ams3.cdn.digitaloceanspaces.com/v2/documents/Due_Diligance_Companies.pdf">For Corporates</a>. Please read them carefully.</small>
              </div>
            </li>
            <li>
              <span>3</span>
              <div>
                Add additional information<br/>
                <small>In order to comply with regulatory requirements for your trading account, we kindly ask you to provide
                us with up-to-date bank details, investment goals and trading experience.</small>
              </div>
            </li>
          </ol>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md="12">
          <p>Once all the steps have been processed, your account will be ready for funding.</p>
          <p>Please note that you must fund your account from the bank account specified during registration.</p>
        </Col>
      </Row>

     
    </CardBody>
    <CardFooter>
      <Button color="brand" to="steps" tag={Link}>START APPLICATION</Button>
      <Link to="/support" className="btn">HAVE QUESTIONS?</Link>
    </CardFooter>
    </>
  );
}
