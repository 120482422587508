// @flow
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Row,
  Col,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  ButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import { Formik } from 'formik';
import cookie from 'react-cookies';
import { useInterval } from 'core/Hooks';
import { FieldInput, FieldPhoneInput, FieldPasswordInput } from 'core/Components/Form';
import { onlyLatinLetters, onlyNumbers, alphanumWithSymbols } from 'core/Components/Form/normalizers';
import { userClientValidationSchema } from 'core/Validation/userValidation';
import queryString from 'query-string';

import { AuthLayout } from '../../Components/Layout';

import * as actions from './actions';

const SMS_CODE_SECONDS_INTERVAL = 60;

type FormikEnhancerProps = {
  location: any,
};

export default function FormikEnhancer(props: FormikEnhancerProps) {
  const dispatch = useDispatch();
  const [redirectToSuccess, setRedirectToSuccess] = useState(false);
  const [smsCodeCountdown, setSmsCodeCountdown] = useState(SMS_CODE_SECONDS_INTERVAL);
  const [isSmsCodeCountdownRunning, setIsSmsCodeCountdownRunning] = useState(false);

  const expires = new Date();
  expires.setDate(new Date().getDate() + 90);

  const utmParams = queryString.parse(props.location.search);
  if (utmParams.utm_source) cookie.save('utm_source', utmParams.utm_source || '', { expires });
  if (utmParams.utm_medium) cookie.save('utm_medium', utmParams.utm_medium || '', { expires });
  if (utmParams.utm_campaign) cookie.save('utm_campaign', utmParams.utm_campaign || '', { expires });

  useInterval(
    () => {
      if (smsCodeCountdown > 0) {
        setSmsCodeCountdown(smsCodeCountdown - 1);
      } else {
        setIsSmsCodeCountdownRunning(false);
      }
    },
    isSmsCodeCountdownRunning ? 1000 : null
  );

  const registerUser = (data) => dispatch(actions.registerUser(data));

  const sendSmsCode = ({ phoneNumber, type, onError }) => {
    dispatch(actions.sendSmsCode({ values: { phoneNumber, type }, onError }));
  };

  const onSubmit = (values, { setSubmitting }) => {
    const { passwordConfirmation, phoneNumber, code, ...restValues } = values;

    const data = {
      user: {
        ...restValues,
        phoneCode: `+${phoneNumber.countryCode}`,
        phoneNumber: `${phoneNumber.phoneWithoutCode}`,
        utm_source: cookie.load('utm_source') || '',
        utm_medium: cookie.load('utm_medium') || '',
        utm_campaign: cookie.load('utm_campaign') || '',
      },
      code: `${code}`,
    };

    const onSuccess = () => {
      setSubmitting(false);
      setRedirectToSuccess(true);
    };

    const onError = () => {
      setSubmitting(false);
    };

    registerUser({ values: data, onSuccess, onError });
  };

  const onSendCode = ({ phoneNumber, type }) => {
    setIsSmsCodeCountdownRunning(true);
    const onError = () => {
      setIsSmsCodeCountdownRunning(false);
      setSmsCodeCountdown(SMS_CODE_SECONDS_INTERVAL);
    };
    sendSmsCode({ phoneNumber, type, onError });
  };

  if (redirectToSuccess) return <Redirect to="/registration_success" />;

  return (
    <Formik
      autoComplete="off"
      onSubmit={onSubmit}
      render={(props) => (
        <RegistrationForm
          {...props}
          onSendCode={onSendCode}
          smsCodeCountdown={smsCodeCountdown}
          isSmsCodeCountdownRunning={isSmsCodeCountdownRunning}
        />
      )}
      validationSchema={userClientValidationSchema}
    />
  );
}

type RegistrationFormProps = {
  isSubmitting: boolean,
  isValid: boolean,
  handleSubmit: (Object) => void,
  onSendCode: ({ phoneNumber: string, type: string }) => void,
  smsCodeCountdown: number,
  isSmsCodeCountdownRunning: boolean,
  errors: any,
  values: any,
};

function RegistrationForm(props: RegistrationFormProps) {
  const {
    handleSubmit,
    isSubmitting,
    isValid,
    values,
    errors,
    onSendCode,
    smsCodeCountdown,
    isSmsCodeCountdownRunning,
  } = props;

  const [dropdownOpen, setOpen] = useState(false);

  const toggle = () => setOpen(!dropdownOpen);

  return (
    <AuthLayout>
      <Col xl={8} lg={8} md={10}>
        <Card className="card-auth">
          <CardHeader tag="h1">OPEN ACCOUNT</CardHeader>

          <CardBody>
            <Form role="form" onSubmit={handleSubmit} autoComplete="off" className="hide-autocomplete">
              <Row className="no-gutters">
                <Col md="6">
                  <FormGroup className="mr-md-2 mr-xs-0">
                    <FieldInput
                      label="First Name *"
                      name="firstName"
                      id="firstName"
                      autoComplete="hash_1"
                      disabled={isSubmitting}
                      normalize={onlyLatinLetters}
                      maxLength={50}
                    />
                  </FormGroup>
                </Col>

                <Col md="6">
                  <FormGroup className="ml-md-2 ml-xs-0">
                    <FieldInput
                      label="Last Name *"
                      name="lastName"
                      id="lastName"
                      autoComplete="hash_2"
                      disabled={isSubmitting}
                      normalize={onlyLatinLetters}
                      maxLength={50}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row className="no-gutters">
                <Col md="12">
                  <FormGroup className="mr-xs-0">
                    <FieldInput
                      label="Email *"
                      name="email"
                      id="email"
                      autoComplete="hash_3"
                      disabled={isSubmitting}
                      normalize={alphanumWithSymbols}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row className="no-gutters">
                <Col md="6">
                  <FormGroup className="mr-md-2 ml-xs-0">
                    <label className="text-light">Phone Number (e.g. 12002222222) *</label>

                    <FieldPhoneInput
                      name="phoneNumber"
                      autoComplete="hash_4"
                      id="phoneNumber"
                      placeholder="+"
                      disabled={Boolean(isSubmitting || isSmsCodeCountdownRunning)}
                    />
                  </FormGroup>
                </Col>

                <Col md="6">
                  <FormGroup className="ml-md-2 ml-xs-0">
                    <label className="text-light">SMS code *</label>
                    <InputGroup className="input-group-alternative">
                      <div style={{ flex: 1 }}>
                        <FieldInput
                          name="code"
                          disabled={isSubmitting || Boolean(errors.phoneNumber || !values.phoneNumber?.phone)}
                          maxLength={6}
                          normalize={onlyNumbers}
                        />
                      </div>
                      <InputGroupAddon addonType="append">
                        <ButtonDropdown color="simple" size="sm" isOpen={dropdownOpen} toggle={toggle}>
                          <DropdownToggle
                            color="light"
                            disabled={Boolean(
                              errors.phoneNumber || isSmsCodeCountdownRunning || !values.phoneNumber?.phone
                            )}
                          >
                            {isSmsCodeCountdownRunning ? `Send code (${smsCodeCountdown}) ` : 'Send code '}
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem
                              onClick={() => onSendCode({ phoneNumber: values.phoneNumber?.phone, type: 'sms' })}
                            >
                              Via SMS
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => onSendCode({ phoneNumber: values.phoneNumber?.phone, type: 'call' })}
                            >
                              Via Phone Call
                            </DropdownItem>
                          </DropdownMenu>
                        </ButtonDropdown>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>

              <Row className="no-gutters">
                <Col md="6">
                  <FormGroup className="mr-md-2 mr-xs-0">
                    <InputGroup className="input-group-alternative">
                      <FieldPasswordInput
                        label="Password * (min 8 symbols)"
                        type="password"
                        name="password"
                        id="password"
                        disabled={isSubmitting}
                        autoComplete="hash_6"
                        normalize={alphanumWithSymbols}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup className="ml-md-2 ml-xs-0">
                    <InputGroup className="input-group-alternative">
                      <FieldPasswordInput
                        label="Retype Password *"
                        type="password"
                        name="passwordConfirmation"
                        id="passwordConfirmation"
                        disabled={isSubmitting}
                        autoComplete="hash_7"
                        normalize={alphanumWithSymbols}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Button className="auth_button mt-3" color="brand" type="submit" disabled={isSubmitting || !isValid}>
                  SUBMIT
                </Button>
              </Row>
            </Form>
          </CardBody>

          <CardFooter>
            <Link to="/login">Back to Login Page</Link>
          </CardFooter>
        </Card>
      </Col>
    </AuthLayout>
  );
}
