// @flow
import React, { useState } from 'react';
import { Button } from 'reactstrap';
import Uppy from '@uppy/core';
import { DashboardModal } from '@uppy/react';
import XHRUpload from '@uppy/xhr-upload';

import { baseURL } from '../../Api';

type Props = {
  onDocumentLoad: (any) => void,
  userToken: string,
  apiPath: string,
  note?: string,
  mimeTypes?: Array<string>,
};

export default function UploadButton(props: Props) {
  const { onDocumentLoad, userToken, apiPath, mimeTypes, note } = props;

  const allowedFileTypes = mimeTypes ? mimeTypes : ['image/jpeg', 'image/png', 'application/pdf'];
  const dashboardNote = (note) ? note : "Images and PDF only, up to 50MB";

  const [modalOpen, setModalOpen] = useState(false);
  const handleOpen = () => setModalOpen(true);
  const handleClose = () => {
    uppy.close();
    window.document.querySelector('body').classList.remove('uppy-Dashboard-isFixed');
    setModalOpen(false);
  }

  const uppy = Uppy({
    allowMultipleUploads: true,
    restrictions: {
      allowedFileTypes,
      maxFileSize: 50000000,
    },
  })
    .use(XHRUpload, {
      endpoint: `${baseURL}${apiPath}`,
      formData: true,
      fieldName: 'document',
      headers: {
        authorization: `Bearer ${userToken ?? ''}`,
      },
    })
    .on('complete', (result) => {
      handleClose();
      onDocumentLoad(result);
    });

  const metaFields = null;

  return (
    <>
      <DashboardModal
        uppy={uppy}
        closeModalOnClickOutside
        open={modalOpen}
        note={dashboardNote}
        onRequestClose={handleClose}
        metaFields={metaFields}
      />
      <Button color="success" onClick={handleOpen}>
        <i className="fas fa-plus pr-2"></i>Upload Documents
      </Button>
    </>
  );
}
