// @flow

import { Formik, FieldArray } from 'formik'
import React from 'react'
import { addYears, addMonths, format } from 'date-fns'
import { useDispatch } from 'react-redux'
import {
  Table,
  CardBody,
  InputGroupAddon,
  InputGroup,
  CardHeader,
  CardFooter,
  Form,
  Button,
  Row,
  Col,
  FormGroup,
} from 'reactstrap'
import { CLIENT_TYPE, TIN_TYPES, CP_TYPES } from 'core/Models/Enums'
import { FieldInput, FieldDatePicker, FieldCountrySelect, FieldSelect, UploadButton } from 'core/Components/Form'
import { alphanumWithSymbols } from 'core/Components/Form/normalizers'

// import { selectUserToken, selectClient } from '../../../Selectors'
import * as actions from './actions'
import Alert from 'reactstrap/lib/Alert'
import { individualDdlSchema, corporateDdlSchema } from 'core/Validation/ddlValidation'

type DueDiligenceFormProps = {
  type: CLIENT_TYPE,
  formId: number,
  initialValues: any,
  isEditable: boolean,
  userToken: string,
}

const minPassportExpiryDate = addMonths(new Date(), 3)
const maxPassportExpiryDate = addYears(new Date(), 50)

export default function DueDiligenceForm(props: DueDiligenceFormProps) {
  const dispatch = useDispatch()
  const { type, initialValues, isEditable, formId, userToken } = props

  const onSubmit = (values, { setSubmitting, submit = true }) => {
    const onSuccess = () => {
      setSubmitting(false)
    }
    const onError = () => {
      setSubmitting(false)
    }

    const transformedValues = Object.assign({}, values, { type })

    dispatch(actions.submitDueDiligenceForm({ id: formId, values: transformedValues, onSuccess, onError }))
  }

  if (!isEditable && !initialValues?.id) {
    return <div>Loading...</div>
  }
  if (initialValues.id !== formId) {
    return <div>Loading...</div>
  }
  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues ?? {}}
      validationSchema={type === CLIENT_TYPE.INDIVIDUAL ? individualDdlSchema : corporateDdlSchema}
      autoComplete={'off'}
      render={(props) => <DueDiligenceFormData {...props} userToken={userToken} type={type} isEditable={isEditable} />}
    />
  )
}

type FormProps = {
  handleSubmit: (values: any) => void,
  type: CLIENT_TYPE,
  isValid: boolean,
  setFieldValue: any,
  isSubmitting: boolean,
  isEditable: boolean,
  values: any,
  userToken: string,
}

const DueDiligenceFormData = (props: FormProps) => {
  const { handleSubmit, values, setFieldValue, type, isValid, isSubmitting, isEditable, userToken } = props
  const fieldsDisabled = Boolean(isSubmitting || !isEditable || values.createdAt)

  const documentLoaded = (payload) => {
    setFieldValue('documents', payload?.successful?.[0]?.response?.body.documents, false)
  }

  return (
    <Form role="form" onSubmit={handleSubmit} autoComplete="off">
      {values.createdAt ? (
        <Alert className="mx-3 mt-3">
          The form has been sent for review at {format(new Date(values.createdAt), 'dd.MM.yyyy')}
        </Alert>
      ) : null}
      <CardHeader tag="h2" className="form-section">
        Client Information
      </CardHeader>

      <CardBody>
        <Row>
          <Col lg="6">
            <FormGroup>
              <FieldInput
                label={type === CLIENT_TYPE.INDIVIDUAL ? 'Full Name *' : 'Company Name *'}
                name="fullName"
                id="fullName"
                disabled={fieldsDisabled}
                normalize={alphanumWithSymbols}
                maxLength={100}
              />
            </FormGroup>
          </Col>
          {type === CLIENT_TYPE.INDIVIDUAL ? (
            <Col lg="6">
              <FormGroup>
                <FieldInput
                  label="Passport Number *"
                  name="passportNumber"
                  id="passportNumber"
                  disabled={fieldsDisabled}
                  normalize={alphanumWithSymbols}
                  maxLength={100}
                />
              </FormGroup>
            </Col>
          ) : null}
        </Row>
        {type === CLIENT_TYPE.INDIVIDUAL ? (
          <Row>
            <Col lg="6">
              <FormGroup>
                <FieldDatePicker
                  label="Passport Expiry Date *"
                  name="passportExpiryDate"
                  id="passportExpiryDate"
                  minDate={minPassportExpiryDate}
                  maxDate={maxPassportExpiryDate}
                  showYearDropdown
                  scrollableYearDropdown
                  showMonthDropdown
                  yearDropdownItemNumber={15}
                  disabled={fieldsDisabled}
                />
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup className="mt-1">
                <InputGroup>
                  <FieldCountrySelect
                    label="Citizenship *"
                    name="passportCountry"
                    id="passportCountry"
                    disabled={fieldsDisabled}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
        ) : null}

        <Row>
          <Col lg="12">
            <FormGroup>
              <FieldInput
                label={type === CLIENT_TYPE.INDIVIDUAL ? 'Residence Address *' : 'Registered Address *'}
                name="address"
                id="address"
                disabled={fieldsDisabled}
                normalize={alphanumWithSymbols}
                maxLength={500}
              />
            </FormGroup>
          </Col>
        </Row>
        {type === CLIENT_TYPE.CORPORATE ? (
          <Row>
            <Col lg="12">
              <FormGroup>
                <FieldInput
                  label={'Operating address (if different from above)'}
                  name="operatingAddress"
                  id="operatingAddress"
                  disabled={fieldsDisabled}
                  normalize={alphanumWithSymbols}
                  maxLength={500}
                />
              </FormGroup>
            </Col>
          </Row>
        ) : null}
      </CardBody>

      <CardHeader tag="h2" className="form-section">
        Taxpayer Identification Numbers
      </CardHeader>

      <CardBody>
        <p>If no TIN is available, please enter Reason A, B or C in the field TIN.</p>
        {type === CLIENT_TYPE.CORPORATE ? (
          <ul>
            <li>Reason A: The country/jurisdiction where the company is liable to pay tax does not issue TINs.</li>
            <li>
              Reason B: The Account Holder is otherwise unable to obtain a TIN or equivalent number (Please explain why
              you are unable to obtain a TIN in the field Comment if you have selected this reason).
            </li>
            <li>
              Reason C: No TIN is required. (Note. Only select this reason if the domestic law of the relevant
              jurisdiction does not require the collection of the TIN issued by such jurisdiction).
            </li>
          </ul>
        ) : (
          <ul>
            <li>
              Reason A: The country/jurisdiction where I am liable to pay tax does not issue TINs to its residents.
            </li>
            <li>
              Reason B: The Account Holder is otherwise unable to obtain a TIN or equivalent number (Please explain why
              you are unable to obtain a TIN in the field Comment if you have selected this reason).
            </li>
            <li>
              Reason C: No TIN is required. (Note. Only select this reason if the domestic law of the relevant
              jurisdiction does not require the collection of the TIN issued by such jurisdiction).
            </li>
          </ul>
        )}
        <FieldArray
          name="tins"
          render={(arrayHelpers) => (
            <>
              {values.tins.map((contact, idx) => (
                <Row key={`tins_row_${idx}`}>
                  <Col xl={9} lg={12}>
                    <FormGroup>
                      <InputGroup>
                        <InputGroupAddon className="tins_prepend" addonType="prepend">
                          <FieldSelect
                            name={`tins.${idx}.type`}
                            placeholder="Type"
                            options={TIN_TYPES.selectOptions}
                            maxWidth={180}
                            disabled={fieldsDisabled}
                          />
                        </InputGroupAddon>

                        <FieldInput
                          maxLength={30}
                          placeholder="Tax ID Number *"
                          name={`tins.${idx}.tin`}
                          autoComplete={`hash-235${idx}`}
                          disabled={fieldsDisabled}
                        />

                        <InputGroupAddon addonType="append" className="tins_append">
                          <FieldCountrySelect
                            placeholder="Country to which TIN applies *"
                            name={`tins.${idx}.country`}
                            id={`tins.${idx}.country`}
                            disabled={fieldsDisabled}
                          />
                        </InputGroupAddon>

                        {!fieldsDisabled && (
                          <InputGroupAddon addonType="append">
                            <Button
                              type="button"
                              size="sm"
                              color="warning"
                              onClick={() => arrayHelpers.remove(idx)}
                              disabled={fieldsDisabled}
                            >
                              <i className="far fa-trash-alt px-1" />
                            </Button>
                          </InputGroupAddon>
                        )}
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              ))}

              {!values.tins.length ? (
                type === CLIENT_TYPE.CORPORATE ? (
                  <Alert color="muted" className="my-4">
                    Please add your company TIN
                  </Alert>
                ) : (
                  <Alert color="muted" className="my-4">
                    Please add your TIN
                  </Alert>
                )
              ) : null}
              {values.tins.length <= 4 ? (
                <Row>
                  <Col lg="2" md="6">
                    <Button
                      type="button"
                      color="success"
                      onClick={() => arrayHelpers.push({ type: TIN_TYPES.TIN, tin: '', country: 'United States' })}
                      disabled={fieldsDisabled}
                    >
                      <i className="fas fa-plus pr-2" />
                      Add TIN
                    </Button>
                  </Col>
                </Row>
              ) : null}
              <div className="mt-4">
                <FieldInput
                  type="textarea"
                  label={'Comment'}
                  name="description"
                  id="description"
                  disabled={fieldsDisabled}
                  normalize={alphanumWithSymbols}
                  maxLength={500}
                />
              </div>
            </>
          )}
        />
      </CardBody>

      {type === CLIENT_TYPE.CORPORATE ? (
        <>
          <CardHeader tag="h2" className="form-section">
            Information about controlling persons
          </CardHeader>
          <CardBody>
            <p>Please add here information on controlling persons of your company.</p>
            <ol>
              <li className="mb-3">In case of a company please add information on beneficial owners and directors.</li>
              <li className="mb-3">
                In case of a trust, please add information on beneficial owners, settlor, trustee and protector.
              </li>
              <li className="mb-3">
                In case of a foundation, please add information on founders, members of the foundation council and
                beneficiaries.
              </li>
              <li>
                If no TIN available enter Reason A, B or C in the field TIN.
                <ul>
                  <li className="mt-3">
                    <p>
                      <b>Reason A:</b> The country/jurisdiction where I am liable to pay tax does not issue TINs to its
                      residents.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Reason B:</b> The Account Holder is otherwise unable to obtain a TIN or equivalent number
                      (Please explain why you are unable to obtain a TIN in the below table if you have selected this
                      reason).
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Reason C:</b> No TIN is required. (Note. Only select this reason if the authorities of the
                      country/jurisdiction of tax residence entered below do not require the TIN to be disclosed)
                    </p>
                  </li>
                </ul>
              </li>
            </ol>

            <FieldArray
              name="persons"
              render={(arrayHelpers) => (
                <>
                  {values.persons.map((person, idx) => (
                    <div className="person_block" key={`persons_row_${idx}`}>
                      <Row>
                        <Col md={9}>
                          <h2 className="mb-3">Controlling person #{idx + 1}</h2>
                        </Col>
                        <Col md={3} className="text-right">
                          {!fieldsDisabled && (
                            <Button
                              type="button"
                              size="sm"
                              color="warning"
                              onClick={() => arrayHelpers.remove(idx)}
                              disabled={fieldsDisabled}
                            >
                              <i className="far fa-trash-alt px-1" />
                              Delete Person
                            </Button>
                          )}
                        </Col>
                      </Row>

                      <Row>
                        <Col lg={4}>
                          <FormGroup>
                            <FieldSelect
                              name={`persons.${idx}.type`}
                              label="Type of CP"
                              options={CP_TYPES.selectOptions}
                              disabled={fieldsDisabled}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg={8}>
                          <FormGroup>
                            <FieldInput
                              maxLength={1000}
                              label="Full Name *"
                              name={`persons.${idx}.name`}
                              autoComplete={`hash-235${idx}`}
                              disabled={fieldsDisabled}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg={6}>
                          <FormGroup>
                            <FieldInput
                              label="Passport Number *"
                              maxLength={1000}
                              placeholder="Passport Number *"
                              name={`persons.${idx}.passportNumber`}
                              autoComplete={`hash-235${idx}`}
                              disabled={fieldsDisabled}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg={6}>
                          <FormGroup>
                            <FieldDatePicker
                              label="Passport Expiry Date *"
                              name={`persons.${idx}.passportExpiryDate`}
                              id={`persons.${idx}.passportExpiryDate`}
                              minDate={minPassportExpiryDate}
                              maxDate={maxPassportExpiryDate}
                              showYearDropdown
                              scrollableYearDropdown
                              showMonthDropdown
                              yearDropdownItemNumber={15}
                              disabled={fieldsDisabled}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg={12}>
                          <FormGroup>
                            <FieldInput
                              maxLength={1000}
                              label="Residence Address *"
                              name={`persons.${idx}.address`}
                              autoComplete={`hash-235${idx}`}
                              disabled={fieldsDisabled}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg={12}>
                          <FormGroup>
                            <InputGroup>
                              <InputGroupAddon className="tins_prepend" addonType="prepend">
                                <FieldSelect
                                  name={`persons.${idx}.tinType`}
                                  placeholder="Type"
                                  options={TIN_TYPES.selectOptions}
                                  maxWidth={180}
                                  disabled={fieldsDisabled}
                                />
                              </InputGroupAddon>

                              <FieldInput
                                maxLength={30}
                                placeholder="Personal TIN *"
                                name={`persons.${idx}.tin`}
                                autoComplete={`hash-235${idx}`}
                                disabled={fieldsDisabled}
                              />

                              <InputGroupAddon addonType="append" className="tins_append">
                                <FieldCountrySelect
                                  placeholder="Country to which TIN applies *"
                                  name={`persons.${idx}.tinCountry`}
                                  id={`persons.${idx}.tinCountry`}
                                  disabled={fieldsDisabled}
                                />
                              </InputGroupAddon>
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  ))}
                  {values.persons.length <= 10 ? (
                    <Row>
                      <Col lg="2" md="6">
                        <Button
                          type="button"
                          color="success"
                          onClick={() =>
                            arrayHelpers.push({
                              type: CP_TYPES.BENEFICIAL_OWNER,
                              name: '',
                              tinType: TIN_TYPES.TIN,
                              tinCountry: 'United States',
                            })
                          }
                          disabled={fieldsDisabled}
                        >
                          <i className="fas fa-plus pr-2" />
                          Add Controlling Person
                        </Button>
                      </Col>
                    </Row>
                  ) : null}
                </>
              )}
            />
          </CardBody>
        </>
      ) : null}

      <CardHeader tag="h2" className="form-section">
        Documents
      </CardHeader>

      <CardBody>
        {type === CLIENT_TYPE.CORPORATE ? (
          <ol>
            <li>
              Please upload a recently Certified Certificate of Incumbency or certified Certificate of Good Standing.{' '}
            </li>
            <li className="mt-3">
              If there has been a change in controlling persons (beneficiaries, directors, truster, trustee, etc.),
              please provide provide a supporting certified document to confirm the change (i.e. Certificate of
              Directors/Shareholders, etc.)
            </li>
            <li className="mt-3">
              For all new controlling persons and for all existing controlling persons who changed their
              passport/residency address, please provide certified passport and proof of address (a utility bill or a
              bank statement) dated no more than 3 months old.
            </li>
          </ol>
        ) : (
          <p className="mb-0">
            If you have changed your passport or residency, please provide us with a new certified copy of it. Certified
            proof of address (a utility bill or a bank statement) should be no more than 3 months old.
          </p>
        )}{' '}
      </CardBody>

      {values.documents.length ? (
        <Table striped bordered responsive className="mb-4">
          <thead>
            <tr>
              <th scope="row">Document</th>
            </tr>
          </thead>
          <tbody>
            {values.documents.map((document, idx) => {
              return (
                <tr key={`ddl_docs_${document.id}_${idx}`}>
                  <th scope="row">{document.name}</th>
                </tr>
              )
            })}
          </tbody>
        </Table>
      ) : (
        <Alert color="muted" className="mb-4 mx-4">
          No documents uploaded
        </Alert>
      )}

      {isEditable ? (
        <div className="mx-4 mb-4">
          <UploadButton
            onDocumentLoad={documentLoaded}
            userToken={userToken}
            apiPath={`due-diligence/${values.id}/document`}
          />
        </div>
      ) : null}

      {isEditable ? (
        <CardFooter>
          <Button type="submit" color="brand" disabled={!isValid}>
            <i className="fas fa-check pr-2"></i>Submit Form
          </Button>
        </CardFooter>
      ) : null}
    </Form>
  )
}
