import { createStore, applyMiddleware, compose } from 'redux';
import { combineReducers } from 'redux-immutable';
import createSagaMiddleware from 'redux-saga';

import rootSaga from './rootSaga';
import rootReducer from './rootReducer';

import loginReducer from './Pages/LoginPage/reducer';
import partnerReducer from './Pages/InnerPage/PartnerPage/reducer';
import amReducer from './Pages/InnerPage/AccountManagerPage/reducer';
import ddlReducer from './Pages/InnerPage/DueDiligencePage/reducer';

const staticReducers = {
  app: rootReducer,
  auth: loginReducer,
  partnerPage: partnerReducer,
  amPage: amReducer,
  ddlPage: ddlReducer,
};

const sagaMiddleware = createSagaMiddleware({});

// const gtm = (store) => (next) => (action) => {
//   const { type } = action;

//   if (type === '[101] AUTH/LOGIN_USER') {
//     window.dataLayer.push({ event: 'Pageviews', dimension5: 'Autorized' });
//   }
//   if (type === '[123] MAIN/USER_LOGGED_OUT') {
//     window.dataLayer.push({ event: 'Pageviews', dimension5: 'NonAutorized' });
//   }

//   return next(action);
// };

// Configure the store
export default function configureStore(initialState) {
  const middlewares = [sagaMiddleware];

  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer];

  const actionsBlacklist = [];
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        actionsBlacklist,
        shouldHotReload: false,
        shouldRecordChanges: true,
      })
    : compose;

  const store = createStore(createReducer(), initialState, composeEnhancers(...enhancers));

  // Add a dictionary to keep track of the registered async reducers
  store.asyncReducers = {};

  // Create an inject reducer function
  // This function adds the async reducer, and creates a new combined reducer
  store.injectReducer = (key, asyncReducer) => {
    store.asyncReducers[key] = asyncReducer;
    store.replaceReducer(createReducer(store.asyncReducers));
  };

  store.runSaga = sagaMiddleware.run;
  sagaMiddleware.run(rootSaga);

  // Return the modified store
  return store;
}

function createReducer(asyncReducers) {
  return combineReducers({
    ...staticReducers,
    ...asyncReducers,
  });
}
