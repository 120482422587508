import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  CardHeader,
  CardBody,
  CardFooter,
  Card,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Button
} from 'reactstrap';

import type { ClientType } from 'core/Models/Client';
import type { UserType } from 'core/Models/User';
import ChangePasswordlModal from './ChangePasswordModal';
import { selectClient, selectUser } from './../../../Selectors';
import { STANDARD_TIME_DATE, STANDARD_DATE } from 'core/Utils/dateUtils';

import { format } from 'date-fns';


export default function ProfilePage() {
  const user: UserType = useSelector(selectUser);
  const client: ClientType = useSelector(selectClient);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  return (
    
    <>
      <CardHeader tag="h1">Profile Page</CardHeader>

          <CardBody>
            <Row>
              <Col sm="6">
                <h2 className="mb-3">Account Information</h2>
                <ListGroup>
                  <ListGroupItem>
                    <span className="brand-span">Full Name:</span> {user.firstName} {user.lastName}
                  </ListGroupItem>
                  <ListGroupItem>
                    <span className="brand-span">Email:</span> {user.email}
                  </ListGroupItem>
                  <ListGroupItem>
                    <span className="brand-span">Phone:</span> {user.phoneCode} {user.phoneNumber}
                  </ListGroupItem>
                  <ListGroupItem>
                    <span className="brand-span">Registred at:</span>{' '}
                    {format(new Date(user.createdAt), STANDARD_TIME_DATE)}
                  </ListGroupItem>
                  <ListGroupItem>
                    <span className="brand-span">Verified at:</span>{' '}
                    {format(new Date(user.verifiedAt), STANDARD_TIME_DATE)}
                  </ListGroupItem>
                </ListGroup>
              </Col>
              <Col sm="6">
                <h2 className="mb-3">Client Information</h2>
                {client.type === 'INDIVIDUAL' && (
                  <ListGroup>
                    <ListGroupItem>
                      <span className="brand-span">Account Type:</span> Individual
                    </ListGroupItem>
                    <ListGroupItem>
                      <span className="brand-span">Citizenship:</span> {client.citizenship}
                    </ListGroupItem>
                    <ListGroupItem>
                      <span className="brand-span">Residence:</span> {client.residence}
                    </ListGroupItem>
                    <ListGroupItem>
                      <span className="brand-span">Birth Date:</span> {format(new Date(client.birthday), STANDARD_DATE)}
                    </ListGroupItem>
                    <ListGroupItem>
                      <span className="brand-span">Occupation:</span> {client.employerName} @ {client.occupation}
                    </ListGroupItem>
                  </ListGroup>
                )}
                {client.type === 'CORPORATE' && (
                  <ListGroup>
                    <ListGroupItem>
                      <span className="brand-span">Account Type:</span> Corporate
                    </ListGroupItem>
                    <ListGroupItem>
                      <span className="brand-span">Full Legal Name:</span> {client.companyName}
                    </ListGroupItem>
                    <ListGroupItem>
                      <span className="brand-span">Incorporation Country:</span> {client.companyIncorporationCountry}
                    </ListGroupItem>
                    <ListGroupItem>
                      <span className="brand-span">Incorporation Date:</span>{' '}
                      {format(new Date(client.companyIncorporationDate), STANDARD_DATE)}
                    </ListGroupItem>
                    <ListGroupItem>
                      <span className="brand-span">Business Description:</span> {client.companyDescription}
                    </ListGroupItem>
                  </ListGroup>
                )}
              </Col>
            </Row>
          </CardBody>

          <CardHeader tag="h2" className="form-section"><i className="fas fa-shield-alt pr-2"></i>Security</CardHeader>

          <CardBody>
            <Row>
              <Col sm="6">
                <Card>
                  <CardHeader tag="h2" className="blue-background">Password</CardHeader>
                  <CardBody>For security purposes, please change your password regularly.</CardBody>
                  <CardFooter className="blue-background">
                  <Button color="brand" onClick={toggleModal}>Change Password</Button>
                  <ChangePasswordlModal isOpen={isModalOpen} toggleModal={toggleModal} />
                  </CardFooter>
                  

                </Card>
              </Col>

              {/* <Col sm="6">
                <Card body>
                  <h2>Two-Factor Authentication</h2>
                  <CardText>Connect 2FA for extra security.</CardText>
                  <Button color="brand" disabled>Activate 2FA</Button> */}
                  {/* <Form className="text-left" onSubmit={onSubmit}>
                    <p>1. Install <a target="_blank" rel="noopener noreferrer" href="https://authy.com/download/">Authy</a> to your smartphone.</p>
                    <p>2. Scan this QR code in Authy Application.</p>
                    <p>3. Type Authy code in field below.</p>
                    <FormGroup>
                      <Input type="text" id="code" name="code" required/>
                    </FormGroup>
                    <p>4. Press button "Connect" to activate 2FA.</p>
                    <Button color="brand">Connect</Button>
                  </Form> */}
                {/* </Card>
              </Col> */}

            </Row>
          </CardBody>
 
    </>
  );
}
