// @flow
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Card, CardFooter, CardBody, Col, Form, FormGroup, CardHeader } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { FieldInput } from 'core/Components/Form';

import { AuthLayout } from '../../Components/Layout';

import * as actions from './actions';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email')
    .required('Email is required'),
});

type FormikEnhancerProps = {};

export default function FormikEnhancer(props: FormikEnhancerProps) {
  const dispatch = useDispatch();
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const sendResetPasswordLink = ({ values, onSuccess, onError }) => dispatch(actions.sendResetPasswordLink({ values, onSuccess, onError }));

  const onSubmit = (values, { setSubmitting }) => {
    const onSuccess = () => {
      setSubmitting(false);
      setRedirectToLogin(true);
    };
    const onError = () => {
      setSubmitting(false);
    };
    sendResetPasswordLink({ values, onSuccess, onError });
  };

  if (redirectToLogin) return <Redirect to="/login" />;

  return <Formik autoComplete="off" component={ResetPasswordEmailForm} onSubmit={onSubmit} validationSchema={validationSchema} />;
}

type ResetPasswordEmailFormProps = {
  isSubmitting: boolean,
  isValid: boolean,
  handleSubmit: () => void,
};

function ResetPasswordEmailForm(props: ResetPasswordEmailFormProps) {
  const { isSubmitting, isValid, handleSubmit } = props;

  return (
    <AuthLayout>
      <Col xl={5} lg={6} md={8}>
        <Card className="card-auth">
           <CardHeader tag="h1">RESET PASSWORD</CardHeader>
          
          <CardBody>
            <Form role="form" onSubmit={handleSubmit}>
              <FormGroup>
             
                  <FieldInput
                    label="Client's Email"
                    type="email"
                    name="email"
                    disabled={isSubmitting}
                    autoComplete="username"
                  />
          
              </FormGroup>
              <Button className="my-2 auth_button" color="brand" type="submit" disabled={isSubmitting || !isValid}>
                RESET PASSWORD
              </Button>
            </Form>
          </CardBody>
          <CardFooter>
            <Link to="/login">Back to Login Page</Link>
          </CardFooter>
        </Card>
      </Col>
    </AuthLayout>
  );
}
