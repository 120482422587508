import noop from 'lodash/noop';
import { takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import * as mainActions from '../../../Actions';
import * as userApi from 'core/Api/user/userApi';

import * as actions from './actions';

export function* getReferralsSaga({ payload: { onSuccess = noop, onError = noop  } = {} }) {
  try {
    const result = yield call(userApi.getReferrals);
    yield put(actions.getReferrals.success(result));
    yield call(onSuccess);
  } catch (e) {
    yield put(actions.getReferrals.error(e));
    yield call(onError);
  }
}

export function* addReferralSaga({ payload: { values, onSuccess = noop, onError = noop  } = {} }) {
  try {
    const result = yield call(userApi.addReferral, { values });
    yield put(actions.addReferral.success(result));
    yield call(onSuccess);
    yield call(toast.success, 'Invite Sent');
    yield put(mainActions.updateUser({ partnerText: values.partnerText }));
  } catch (e) {
    yield call(onError);
  }
}

export default [
  takeLatest(actions.getReferrals, getReferralsSaga),
  takeLatest(actions.addReferral, addReferralSaga),
];
