// @flow
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

import * as mainActions from '../../Actions';

type Props = {};

export default function LogoutPage(props: Props) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(mainActions.userLoggedOut());
  }, [dispatch]);

  return <Redirect to="/" />;
}
