import React from 'react';
import { Field, ErrorMessage } from 'formik';
import { Input, Label, FormFeedback } from 'reactstrap';

function defaultNormalize(v) {
  return v;
}

const normalizeInput = (handleChange, normalize = defaultNormalize) => (event) => {
  const normalizedValue = normalize(event.target.value);
  return handleChange(event.target.name)(normalizedValue);
};

export default function(props) {
  const {label, normalize, ...restProps } = props;
  return (
    <Field
      {...restProps}
      render={({ field, form: { touched, errors, handleChange} }) => {
        return (
          <>
            {props.label ? <Label className="muted_label" for={field.name}>{label}</Label> : null}
            <Input
              {...restProps}
              {...field}
              onChange={normalizeInput(handleChange, normalize)}
              value={field.value || ''}
              invalid={Boolean(touched[field.name] && errors[field.name])}
            />
            <ErrorMessage name={field.name}>
              {(msg) => <FormFeedback>{msg}</FormFeedback>}
            </ErrorMessage>
          </>
        );
      }}
    />
  );
}
